import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { Grid, Container } from "@mui/material";
import CategoryCard from "../../components/button_card/index";
import Details from "../../components/project_category_details/index";
import { getAllProjects } from "../../redux/actions/project";
import { getProjectPageDetails } from "../../redux/actions/projectPage";
import "./categories.scss";
import StickyButton from "../../components/maakeenafspraak_sticky_button/sticky_button";

const Categories = ({
  getProjectPageDetails,
  getAllProjects,
  projectPage,
  project,
}) => {
  const options = {
    icons: {},
    items: [
      { to: "/", label: "Home" },
      { to: "/", label: "Projecten", active: true },
    ],
  };

  const [pageDetails, setProjectDetails] = useState();
  const [categoriesList, setCategoriesList] = useState([]);

  const multipleCalls = async () => {
    const [{ data: pageData }, { data: categoriesData }] = await Promise.all([
      getProjectPageDetails(),
      getAllProjects(),
    ]);
    setProjectDetails(pageData);
    setCategoriesList(categoriesData);
  };

  useEffect(() => {
    multipleCalls();
  }, []);

  useEffect(() => {
    setProjectDetails(projectPage?.project_page_details);
  }, [projectPage.project_page_details]);

  return (
    <>
      <StickyButton />
      <Details options={options} pageDetails={pageDetails} />
      <Grid container justifyContent="center" alignItems="center">
        <Container maxWidth="lg">
          <div className="categories-category h-auto mb-[20px] mt-[40px] gap-[20px]">
            {categoriesList.map((obj, index) => (
              <Fragment key={`cat-${index}`}>
                {obj?.show && (
                  <CategoryCard obj={obj} />
                )}
              </Fragment>
            ))}
          </div>
        </Container>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ projectPage, project, loader }) => ({
  projectPage,
  project,
  loader,
});
export default connect(mapStateToProps, {
  getProjectPageDetails,
  getAllProjects,
})(Categories);
