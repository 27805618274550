import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Container, Divider } from "@mui/material";
import ImagePart from "./child_components/image_part";
import DetailsPart from "./child_components/details_part";
import TabsProduct from "./child_components/tabs_product";
import BreadcrumbsItem from "../../components/breadcrumb/breadcrumb";
import { getAllProducts } from "../../redux/actions/product";

import "./child_components/details.scss";
import axios from "axios";
import { BASE_URL, getProduct, getSalesItem, viewProduct } from "../../redux/constants/ApiEndPoints";

const Index = ({ product, getAllProducts }) => {
  let { pageId, productId } = useParams();
  const [selectedproduct, setSelectedProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const [productTitle, setProductTitle] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const userData = JSON.parse(localStorage.getItem("user-data"))
  const options = {
    icons: {},
    items: [
      { to: "/", label: "Home" },
      { to: pageId === "sale" ? "/sales-page" : "/inventory-page", label: pageId === "sale" ? "Sales" : "Products" },
      {
        to: `/inventory-page/${pageId}/${productId}`,
        label: productTitle ? productTitle : "No title",
        active: true,
      },
    ],
  };

  useEffect(() => {
    if(pageId === "product") {
      if(productId) {
        axios.get(BASE_URL + getProduct + productId, {
          params: {
            user_id: userData?.id
          }
        }).then(response => {
          const productData = response.data.data;
          let productTitle = "";
          if(!productData?.fixed) {
            if (productData.brand?.brand_id && productData.brand?.name) productTitle = productData.name;
            if (productData.brand?.brand_id && productData.brand?.name && productData.name)
              productTitle = productTitle.concat(` | ${productData.prefix}`);
          }
          else {
            if (productData.brand?.brand_id && productData.brand?.name && productData.model_id)
            productTitle = productData.model_id;
            if (productData.brand?.brand_id && productData.brand?.name && productData.model_id)
            productTitle = productTitle.concat(` | ${productData.product_id}`);
          }
          setSelectedProduct(productData);
          setSelectedImage(pageId === "product" ? productData.main_image : productData?.picture);
          setProductTitle(productTitle);
        })
      }
    }
    else {
      if(productId) {
        axios.get(BASE_URL + getSalesItem + productId, {
          params: {
            user_id: userData?.id
          }
        }).then(response => {
          const productData = response.data.data;
          setSelectedProduct(productData);
          setSelectedImage(pageId === "product" ? productData.main_image : productData?.picture);
          setProductTitle(productData?.card_id);
        })
      }
    }
    
  }, [productId])

  useEffect(() => {
    if(productId) {
      let data;
      axios.get("https://www.cloudflare.com/cdn-cgi/trace?format=json").then(response => {
        data = response.data;
        data = data.trim().split('\n').reduce(function(obj, pair) {
          pair = pair.split('=');
          obj[pair[0]] = pair[1];
          return obj;
        }, {});
      }).then(() => {
        axios.post(BASE_URL + viewProduct, {
          product_id: productId,
          ip: data?.ip
        }).catch(error => console.log(error))
      }).catch(error => console.log(error))
    }
  }, [productId])

  return (
    <>
      {selectedproduct && (
        <>  
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <BreadcrumbsItem options={options} />
            </Grid>
          </Grid>
          <Container>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ImagePart
                  product={selectedproduct}
                  pageId = {pageId}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="pb-5">
                <DetailsPart product={selectedproduct} pageId = {pageId} />
              </Grid>
              {pageId === "product" && (
                <Divider className="product-divider" />
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <ProductCard products={selectedproduct} currentPage={pageId} /> */}
              </Grid>
              {pageId === "product" && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="justify-center"
                >
                  <TabsProduct products={selectedproduct} currentPage={pageId} />
                </Grid>
              )}
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ product, loader }) => ({ product, loader });
export default connect(mapStateToProps, { getAllProducts })(Index);
