import axios from "../axios/axios";
import { get_promotions } from "../constants/ApiEndPoints";
import { GET_ALL_PROMOIONS } from "../constants/constants";

export const getAllPromotions = () => {
  return async (dispatch) => {
    const { data } = await axios.get(get_promotions);
    dispatch({ type: GET_ALL_PROMOIONS, payload: data.data });
    return data;
  };
};
