import {lowerCase} from "lodash";
import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getVacatures, getAllJobs } from "../../../redux/actions/vacatures";
import BreadcrumbsItem from "../../../components/breadcrumb/breadcrumb";
import CheckboxList from "./checkbox_list";
import { checkConstants, jobsList } from "../constant";
import JobsTable from "./jobs_table";
import BreakpointController from "../../../components/breakpoint_controller/breakpoint_controller";
import LogoTop from "../../../images/icons/logo_top.png";
import job from "../../../assets/images/jobs/1.png";

import "./jobs.scss";
import StickyButton from "../../../components/maakeenafspraak_sticky_button/sticky_button";

const Jobs = () => {
  const dispatch = useDispatch();
  const jobsListSelector = useSelector((state) => state.vacatures.allJobs);
  const [checkboxList, setCheckboxList] = useState(checkConstants);
  const [allJobs, setAllJobs] = useState([]);
  const [vacatures, setVacatures] = useState();

  const options = {
    icons: {},
    items: [
      { to: "/", label: "Home" },
      { to: "/job-openings", label: "Vacatures", active: true },
    ],
  };

  useEffect(() => {
    fetchingMultiple();
    // eslint-disable-next-line
  }, []);

  const fetchingMultiple = async () => {
    const [{ data: vacaturesResponse }, { data: jobsResponse }] =
      await Promise.all([dispatch(getVacatures()), dispatch(getAllJobs())]);
    setVacatures(vacaturesResponse);
    setAllJobs([...jobsResponse]);
  };

  const filterRecordsHandler = () => {
    let filterJobs = [];
    const allChecked = checkboxList?.filter(f => f.selected);
    if(allJobs && allJobs.length > 0) {
      if (checkboxList[0].selected && checkboxList[1].selected) {
        filterJobs = [...allJobs];
      }
      else {
        checkboxList.forEach((obj) => {
          allJobs.forEach((obj2) => {
            if (obj.selected && (lowerCase(obj.name) === lowerCase(obj2.location))) {
              filterJobs.push(obj2);
            }
          });
        });
      }
    }
    return allChecked?.length === 0 ? [] : filterJobs;
  };

  const handleCheckbox = (e) => {
    let index = e.currentTarget.id;
    let items = [...checkboxList];
    let prevValue = items[index].selected;
    items[index].selected = !prevValue;
    setCheckboxList([...items]);
  };

  return (
    <>
      <StickyButton />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="jobs-container pb-30">
            <BreadcrumbsItem options={options} />
            <Container>
              <div className="text-center">
                <span className="fs-35 font-dmserif text-[#404040]">
                  {vacatures?.title}
                </span>
              </div>
              <BreakpointController direction="up" breakpoint="lg">
                <img
                  src={vacatures?.image}
                  alt="job"
                  className="w-full h-[190px] object- mt-[43px] mb-[35px]"
                />
              </BreakpointController>
              <BreakpointController direction="down" breakpoint="lg">
                <div className="jobs-image-container mt-5">
                  <img src={vacatures?.image} alt={"job"} />
                </div>
              </BreakpointController>
              <div className="jobs-bottom-image-container">
                <BreakpointController direction="down" breakpoint="lg">
                  <img
                    src={LogoTop}
                    alt="logo"
                    className="object-contain translate-y-[-25px] cursor-pointer"
                  />
                </BreakpointController>
                <span className="jobs-text-logo font-montserrat">
                  {/* Top Interieur is een gekende meubelzaak met een uitgebreid
                  woon-en slaapassortiment. Er is een vestiging in Izegem en in
                  Massenhoven. */}
                  <div className="linkDiv">
                    <div
                      dangerouslySetInnerHTML={{ __html: vacatures?.content }}
                    />
                  </div>
                </span>
              </div>
            </Container>
          </div>
        </Grid>
        <Container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CheckboxList
              checkboxList={checkboxList}
              setCheckboxList={setCheckboxList}
              handleCheckbox={handleCheckbox}
            />
            <JobsTable allJobs={filterRecordsHandler()} />
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default Jobs;
