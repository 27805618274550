import React from 'react';

import './circle.scss';

const CircleButton = ({color}) => {
 
  return (
    <div className='circle'>
        <span className='circle-btn' style={{backgroundColor:`${color}`}}></span>
    </div>
  )    
}

export default CircleButton;