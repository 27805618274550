import React, { useEffect, useState } from 'react';
import { Grid, Container } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import PropTypes from "prop-types";
import { IoMdCheckmark } from 'react-icons/io';
import axios from "axios";
import { BASE_URL, getWishlist } from '../../../redux/constants/ApiEndPoints';
import InventoryCard from '../../../components/inventory_card/inventory_card';
import SalesCard from '../../../components/sales_card';

import './favorites.scss';

const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const FavoriteCardExpanded = (props) => {
    const { index, data, imageBaseUrl, animationState, setAnimationState } = props;

    return (
        <div className = "w-[448px] h-[455px] border-[1px] border-[#C98468] absolute left-0 top-0 bg-white animated" style = {{opacity: animationState ? 1 : 0, pointerEvents: animationState ? 'all' : 'none', transform: animationState ? 'translateY(0px)' : 'translateY(-10px)'}} onMouseOver = {() => setAnimationState(true)} onMouseLeave = {() => setAnimationState(false)}>
            <div className = "w-full h-full flex justify-between">
                <div className = "flex h-full flex-col w-full">
                    {/* render secondary images in this column */}
                </div>
                <div className = "flex flex-col w-[332px] h-full shrink-0">
                    <div className = "w-full h-[234px] flex shrink-0">
                        <img className = "w-full h-full object-cover" alt = "main image" src = {data?.product?.main_image ? imageBaseUrl + data?.product?.main_image : "https://grandinteriors.com.pk/wp-content/uploads/2021/02/f1cd1b9a-964f-46ad-a18f-8fecb029daa9.jpg"} />
                    </div>
                    <div className = "w-full h-full flex flex-col box-border pr-[15px]">
                        <p className = "font-[Montserrat] font-bold text-[15px]">{data?.product?.name}</p>
                        <p className = "font-[Montserrat] font-regular text-[13px] mt-[6px]">{data?.product?.item_description}</p>
                        <p className = "font-[Montserrat] font-bold text-[13px] mt-[9px]">Merk</p>
                        <p className = "font-[Montserrat] font-bold text-[15px] text-[#C98468] mt-[9px]">€ {data?.product?.price}</p>
                        <div className = "h-[1px] w-full my-[9px] bg-[#D0CCC671]" />
                        <p className = "font-[Montserrat] font-semibold text-[15px]">In Stock</p>
                        <div className = "flex mt-[9px] items-center">
                            <IoMdCheckmark color = "#C98468" size = "1em" />
                            <p className = "font-[Montserrat] font-regular text-[13px] ml-[5px]">Izegem</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FavoriteItemCard = (props) => {
    const [ cardAnimationState, setCardAnimationState ] = useState(false);

    const { index, data, imageBaseUrl } = props;

    return (
        <div className = {`font-montesrrat text-[#404040] w-[332px] relative mr-[16px] mb-[20px] cursor-pointer z-[${index}]`}>
            <div className = "w-full animated" onMouseOver = {() => setCardAnimationState(true)} onMouseLeave = {() => setCardAnimationState(false)}>
                <img alt="" src={data?.product?.main_image ? imageBaseUrl + data?.product?.main_image : "https://grandinteriors.com.pk/wp-content/uploads/2021/02/f1cd1b9a-964f-46ad-a18f-8fecb029daa9.jpg"} className = "h-[235px] w-[332px] object-cover" />
                <div className='text-[15px] font-bold mt-[15px] '>{data?.product?.name}</div>
                <div className='text-[13px] mt-[6px]'>{data?.product?.item_description}</div>
                <div className='mt-[5px]'>
                    <span className='text-[#27292C] text-[14px] mr-[6px]'>
                        Vanaf
                    </span>
                    <span className='text-[#C98468] text-[15px] font-semibold'>
                        € {data?.product?.price}
                    </span>
                    <span className='float-right mt-[-5px] text-[#C98468]'>
                        <FavoriteIcon /> 
                    </span>                    
                </div>
            </div>
            <FavoriteCardExpanded index = {index} data = {data} animationState = {cardAnimationState} setAnimationState = {setCardAnimationState} imageBaseUrl = {imageBaseUrl} />
        </div>
    )
}

const Favorites = (props) => {
    const [ wishlist, setWishlist ] = useState([]);

    const userData = JSON.parse(localStorage.getItem('user-data'));
    const imageBaseUrl = "https://tp-storage.s3.fr-par.scw.cloud/import";

    useEffect(() => {
        if(userData?.id) {
            axios.get(BASE_URL + getWishlist + userData?.id, {headers}).then(response => {
                setWishlist(response.data.data)
            }).catch(error => console.log(error))
        }
    }, [])

    const onWishlistItemDelete = (wishlistItemIndex) => {
        if(userData?.id) {
            axios.get(BASE_URL + getWishlist + userData?.id, {headers}).then(response => {
                setWishlist(response.data.data)
            }).catch(error => console.log(error))
        }
    }

    const TabContent = () => {
        return (
            <div className='mt-[50px] w-full flex flex-wrap'>
                {wishlist && wishlist.length > 0 && wishlist?.map((wishlistItem, wishlistIndex) => (
                    <Grid item md={3} className="inventory-item" key={wishlistIndex}>
                        {wishlistItem?.type === 1 ? (
                            <InventoryCard product = {wishlistItem?.product} currentPage isFavourite = {wishlistItem?.is_favourite} wishlist = {wishlist} onDelete = {() => onWishlistItemDelete(wishlistIndex)} />
                        ) : (
                            <SalesCard product = {wishlistItem?.sale_item} currentPage isFavourite = {wishlistItem?.is_favourite} wishlist = {wishlist} onDelete = {() => onWishlistItemDelete(wishlistIndex)} />
                        )}
                    </Grid>
                ))}
            </div>
        )
    }

    return (
        <Grid container justifyContent= "center" alignItems = "center">
            <TabContent />
        </Grid>
    )
}

export default Favorites;

FavoriteItemCard.propTypes = {
    index: PropTypes.number,
    data: PropTypes.object,
    imageBaseUrl: PropTypes.string
}

FavoriteItemCard.propTypes = {
    index: 0,
    data: {},
    imageBaseUrl: "https://tp-storage.s3.fr-par.scw.cloud/import"
}

FavoriteCardExpanded.propTypes = {
    index: PropTypes.number,
    data: PropTypes.object,
    imageBaseUrl: PropTypes.string,
    animationState: PropTypes.bool,
    setAnimationState: PropTypes.func
}

FavoriteCardExpanded.defaultProps = {
    index: 0,
    animationState: false,
    imageBaseUrl: "https://tp-storage.s3.fr-par.scw.cloud/import",
    data: {},
    setAnimationState: () => {}
}