import React from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTopWrapper from "../config/scrollToTop";
import SuccessMessage from "../components/success-message";

import "./routes.scss";

export default function RoutesWithoutHeader() {
  return (
    <ScrollToTopWrapper>
      <Routes>
        <Route path="/success-message" element={<SuccessMessage />} />
      </Routes>
    </ScrollToTopWrapper>
  );
}
