import axios from "../axios/axios";
import {
  get_vacatures,
  get_all_jobs,
  get_job,
} from "../constants/ApiEndPoints";
import {
  GET_VACATURES_DETAIL,
  GET_ALL_JOBS,
  GET_JOB,
} from "../constants/constants";

export const getVacatures = () => {
  return async (dispatch) => {
    const { data } = await axios.get(get_vacatures);
    dispatch({ type: GET_VACATURES_DETAIL, payload: data.data });
    return data;
  };
};

export const getAllJobs = () => {
  return async (dispatch) => {
    const { data } = await axios.get(get_all_jobs);
    dispatch({ type: GET_ALL_JOBS, payload: data.data });
    return data;
  };
};

export const getJob = (id) => {
  return async (dispatch) => {
    const { data } = await axios.get(`${get_job}/${id}`);
    dispatch({ type: GET_JOB, payload: data.data });
    return data;
  };
};
