import { ALL_SALES } from "../constants/constants";
const INIT_STATE = {
  all_sales: [],
};
const sale = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_SALES:
      return {
        ...state,
        all_sales: action.payload,
      };

    default:
      return state;
  }
};

export default sale;
