import React from 'react';
import Content from './content';
import BreadcrumbsItem from "../breadcrumb/breadcrumb";

const Form = () => {
    const options = {
        icons: {},
        items: [
            { to: "/", label: "Home" },
            { to: "/", label: "Afspraak Form", active: true },
        ],
    };

    return (
        <div>
            <BreadcrumbsItem options={options} />

            <Content/>
        </div>
    );
}

export default Form;
