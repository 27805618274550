import axios from "../axios/axios";
import { get_brands } from "../constants/ApiEndPoints";
import { GET_ALL_BRANDS } from "../constants/constants";

export const getBrands = () => {
  return async (dispatch) => {
    const { data } = await axios.get(get_brands);
    dispatch({ type: GET_ALL_BRANDS, payload: data.data });
    return data;
  };
};
