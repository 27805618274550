import { GET_PROJECT_PAGE_DETAILS } from "../constants/constants";
const INIT_STATE = {
  project_page_details: [],
};
const projectPage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECT_PAGE_DETAILS:
      return {
        ...state,
        project_page_details: action.payload,
      };

    default:
      return state;
  }
};

export default projectPage;
