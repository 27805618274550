import React from 'react';
import { Grid, Container } from "@mui/material";

import './top_bar.scss';

const TopBar = ({activeProp, inActiveProps}) => {
    return (
        <Grid container justifyContent= "center" alignItems = "center" className='top_bar_grid'>
            <Container maxWidth = "lg">
                <div className='pb-[35px] mt-[30px] text-[16px] font-montserrat text-[#27292C] top_bar_parent'>
                    {inActiveProps.map ((inActiveProp, index) => {
                        return (
                            <span key={index}>
                                <span className='underline'>
                                    {inActiveProp}
                                </span>
                                {index !== inActiveProps.length - 1 ? ' / ' :''}
                            </span>
                        )
                    })}
                    <span className='font-semibold'> / {activeProp}</span>
                </div>
            </Container>
        </Grid>
    )
}

export default TopBar;
