import React, { useEffect, useState } from "react";
import RadioGroup from "../inputs/radio_group";
import DropDown from "../inputs/drop_down";
import { sendAppointmentEmail } from "../../redux/actions/email";
import { connect } from "react-redux";
import SuccessModal from "../success_modal/success_modal";
import BreakpointController from "../../components/breakpoint_controller/breakpoint_controller";

import "./content.scss";
import { getAllAppointments } from './afspraak.api';
import { REGEX } from "../../constants/constants";

const Content = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [payload, setPayload] = useState({
    id: '',
    title: '',
    description: '',
  });
  const [formData, setFormData] = useState({
    title: '',
    lastname: '',
    email: '',
    phone: '',
    contact_through: '',
    location: 'Massenhoven',
    reciever_email: '',
  });
  const [formDataErrorState, setFormDataErrorState] = useState({
    name: false,
    lastname: false,
    email: false,
    phone: false
  })
  const [modalState, setModalState ] = useState(false)

  useEffect(() => {
    getAllAppointments(setPayload, setIsFetching);
  }, []);

  const handleRadioChange = (value) => {
    setFormData({
      ...formData,
      location: value,
    });
  };

  const handleDDChange = (value) => {
    setFormData({
      ...formData,
      contact_through: value,
    });
  };

  const handleOnChange = (event) => {
    const { value, name } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if(validateForm()) {
      var obj = {
        ...formData,
        reciever_email: formData?.email,
      };
      props.sendAppointmentEmail(obj).then(response => {
        if(response.data?.response) {
          setModalState(true)
        }
      });
      setFormData({
        title: '',
        lastname: '',
        email: '',
        phone: '',
        contact_through: '',
        location: 'Massenhoven',
        reciever_email: '',
      });
    }
  };

  const validateForm = () => {
    let name = false, lastname = false, email = false, phone = false, contact_through = false;
    let isValid = true;
    if(!formData?.title) {
      isValid = false;
      name = true;
    }
    if(!formData?.lastname) {
      isValid = false;
      lastname = true;
    }
    if(!formData?.contact_through) {
      isValid = false;
    }
    if(!REGEX.EMAIL.test(formData?.email)) {
      isValid = false;
      email = true;
    }
    if(formData?.contact_through === "") {
      isValid = false;
      contact_through = true;
    }
    if(formData?.phone && !REGEX.PHONE.test(formData?.phone)) {
      isValid = false;
      phone = true;
    }
    if(formData?.contact_through === "telefoon" && !REGEX.PHONE.test(formData?.phone)) {
      isValid = false;
      phone = true;
    }
    setFormDataErrorState({name, lastname, email, phone, contact_through});
    return isValid;
  }

  return (
    <div className="sm:mx-[20%] lg:mx-[29%] border-solid mb-[20px] afspraak_form_content">
      <div className="text-[#404040]">
        <div className="text-center text-[35px] font-dmserif mt-[35px]">
          <p dangerouslySetInnerHTML={{ __html: payload?.title }} />
        </div>
        <div className="text-center text-[16px] font-montserrat">
          <div className="appointmentDiv">
            <p dangerouslySetInnerHTML={{ __html: payload?.description }} />
          </div>
        </div>
      </div>
      <div className="mt-[60px] mx-[30px]">
          <div className="mb-[40px]">
            <RadioGroup
                rbState={formData.location}
                setRBState={handleRadioChange}
              labels={["Massenhoven", "Izegem"]}
              styleProps="text-[14px] text-[#8D908B] md:flex md:justify-center"
            />
          </div>
          <div className="mb-[40px]">
            <div className="text-[14px] font-semibold mb-[10px] text-[#27292C]">
              NAAM
            </div>
            <input
              className="appearance-none border-2 text-[14px] rounded-[3px] border-[#D0CCC6] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline animated"
              type="text"
              placeholder={"Naam"}
              style = {{borderColor: formDataErrorState?.name ? "red" : "#D0CCC6"}}
              name="title"
              value={formData.title}
              onChange={handleOnChange}
            />

            <div className="text-[14px] font-semibold mb-[10px] text-[#27292C] pt-[10px]">
              VOORNAAM
            </div>
            <input
              className="appearance-none border-2 text-[14px] rounded-[3px] border-[#D0CCC6] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Voornaam"
              style = {{borderColor: formDataErrorState?.lastname ? "red" : "#D0CCC6"}}
              name="lastname"
              value={formData.lastname}
              onChange={handleOnChange}
            />

            <div className="text-[14px] font-semibold mb-[10px] text-[#27292C] pt-[10px]">
              EMAIL ADRES
            </div>
            <input
              className="appearance-none border-2 text-[14px] rounded-[3px] border-[#D0CCC6] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline animated"
              type="text"
              placeholder="Voorbeeld@email.com"
              style = {{borderColor: formDataErrorState?.email ? "red" : "#D0CCC6"}}
              name="email"
              value={formData.email}
              onChange={handleOnChange}
            />
            <div className="text-[14px] font-semibold mb-[10px] text-[#27292C] pt-[10px]">
              TELEFOON NUMMER
            </div>
            <input
              className="appearance-none border-2 text-[14px] rounded-[3px] border-[#D0CCC6] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline animated"
              type="text"
              placeholder={"+32"}
              style = {{borderColor: formDataErrorState?.phone ? "red" : "#D0CCC6"}}
              name="phone"
              value={formData.phone}
              onChange={handleOnChange}
            />

            <div className="text-[14px] font-semibold mb-[10px] text-[#27292C] pt-[10px]">
              HOE WILT U BENADERD WORDEN VOOR HET MAKEN VAN EEN AFSPRAAK?
            </div>
            <DropDown
              contentArr={["telefoon", "email"]}
              width="full"
              class_="bdd_qty p_group border_color_gray"
              name="contact_through"
              onChange={handleDDChange}
              errorState = {formDataErrorState?.contact_through}
              enablePlaceholder
            />
          </div>
          <div className="text-center submit">
            <button
              className="text-[18px] px-[60px] py-[11px] bg-redOrange-100 hover:bg-redOrange-100 text-white font-semibold rounded-full"
              onClick={handleSubmit}
            >
              Stuur mijn verzoek in
            </button>
          </div>
      </div>
      <SuccessModal message = "Succes!" modalState = {modalState} onTimeout = {() => setModalState(false)} />
    </div>
  );
};
const mapStateToProps = ({ loader }) => ({ loader });
export default connect(mapStateToProps, { sendAppointmentEmail })(Content);
