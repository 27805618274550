import { useLocation, useNavigate } from 'react-router-dom'
import { useLayoutEffect, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types';
import HeaderTop from "../pages/header/header_top";
import FooterTop from "../pages/footer/footer_top";
import FooterLower from "../pages/footer/lower/footer_lower";
import HeaderBottom from "../pages/header_bottom/header_bottom";
import axios from "axios";
import { BASE_URL, getCart, validateToken } from '../redux/constants/ApiEndPoints';
import CookieAlert from '../components/cookie_alert/cookie_alert';
import { cartStore } from '../redux/store/cart';

const ScrollToTopWrapper = ({children}) => {
  const [ cartCount, setCartCount ] = useState(0);

    const location = useLocation();
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("user-data"));
    const cartValue = cartStore((state) => state.didChange);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    useEffect(() => {
      if(userData?.id) {
        axios.get(BASE_URL + getCart + userData?.id, {headers}).then(response => {
          const { products } = response.data.data;
          let count = 0;
          if(products && products?.length > 0) {
            products.forEach(product => {
              count += product?.quantity || 0;
            })
          }
          setCartCount(count)
        }).catch(error => console.log(error))
      }
    }, [cartValue])

    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
      if(location.pathname) {
        // on every navigation, check if user is logged in
        const user = JSON.parse(localStorage.getItem("user-data"));
        if(user?.id) {
          // if user is logged in then grab the token and validate it
          const token = localStorage.getItem("token");
          axios.get(BASE_URL + validateToken, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }).then(response => {
            // do nothing
          }).catch(error => {
            if(error.response.status === 401) {
              // it means token has expired, log the user out and refresh the page
              localStorage.clear();
              navigate(0);
            }
          })
        }
      }
    }, [location.pathname])

    return (
    <>
      <HeaderTop cartCount = {cartCount} />
      <HeaderBottom cartCount = {cartCount} />
      {children}
      <FooterTop />
      <FooterLower />
      <CookieAlert path = {location.pathname} />
    </>)
  } 
  
  export default ScrollToTopWrapper;

  ScrollToTopWrapper.propTypes = {
    display: PropTypes.bool
  }
  
  ScrollToTopWrapper.defaultProps = {
    display : true
  }