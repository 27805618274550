import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Masonry from "react-masonry-css";
import { Link } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import PromotionsCard from "../../components/promotions_card/promotions_card";
import { getAllPromotions } from "../../redux/actions/promotion";
import StickyButton from "../../components/maakeenafspraak_sticky_button/sticky_button";
import { IoMdClose } from "react-icons/io";

import "./promotions.scss";

const PromotionsPage = ({ getAllPromotions, promotion }) => {
  const [promotionsData, setPromotionsData] = useState([])
  const [selectedImage, setSelectedImage ] = useState(null);
  const [brands, setBrands ] = useState([]);

  useEffect(() => {
    getAllPromotions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (promotion.promotions) {
      setPromotionsData(promotion.promotions)
      const brnadArr = promotion.promotions.filter((value, index, self) =>
              index === self.findIndex((t) => (
                  t.brand_id === value.brand_id
              ))
      )
      setBrands(brnadArr)
    };
  }, [promotion.promotions]);

  useEffect(() => {
  }, [promotionsData]);

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  const handlePromotionImageClick = image => {
    setSelectedImage(image)
  }

  return (
    <div id="promotions">
      <Grid container className="top-section pb-[15px] pt-[20px]">
        <Container maxWidth="lg">
          <h3 className="top-heading mb-[40px]">Promoties</h3>

          <Grid container className="mb-[40px]" justifyContent="center">
            {brands?.map(
              (b, i) =>
                b.show && (
                  <Grid item md={2} className="p-[20px]" key={i}>
                    <Link to={"/inventory-page?brand=" + b?.brand_id + "&brandname=" + b?.name} className="link-style-class">
                      {b.brand?.image && b.brand?.image !== "[object Object]" ? (
                        <img src={b.brand?.image} alt="Pin" style={{marginTop: '-22px', height: '50px'}} />
                      ) : (
                        <span className="brand-logo-name">
                          {b.brand?.second_name ? b.brand?.second_name : ""}
                        </span>
                      )}
                    </Link>
                  </Grid>
                )
            )}
          </Grid>

          <StickyButton />

          <Masonry
            className="my-masonry-grid"
            breakpointCols={breakpointColumnsObj}
            columnClassName="my-masonry-grid_column"
          >
            {promotionsData?.map(
              (item, i) => item.show && <PromotionsCard item={item} key={i} handlePromotionImageClick = {handlePromotionImageClick} />
            )}
          </Masonry>
        </Container>
      </Grid>
      {selectedImage && (
        <div className = "fixed left-0 top-0 h-screen w-screen flex justify-center items-center z-[999]">
          <div className = "w-full h-full absolute top-0 left-0 bg-[#000]/[0.5]" onClick = {() => setSelectedImage(null)} />
          <div className = "bg-white box-border p-[25px] relative">
            <div className = "absolute right-0 top-0 cursor-pointer" onClick = {() => setSelectedImage(null)}>
                <IoMdClose size = "1.5em" color = "black" />
            </div>
            <img src = {selectedImage} alt = "promotion image" className = " lg:max-w-[1000px] md:max-w-[750px] max-w-[300px] lg:max-h-[700px] md:max-h-[600px] max-h-[300px] object-contain" />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ promotion, loader }) => ({ promotion, loader });
export default connect(mapStateToProps, { getAllPromotions })(PromotionsPage);
