import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Grid, Container } from "@mui/material";
import { BASE_URL, sign_up, client } from '../../../redux/constants/ApiEndPoints';
import axios from 'axios';
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import SuccessModal from '../../../components/success_modal/success_modal';

import LoginTopBar from '../../../components/top_bars/login_top_bar';
import RadioButton from '../../../components/radio_button/radio_button';
import RadioGroup from '../../../components/inputs/radio_group';
import InputText from '../../../components/inputs/text';
import CheckBox from '../../../components/inputs/checkbox';
import SubmitButton from '../../../components/submit_section';

import './register.scss';
import { REGEX } from '../../../constants/constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { trim } from 'lodash';

const Heading = (props) => {
    const { label, mb, mainHeadings } = props;
    const headingClass = mainHeadings ? `ms:text-[40px] text-[35px] font-dmserif mb-[${mb}] text-[#404040]` : `ms:text-[27px] text-[20px] font-semibold font-montserrat mb-[${mb}] text-[#C98468]`;
    return (
        <div className={headingClass}>
            {label}
        </div>
    )
}

// top most part of the registration form, includes radio button and login form
const EmailPassword = forwardRef((props, ref) => {

    const { formHandler } = props;

    useImperativeHandle(ref, () => ({

    }))

    return (    
        <div className=' mb-[50px]'>
            <RadioGroup 
                rbState={formHandler.values.registerType}
                setRBState={(value)=>formHandler.setFieldValue('registerType', value)}
                labels={["PARTICULIER", "ZAKELIJK"]}
                styleProps='text-[#27292C] font-semibold text-[14px]'
                spanStyleProps='pr_radio_group ml-[-20px]'
                spaceBetween='|||'
            />
            <div className='grid_register_page grid_2_columns ms:mt-[33px] mt-[70px]'>
                <InputText 
                    label='EMAIL'
                    name='email'
                    placeHolderText='Email'
                    value = {formHandler.values.email}
                    handleChange = {formHandler.handleChange}
                    errorState = {formHandler.errors.email && formHandler.touched.email}
                />
                <div>
                    <InputText
                        label='BEVESTIG EMAIL'
                        name='confirmEmail'
                        placeHolderText='Bevestig Email'
                        value={formHandler.values.confirmEmail}
                        handleChange={formHandler.handleChange}
                        errorState={formHandler.errors.confirmEmail && formHandler.touched.confirmEmail}
                    />
                    {formHandler.errors.confirmEmail && formHandler.touched.confirmEmail && <p className='text-left text-[red]'>{formHandler.errors.confirmEmail}</p>}
                </div>
            </div>
            <div className='grid_register_page grid_2_columns mt-[25px] ms:mt-[0px]'>
                <InputText 
                    label='WACHTWOORD'
                    type='password'
                    name='password'
                    placeHolderText='Wachtwoord'
                    value = {formHandler.values.password}
                    handleChange = {formHandler.handleChange}
                    errorState = {formHandler.errors.password && formHandler.touched.password}
                />
                <div>
                    <InputText
                        label='BEVESTIG WACHTWOORD'
                        type='password'
                        name='confirmPassword'
                        placeHolderText='Bevestig wachtwoord'
                        value={formHandler.values.confirmPassword}
                        handleChange={formHandler.handleChange}
                        errorState={formHandler.errors.confirmPassword && formHandler.touched.confirmPassword}
                    />
                    {formHandler.errors.confirmPassword && formHandler.touched.confirmPassword && <p className='text-left text-[red]'>{formHandler.errors.confirmPassword}</p>}
                </div>
            </div>                 
        </div>
    )
})

// second part of the registration form, address part with optional business related fields
const Address = forwardRef((props, ref) => {    
    const { isBusiness, mb, formHandler } = props;

    useImperativeHandle(ref, () => ({
        
    }))
    return (
        <div className='mb-[60px]'>
            <Heading mb='27px' label='Huidig adres'/>
            {isBusiness && (
                <div style={{marginTop: '-8px'}} className='grid_register_page grid_2_columns mt-[70px] ms:mt-[24px]'>
                    <InputText 
                        label='BEDRIJFSNAAM'
                        name='company'
                        placeHolderText='Bedrijfsnaam'
                        value={formHandler.values.company}
                        handleChange={formHandler.handleChange}
                        errorState={formHandler.errors.company && formHandler.touched.company}
                    />
                    <InputText 
                        label='BTW NR'
                        name='company_number'
                        placeHolderText='BE'
                        value={formHandler.values.company_number}
                        handleChange={formHandler.handleChange}
                        errorState={formHandler.errors.company_number && formHandler.touched.company_number}
                    />
                </div>
            )}
            <div className='grid_register_page grid_2_columns mt-[25px]'>
                <InputText 
                    label='NAAM'
                    name='firstName'
                    placeHolderText='Naam'
                    value={formHandler.values.firstName}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.firstName && formHandler.touched.firstName}
                />
                <InputText 
                    label='VOORNAAM'
                    name='lastName'
                    placeHolderText='Voornaam'
                    value={formHandler.values.lastName}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.lastName && formHandler.touched.lastName}
                />
            </div>  
            <div className='grid_register_page grid_columns_7_2 mt-[15px]'>
                <InputText 
                    label='ADRES'
                    name='address.street_1'
                    placeHolderText='Straat'
                    value={formHandler.values.address?.street_1}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.address?.street_1 && formHandler.touched.address?.street_1}
                />
                <div className='grid_register_page mini_grid_2_columns'>
                    <InputText 
                        label='NR'
                        labelColor='#fff'
                        name='address.number_1'
                        placeHolderText='Nr'
                        value={formHandler.values.address?.number_1}
                        handleChange={formHandler.handleChange}
                        errorState={formHandler.errors.address?.number_1 && formHandler.touched.address?.number_1}
                    />
                    <InputText 
                        label='BUS'
                        labelColor='#fff'
                        name='address.number_2'
                        placeHolderText='Bus'
                        value={formHandler.values.address?.number_2}
                        handleChange={formHandler.handleChange}
                        errorState={formHandler.errors.address?.number_2 && formHandler.touched.address?.number_2}
                    />
                </div>
            </div>   
            <div className={`grid_register_page grid_columns_7_2 mb-[${mb}]`}>
                <InputText 
                    label='GEMEENTE'
                    labelColor='#fff'
                    name='address.city'
                    placeHolderText='Gemeente'
                    value={formHandler.values.address?.city}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.address?.city && formHandler.touched.address?.city}
                />
                <InputText 
                    label='POSTCODE'
                    labelColor='#fff'
                    name='address.zip_code'
                    inputStyleProps='border border-[#000000] ms:w-[48%] mt-[10px] py-2 px-3'
                    placeHolderText='Postcode'
                    value={formHandler.values.address?.zip_code}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.address?.zip_code && formHandler.touched.address?.zip_code}
                />
            </div>
            <div className='grid_register_page grid_2_columns mt-[25px]'>
                <InputText 
                    label='EMAIL'
                    name='address.email'
                    placeHolderText='Example@email.com'
                    value={formHandler.values.address?.email}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.address?.email && formHandler.touched.address?.email}
                />
                <InputText 
                    label='TELEFOON NUMMER'
                    name='phone'
                    placeHolderText='+32'
                    value={formHandler.values.phone}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.phone && formHandler.touched.phone}
                />
            </div>   
            <div className='grid_register_page grid_2_columns mt-[25px]'>
                <InputText 
                    label='GEBOORTE DATUM'
                    name='dob'
                    placeHolderText='DD/MM/JJJJ'
                    value={formHandler.values.dob}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.dob && formHandler.touched.dob}
                />
                <InputText 
                    label='GSM NUMMER'
                    name='mobile'
                    placeHolderText='+32 4'
                    value={formHandler.values.mobile}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.mobile && formHandler.touched.mobile}
                />
                {formHandler.errors.dob && formHandler.touched.dob && <p className = "font-[Montserrat] font-medium text-[16px] text-[red]">ongeldige datum DD/MM/JJJJ</p>}
            </div>   
        </div>
    )
})

// third part of the registration form, basic contact info for secondary person
const SecondPersonContact = forwardRef((props, ref) => {
    const { formHandler } = props;

    useImperativeHandle(ref, () => ({
        
    }))
    return (
        <div className='mb-[45px]'>
            <Heading
                mb='27px'
                label='2de contact persoon'
                mainHeadings={false}
            />
            <div className='grid_register_page grid_2_columns mt-[25px]'>
                <InputText 
                    label='NAAM'
                    name='second_contact.firstName'
                    placeHolderText='Naam'
                    value={formHandler.values.second_contact?.firstName}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.second_contact?.firstName && formHandler.touched.second_contact?.firstName}
                />
                <InputText 
                    label='VOORNAAM'
                    name='second_contact.lastName'
                    placeHolderText='Voornaam'
                    value={formHandler.values.second_contact?.lastName}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.second_contact?.lastName && formHandler.touched.second_contact?.lastName}
                />
            </div>  
             
            <div className='grid_register_page grid_2_columns mt-[25px]'>
                <InputText 
                    label='EMAIL'
                    name='second_contact.email'
                    placeHolderText='Example@email.com'
                    value={formHandler.values.second_contact?.email}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.second_contact?.email && formHandler.touched.second_contact?.email}
                />
                <InputText 
                    label='TELEFOON NUMMER'
                    name='second_contact.phone'
                    placeHolderText='+32'
                    value={formHandler.values.second_contact?.phone}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.second_contact?.phone && formHandler.touched.second_contact?.phone}
                />
            </div>  
        </div>
)})

const AddressBilling = forwardRef((props, ref) => {
    const { mb, formHandler } = props

    useImperativeHandle(ref, () => ({
        
    }))

    return (
        <>
            <div className='grid_register_page grid_columns_7_2 mt-[15px]'>
                <InputText 
                    label='ADRES'
                    name='billingForm.street_1'
                    placeHolderText='Straat'
                    value={formHandler.values.billingForm?.street_1}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.billingForm?.street_1 && formHandler.touched.billingForm?.street_1}
                />
                <div className='grid_register_page mini_grid_2_columns'>
                    <InputText 
                        label='NR'
                        labelColor='#fff'
                        name='billingForm.number_1'
                        placeHolderText='Nr'
                        value={formHandler.values.billingForm?.number_1}
                        handleChange={formHandler.handleChange}
                        errorState={formHandler.errors.billingForm?.number_1 && formHandler.touched.billingForm?.number_1}
                    />
                    <InputText 
                        label='BUS'
                        labelColor='#fff'
                        name='billingForm.number_2'
                        placeHolderText='Bus'
                        value={formHandler.values.billingForm?.number_2}
                        handleChange={formHandler.handleChange}
                        errorState={formHandler.errors.billingForm?.number_2 && formHandler.touched.billingForm?.number_2}
                    />
                </div>
            </div>   
            <div className={`grid_register_page grid_columns_7_2 mb-[${mb}]`}>
                <InputText 
                    label='GEMEENTE'
                    labelColor='#fff'
                    name='billingForm.city'
                    placeHolderText='Gemeente'
                    value={formHandler.values.billingForm?.city}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.billingForm?.city && formHandler.touched.billingForm?.city}
                />
                <InputText 
                    label='POSTCODE'
                    labelColor='#fff'
                    name='billingForm.zip_code'
                    inputStyleProps='border border-[#000000] ms:w-[48%] mt-[10px] py-2 px-3'
                    placeHolderText='Postcode'
                    value={formHandler.values.billingForm?.zip_code}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.billingForm?.zip_code && formHandler.touched.billingForm?.zip_code}
                />
            </div>
        </>
    )
})

const AddressLiving = forwardRef((props, ref) => {
    const { mb, formHandler } = props;

    useImperativeHandle(ref, () => ({
        
    }))
    return (
        <>
            <div className='grid_register_page grid_columns_7_2 mt-[15px]'>
                <InputText 
                    label='ADRES'
                    name='livingForm.street_1'
                    placeHolderText='Straat'
                    value={formHandler.values.livingForm?.street_1}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.livingForm?.street_1 && formHandler.touched.livingForm?.street_1}
                />
                <div className='grid_register_page mini_grid_2_columns'>
                    <InputText 
                        label='NR'
                        labelColor='#fff'
                        name='livingForm.number_1'
                        placeHolderText='Nr'
                        value={formHandler.values.livingForm?.number_1}
                        handleChange={formHandler.handleChange}
                        errorState={formHandler.errors.livingForm?.number_1 && formHandler.touched.livingForm?.number_1}
                    />
                    <InputText 
                        label='BUS'
                        labelColor='#fff'
                        name='livingForm.number_2'
                        placeHolderText='Bus'
                        value={formHandler.values.livingForm?.number_2}
                        handleChange={formHandler.handleChange}
                        errorState={formHandler.errors.livingForm?.number_2 && formHandler.touched.livingForm?.number_2}
                    />
                </div>
            </div>   
            <div className={`grid_register_page grid_columns_7_2 mb-[${mb}]`}>
                <InputText 
                    label='GEMEENTE'
                    labelColor='#fff'
                    name='livingForm.city'
                    placeHolderText='Gemeente'
                    value={formHandler.values.livingForm?.city}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.livingForm?.city && formHandler.touched.livingForm?.city}
                />
                <InputText 
                    label='POSTCODE'
                    labelColor='#fff'
                    name='livingForm.zip_code'
                    inputStyleProps='border border-[#000000] ms:w-[48%] mt-[10px] py-2 px-3'
                    placeHolderText='Postcode'
                    value={formHandler.values.livingForm?.zip_code}
                    handleChange={formHandler.handleChange}
                    errorState={formHandler.errors.livingForm?.zip_code && formHandler.touched.livingForm?.zip_code}
                />
            </div>
        </>
    )
})

const RegisterPage = () => {
    const [rbState, setRBState] = useState('PARTICULIER');
    const [successModalState, setSuccessModalState] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const registerForm = useFormik({
        initialValues: {
            registerType: "PARTICULIER",
            // fields for email form section
            email: "",
            confirmEmail: "",
            password: "",
            confirmPassword: "",
            // address form section fields
            firstName: "", 
            lastName: "", 
            company: "", 
            company_number: "", 
            phone: "", 
            dob: "", 
            mobile: "",
            address: {
                street_1: "",
                number_1: "",
                number_2: "",
                zip_code: "",
                city: "",
                email: "",
                billing: true,
                delivery: true
            }, 
            // SecondPersonContact form section fields
            second_contact: {
                firstName: "",
                lastName: "",
                email: "",
                phone: ""
            },
             // AddressBilling form section fields
            billingForm : {
                street_1: "",
                number_1: "",
                number_2: "",
                city: "",
                zip_code: "",
            },
            // AddressLiving form section fields
            livingForm: {
                street_1: "",
                number_1: "",
                number_2: "",
                city: "",
                zip_code: "",
            }
        },
        validationSchema: Yup.object().shape({
            registerType: Yup.string().required(" ").oneOf(["PARTICULIER", "ZAKELIJK"]),
            email: Yup.string().matches(REGEX.EMAIL, { message: "Ongeldig e-mailadres" }).required(" "),
            confirmEmail: Yup.string().matches(REGEX.EMAIL, { message: "Emails komen niet overeen" }).oneOf([Yup.ref('email')], 'Emails komen niet overeen').required(" "),
            password: Yup.string().min(8, 'wachtwoord moet 8 tekens lang zijn.').required(" "),
            confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Wachtwoorden komen niet overeen').required(" "),
            firstName: Yup.string().required(" "),
            lastName: Yup.string().required(" "),
            company: Yup.string().when('registerType', ([registerType], field) => registerType === 'ZAKELIJK' ? field.required(" ") : field),
            company_number: Yup.string().when('registerType', ([registerType], field) => registerType === 'ZAKELIJK' ? field.required(" ") : field),
            phone: Yup.string().matches(REGEX.PHONE).when('mobile', ([mobile], field) => !mobile ? field.required(" "): field),
            dob: Yup.string().matches(REGEX.DATE, { message: "Ongeldig DOB" }).required("Verplicht"),
            mobile: Yup.string().matches(REGEX.PHONE).when('phone', ([phone], field) => !phone? field.required(" "): field),
            address:  Yup.object().shape({
                street_1: Yup.string().required(" "),
                number_1: Yup.string().required(" "),
                number_2: Yup.string().optional(),
                zip_code: Yup.string().required(" "),
                city: Yup.string().required(" "),
                email: Yup.string().matches(REGEX.EMAIL).required(" "),
                billing: Yup.boolean().required(" "),
                delivery: Yup.boolean().required(" "),
            }),
            // SecondPersonContact form section fields
            second_contact:  Yup.object().shape({
                firstName: Yup.string(),
                lastName: Yup.string(),
                email: Yup.string().matches(REGEX.EMAIL),
                phone: Yup.string().matches(REGEX.PHONE),
            }),
            // AddressBilling form section fields
            billingForm: Yup.object().when("address", {
                is: (address) => address?.billing === false,
                then: () => Yup.object().shape({
                    street_1: Yup.string().required(),
                    number_1: Yup.string().required(),
                    number_2: Yup.string().optional(),
                    city: Yup.string().required(),
                    zip_code: Yup.string().required(),
                }),
            }),
            // AddressLiving form section fields
            livingForm: Yup.object().when("address", {
                is: (address) => address?.delivery === false,
                then: () => Yup.object().shape({
                    street_1: Yup.string().required(),
                    number_1: Yup.string().required(),
                    number_2: Yup.string().optional(),
                    city: Yup.string().required(),
                    zip_code: Yup.string().required(),
                }),
            }),
        }, [['phone', 'mobile']]),
        onSubmit: (values) => {
            setErrorMessage("")
            let payload = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
                type_id: 1,
                business: values.registerType === "ZAKELIJK" ? true : false,
                company: values.company,
                company_number: values.company_number,
                addresses: [values.address],
                phone: values.phone,
                dob: values.dob,
                mobile: values.mobile
            }
            // billing form
            payload.addresses.push(!values.address.billing ? values.billingForm : { ...values.address });

            // living form
            payload.addresses.push(!values.address.delivery ? values.livingForm : { ...values.address });

            if (Object.values(values.second_contact).find(item => trim(item) !== "")) {
                payload.second_contact = values.second_contact;
            }
            axios.post(BASE_URL + client, payload).then(response => {
                setSuccessModalState(true)
            }).catch(error => {
                console.log(error);
                if (error?.response?.data) {
                    if (error?.response?.data?.data?.message.includes("ER_DUP_ENTRY")) {
                        setErrorMessage("Dit e-mailadres is niet geschikt voor gebruik. Gelieven te probeer met een ander e-mailadres.");
                    }
                }
            })
        },
    });

    const navigate = useNavigate();
    
    const handleSuccess = () => {
        setSuccessModalState(false);
        navigate("/info")
    }

    return (
        <div>  
            <Grid container justifyContent= "center" alignItems = "center">
                <Container maxWidth = "lg">
                <form onSubmit={registerForm.handleSubmit}>
                    <div className="register_body mx-[12%] mt-[40px] text-center">
                        <Heading mb='27px' label='Registreren'/>
                        <EmailPassword rbState = {rbState} setRBState = {setRBState} formHandler={registerForm}/>
                        <Address isBusiness = {registerForm.values.registerType === "ZAKELIJK"} formHandler={registerForm}/>
                        <SecondPersonContact formHandler={registerForm}/>
                        <div className='mt-[45px]'>
                            <div className='mb-[20px]'>
                                <Heading
                                    mb='10px'
                                    label='Facturatieadres'
                                />
                                <CheckBox
                                    label='identiek aan het huidig adres'
                                    name='address.billing'
                                    checked={registerForm.values.address.billing}
                                    setChecked={(value)=>registerForm.setFieldValue('address.billing', value)}
                                    parentClassProp = 'w-full flex justify-center'
                                />
                            </div>
                            {!registerForm.values.address.billing && 
                                <AddressBilling
                                    mb='50px'
                                    formHandler={registerForm}
                                />
                            }
                            <div className='mb-[25px]'>
                                <Heading
                                    mb='10px'
                                    label='Leveringsadres'
                                />
                                <CheckBox 
                                    checked={registerForm.values.address.delivery}
                                    setChecked={(value)=>registerForm.setFieldValue('address.delivery', value)}
                                    label='identiek aan het huidig adres'
                                    name='checkbox_2'
                                    parentClassProp = 'w-full flex justify-center'
                                />
                            </div>
                            {!registerForm.values.address.delivery &&
                                <AddressLiving
                                    mb='50px'
                                    formHandler={registerForm}
                                />
                            }
                        </div>
                        <SubmitButton submitText='Volgende stap' link='/checkout' onClick = {registerForm.handleSubmit} alternative />
                        {registerForm.submitCount > 0 && !registerForm.isValid && (
                            <p className = "font-[Montserrat] font-medium text-[16px] text-[red] mt-[15px]">Nog niet alle verplichte velden zijn ingevuld</p>
                        )}
                        {errorMessage && (
                            <p className = "font-[Montserrat] font-medium text-[16px] text-[red] mt-[15px]">{errorMessage}</p>
                        )}
                    </div>
                    </form>
                </Container>
            </Grid>
            <SuccessModal modalState = {successModalState} message = "Bevestigingsmail verzonden" onTimeout = {handleSuccess} timeoutDuration = {4000} />
        </div>
    )
}

export default RegisterPage;

Heading.propTypes = {
    label: PropTypes.string,
    mb: PropTypes.string,
    mainHeadings: PropTypes.bool
}

Heading.defaultProps = {
    label: "",
    mb: "",
    mainHeadings: true
}

EmailPassword.propTypes = {
    rbState: PropTypes.any,
    setRBState: PropTypes.func
}

EmailPassword.defaultProps = {
    rbState: false,
    setRBState: () => {}
}

Address.propTypes = {
    isBusiness: PropTypes.bool,
    mb: PropTypes.string
}

Address.defaultProps = {
    isBusiness: false,
    mb: ""
}

AddressBilling.propTypes = {
    mb: PropTypes.string
}

AddressBilling.defaultProps = {
    mb: ""
}

AddressLiving.propTypes = {
    mb: PropTypes.string
}

AddressLiving.defaultProps = {
    mb: ""
}
