import React from "react";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";

import "./lock_modal.scss";

export default function LockModal(props) {
    const { heading, subheader, buttonText, onClick, onExit, openState, subTextClass, headerClass } = props;

    return(
        <div className = "w-screen h-screen fixed top-0 left-0 z-[1000] flex justify-center items-center text-start" style = {{display: openState ? "flex" : "none"}}>
            <div className = "bg-black opacity-[0.4] w-full h-full absolute left-0 top-0" onClick = {onExit} />
            <div className = "box-border py-[20px] sm:w-[500px] w-[400px] bg-white m-10 rounded-[10px] flex flex-col relative">
                <div className = "flex w-full justify-end box-border px-[15px]">
                    <button type = "button" className = "cursor-pointer" onClick = {onExit}>
                        <IoMdClose size = "1.5em" color = "black" />
                    </button>
                </div>
                <div className = "flex flex-col w-full box-border px-[75px]">
                    <p className = {`information_heading text-[35px] text-[#404040] ${headerClass}`}>{heading}</p>
                    <p className = {`font-[Montserrat] font-medium text-[16px] text-[#404040] ${subTextClass}`}>{subheader}</p>
                    {props.children}
                </div>
                <div className = "flex flex-col w-full box-border px-[60px]">
                    
                </div>
                <div className = "flex flex-col w-full box-border items-center px-[75px] mt-[15px]">
                    <button className = "w-[300px] h-[41px] bg-[#C98468] cursor-pointer flex justify-center items-center rounded-[50px]" onClick = {onClick}>
                        <p className = "font-[Montserrat] font-medium text-[16px] text-white">{buttonText}</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

LockModal.propTypes = {
    heading: PropTypes.string,
    subheader: PropTypes.string,
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
    onExit: PropTypes.func,
    openState: PropTypes.bool,
    subTextClass: PropTypes.string,
    headerClass: PropTypes.string
}

LockModal.defaultProps = {
    heading: "",
    subheader: "",
    buttonText: "",
    onClick: () => {},
    onExit: () => {},
    openState: true,
    subTextClass: "",
    headerClass: ""
}
