import {
  GET_VACATURES_DETAIL,
  GET_ALL_JOBS,
  GET_JOB,
} from "../constants/constants";
const INIT_STATE = {
  vacaturesData: null,
  allJobs: null,
  job: null,
};
const vacatures = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VACATURES_DETAIL:
      return {
        ...state,
        vacaturesData: action.payload,
      };
    case GET_ALL_JOBS:
      return {
        ...state,
        allJobs: action.payload,
      };
    case GET_JOB:
      return {
        ...state,
        job: action.payload,
      };
    default:
      return state;
  }
};

export default vacatures;
