import React from "react";
import { Grid } from "@mui/material";
import Details from "./details";
import BreadcrumbsItem from "./../../components/breadcrumb/breadcrumb";

import "./details.scss";

export default function index({ pageDetails, options }) {
  return (
    <div className="category-details-container">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <BreadcrumbsItem options={options} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={8} lg={8} xl={6}>
          <Details pageDetails={pageDetails} />
        </Grid>
      </Grid>
    </div>
  );
}
