import request from './../../common/request';

export const getCadeauData = (setFetching, setResponse) => {
    if (setFetching) {
        setFetching(true);
    }

    const url = '/cadeaus';
    const response = request.get(url);

    response
        .then((res) => {
            if (setFetching) {
                setFetching(false);
            }
            const abc = res.data.data;
            setResponse(abc);
        })
        .catch(() => {
            if (setFetching) {
                setFetching(false);
            }
        });
};
