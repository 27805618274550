import { GET_ALL_CATEGORIES } from "../constants/constants";
const INIT_STATE = {
  all_categories: [],
};
const category = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        all_categories: action.payload,
      };

    default:
      return state;
  }
};

export default category;
