import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import TPLogo from "../../../images/brand_logos/top_interieur.svg"
import { Grid, Container } from "@mui/material";
import { DeleteOutline } from '@mui/icons-material';
import axios from '../../../redux/axios/axios';
import { BASE_URL, getCart, updateCart } from '../../../redux/constants/ApiEndPoints';
import DropDown from '../../../components/inputs/drop_down';

import './cart.scss';
import { cartStore } from '../../../redux/store/cart';

const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const Cart = () => {
    const [ cartList, setCartList ] = useState([]);
    const [ updateState, setUpdateState ] = useState(false);
    const changeStateCart = cartStore((state) => state.setDidChange);
    const cartValue = cartStore((state) => state.didChange);

    const userData = JSON.parse(localStorage.getItem('user-data'))
    const navigate = useNavigate();
    const imageBaseUrl = "https://tp-storage.s3.fr-par.scw.cloud/import";

    useEffect(() => {
        if(userData?.id) {
            axios.get(BASE_URL + getCart + userData?.id, {headers}).then(response => {
                setCartList(response.data.data)
            }).catch(error => console.log(error))
        }
    }, [updateState])

    const updateCartQuantity = (itemIndex, value) => {
        let newCartList = JSON.parse(JSON.stringify(cartList));
        const products = [];
        newCartList?.products?.forEach((cartItem, cartIndex) => {
            if(cartItem.type === 1) {
                products.push({
                    id: cartItem?.id,
                    product_id: cartItem?.product_id,
                    cart_id: cartItem?.cart_id,
                    quantity: cartIndex === itemIndex ? parseInt(value) : cartItem?.quantity
                })
            }
            else {
                products.push({
                    id: cartItem?.id,
                    sale_item_id: cartItem?.sale_item_id,
                    cart_id: cartItem?.cart_id,
                    quantity: cartIndex === itemIndex ? parseInt(value) : cartItem?.quantity
                })
            }
        });
        const payload = {
            id: newCartList?.id,
            user_id: newCartList?.user_id,
            products,
        }
        axios.put(BASE_URL + updateCart + newCartList?.id, payload, {headers}).then(response => {
            setUpdateState(!updateState);
            changeStateCart(!cartValue)
        }).catch(error => console.log(error))
    }

    const deleteItem = itemIndex => {
        let newCartList = JSON.parse(JSON.stringify(cartList));
        let products = [];
        newCartList.products?.splice(itemIndex, 1)
        newCartList?.products?.forEach(cartItem => {
            if(cartItem?.type === 1) {
                products.push({
                    id: cartItem?.id,
                    product_id: cartItem?.product_id,
                    cart_id: cartItem?.cart_id,
                    quantity: cartItem?.quantity
                })
            }
            else {
                products.push({
                    id: cartItem?.id,
                    sale_item_id: cartItem?.sale_item_id,
                    cart_id: cartItem?.cart_id,
                    quantity: cartItem?.quantity
                })
            }
        })
        const payload = {
            id: newCartList?.id,
            user_id: newCartList?.user_id,
            products,
        }
        axios.put(BASE_URL + updateCart + newCartList?.id, payload, {headers}).then(response => 
            {           
                setUpdateState(!updateState)
                changeStateCart(!cartValue)
            }
        ).catch(error => console.log(error))
    }

    const getItemPrice = (cartItem) => {
        if(cartItem?.type === 1) {
            return cartItem?.product?.promotion_price || cartItem?.product?.price || 0;
        }
        else {
            return cartItem?.sale_item?.new_sales_price || cartItem?.sale_item?.original_sales_price || 0;
        }
    }

    const calculateCost = () => {
        if(cartList?.products && cartList?.products?.length > 0) {
            let price = 0;
            cartList?.products?.forEach(cartItem => {
                if(cartItem?.type === 1) {
                    price += (cartItem?.product?.price * cartItem?.quantity || 1);
                }
                else {
                    price += (cartItem?.sale_item?.new_sales_price * cartItem?.quantity || 1);
                }
            })
            return price;
        }
        else {
            return 0;
        }
    }

    const calculateBTW = () => {
        if(cartList?.products && cartList?.products?.length > 0) {
            let price = 0;
            cartList?.products?.forEach(cartItem => {
                if(cartItem?.type === 1) {
                    price += (cartItem?.product?.price * cartItem?.quantity || 1);
                }
                else {
                    price += (cartItem?.sale_item?.new_sales_price * cartItem?.quantity || 1);
                }
            })
            const BTW = (price/100) * 21;
            return BTW.toFixed(2);
        }
        else {
            return 0;
        }
    }

    const calculateTotal = () => {
        if(cartList?.products && cartList?.products?.length > 0) {
            let price = 0;
            cartList?.products?.forEach(cartItem => {
                price += (getItemPrice(cartItem) * cartItem?.quantity || 1)
            })
            return price.toFixed(2);
        }
        else {
            return 0;
        }
    }
    
    const goToProduct = (content) => {
        if(content?.type === 1) {
            navigate(`/product-details/product/${content?.product_id}`)
        }
        else {
            navigate(`/product-details/sale/${content?.sale_item_id}`)
        }
    }

    const TableRow = ({ content, index }) => {
        const getTotalPrice = () => {
            return getItemPrice(content) * (content?.quantity || 1);
        }

        const getImage = () => {
            if(content?.type === 1) {
                return content?.product?.main_image ? imageBaseUrl + content?.product?.main_image : "https://tp-storage.s3.fr-par.scw.cloud/menu/noImage.png";
            }
            else {
                return content?.sale_item?.picture ? imageBaseUrl + content?.sale_item?.picture : TPLogo;
            }
        }

        return (
            <div className='flex md:justify-between items-center bg-[#F7F6F5] md:bg-transparent md:border-b-[1px] md:border-b-[#ececec] flex-wrap md:flex-nowrap box-border p-[10px] md:p-0 md:py-[10px] mb-[13px] md:mb-0 rounded-[5px] mb:rounded-0'>
                <img className = {`flex flex-1 h-[109px] w-[109px] md:h-[108px] ${content?.type === 1 ? "object-cover" : "object-contain"} cursor-pointer`} onClick = {() => goToProduct(content)} src={getImage()}/>
                <div className='flex flex-[2] font-montserrat h-[109px] md:h-auto'>
                    <div className = "flex flex-1 flex-col justify-between md:block h-full md:h-auto ml-[15px] md:ml-[25px]">
                        <div className = "flex flex-col cursor-pointer" onClick = {() => goToProduct(content)}>
                            <div className='font-[Montserrat] font-semibold text-[#404040] text-[15px]'>{content?.product?.name || content?.product?.brand?.name || content?.sale_item?.card_id || ""}</div>
                            <div className='text-[13px] text-[#404040] hidden md:block'>{content?.product?.item_description || content?.sale_item?.branch}</div>
                        </div>
                        <div className='text-[13px] text-[#404040] mt-[9px] block md:hidden'></div>
                        <div className='font-[Montserrat] font-semibold text-[#C98468] text-[15px] block md:hidden'>€ {getItemPrice(content)}</div>
                        <div className = "w-full flex justify-between h-[35px] mt-[17px] items-center block md:hidden">
                            <p className = "font-[Montserrat] text-[13px] font-bold text-[#404040]">Aantal</p>
                            <DropDown
                                contentArr={[1,2,3,4,5,6,7,8]}
                                width='full'
                                class_='bdd_qty p_singular border_color_redOrange h-[35px] w-[70px]'
                                initialValue = {content?.quantity}
                                onChange = {value => updateCartQuantity(index, value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex-[2] vertical_center margin_top_basket_10px text-[#404040] hidden md:flex'></div>
                <div className='flex-1 text-center price_basket text-[#C98468] font-montserrat font-semibold vertical_center hidden md:flex'>
                    € {getItemPrice(content)}
                </div>
                <div className='flex-1 vertical_center drop_down_basket mx-[15px] mb-[-15px] hidden md:flex'>
                    <DropDown
                        contentArr={[1,2,3,4,5,6,7,8]}
                        width='full'
                        class_='bdd_qty p_singular border_color_redOrange'
                        initialValue = {content?.quantity}
                        onChange = {value => updateCartQuantity(index, value)}
                    />
                </div>
                <div className='flex-1 text-[#C98468] total_price_basket vertical_center font-montserrat font-semibold hidden md:flex'>
                    € {getTotalPrice()}
                </div>
                <div className='flex-1 justify-end vertical_center delete_btn_basket cursor-pointer hidden md:flex' onClick = {() => deleteItem(index)}>
                    <DeleteOutline className='text-[#C98468] mr-[15px]'/>
                </div>
                <div className = "w-full flex justify-between items-center mt-[30px] md:hidden">
                    <div className='text-[#404040] font-[Montserrat] text-[13px] vertical_center font-montserrat font-semibold'>
                        SUBTOTAAL
                    </div>
                    <div className='text-[#C98468] font-[Montserrat] text-[13px] vertical_center font-montserrat font-semibold'>
                        € {getTotalPrice() || 0}
                    </div>
                    <div className='vertical_center delete_btn_basket cursor-pointer' onClick = {() => deleteItem(index)}>
                        <DeleteOutline className='text-[#C98468] '/>
                    </div>
                </div>
            </div>
        )
    }

    const TabContent = () => {
        return (
            <div className='tab_content_basket mt-[100px] border-t-[1px] border-t-[#ececec]'>
                {cartList?.products && cartList?.products?.length > 0 && cartList?.products?.map((cartItem, cartIndex) => <TableRow key={`crow-${cartIndex}`} content={cartItem} index = {cartIndex}/>)}
            </div>
        )
    }

    const TotalBasketAmount = () => {
        const RowTotal = ({title, amount}) => {
            return (
                <div className='total_basket_amount mb-[17px]'>
                    <span className='font-montserrat text-[#404040]'>{title}</span>
                    <span className='font-montserrat text-right margin text-[#C98468]'>{amount}</span>
                </div>
            )
        }

        return (
            <div className='pt-[10px] pb-[10px] mt-[60px] row_total_parent_basket bg-[#f8f8f8] '>
                {/* <div className='pt-[36px] md:pl-[70%] row_total_amount_basket border-b-[1px] border-b-[#d0d0d0] pr-[20px]'>
                    <RowTotal title='EXCL. BTW' amount={`€ ${calculateCost()}`}/>
                    <RowTotal title='BTW' amount={`€ ${calculateBTW()}`}/>
                </div> */}
                <div className='md:pl-[70%] mt-[17px] row_total_amount_basket pr-[20px]'>
                    <RowTotal title='TOTAAL BEDRAG' amount={`€ ${calculateTotal()}`}/>
                </div>
            </div>
        )
    }

    const ActionButtons = () => {
        return (
            <div className='flex justify-end mt-[95px] btn_basket_mb'>
                <button className = {`btn_verder_basket_mb text-[18px] ${doesCartHaveItems() && "mr-[50px]"} h-[45px] w-[49%] md:w-[272px] border-[1px] border-[#B96A4B] text-[#B96A4B] font-semibold rounded-full`} onClick = {() => navigate(-1)}>
                    Verder winkelen
                </button>
                {doesCartHaveItems() && (
                    <Link to={userData?.id ? '/checkout/billing' : 'checkout/login'} className = "w-[49%]">
                        <button className="btn_bestellen_basket_mb text-[18px] w-[100%] md:w-[206px] h-[45px] bg-redOrange-100 hover:bg-redOrange-100 text-white font-semibold rounded-full">
                            Bestellen
                        </button>
                    </Link>
                )}
            </div>
        )
    }

    const doesCartHaveItems = () => {
        return (cartList?.id && cartList?.products?.length > 0) ? true : false;
    }
    
    return (
        <div>
            <Grid container justifyContent= "center" alignItems = "center">
                <div className = "w-full">
                    <TabContent/>
                    <TotalBasketAmount/>
                    <ActionButtons />
                </div>
            </Grid>
        </div>
    )
}

export default Cart;
