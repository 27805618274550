import React, { useState, useEffect, Fragment } from "react";
import { Grid, Container } from "@mui/material";
import BreakpointController from "../../components/breakpoint_controller/breakpoint_controller";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL, category, menu, getCart } from "../../redux/constants/ApiEndPoints";
import Brochures from "../brochures/brochures";

import PropTypes from "prop-types";

// components, utils, etc here
import { IoPersonOutline, IoCartOutline, IoHeartOutline } from "react-icons/io5"
import { IoIosArrowDown } from "react-icons/io";
import Logo from "../../images/icons/logo_top.png";
import Person from "../../images/icons/person_2.svg";
import Heart from "../../images/icons/heart.svg";
import Feather from "../../images/icons/feather.svg";

 // menu parts
import { MenuGenericWide } from "./components/menu_catalogue";
import MenuGeneric from "./components/menu_generic";

import "./header_bottom.scss";

function HeaderItemSubCategory(props) {
    const [ menuState, setMenuState ] = useState(0);
    const [ subMenuState, setSubMenuState ] = useState(0)

    const { headerData, headerDataIndex, navigate, isWide, location, menuToggle } = props;

    const handleItemClick = (item, itemIndex) => {
        if(item?.children && item?.children?.length > 0) {
            menuState === (itemIndex + 1) ? setMenuState(0) : setMenuState(itemIndex + 1)
            setSubMenuState(0)
        }
        else {
            const currentPage = location.pathname;
            if(currentPage === "/inventory-page") {
                navigate(`inventory-page?search=${item?.name?.toLowerCase()}`);
                navigate(0);
            }
            else {
                navigate(`inventory-page?search=${item?.name?.toLowerCase()}`);
            }
        }
        menuToggle && menuToggle(0)
    }

    const handleSubItemClick = (item, itemIndex) => {
        if(item?.children && item?.children?.length > 0) {
            subMenuState === (itemIndex + 1) ? setSubMenuState(0) : setSubMenuState(itemIndex + 1)
        }
        else {
            const currentPage = location.pathname;
            if(currentPage === "/inventory-page") {
                navigate(`inventory-page?search=${item?.name?.toLowerCase()}`);
                navigate(0);
            }
            else {
                navigate(`inventory-page?search=${item?.name?.toLowerCase()}`);
            }
        }
        menuToggle && menuToggle(0)
    }

    const handleHeaderItemClick = (item) => {
        const currentPage = location.pathname;
        if(currentPage === "/inventory-page") {
            navigate(`inventory-page?search=${item?.toLowerCase()}`);
            navigate(0);
        }
        else {
            navigate(`inventory-page?search=${item?.toLowerCase()}`);
        }
        menuToggle && menuToggle(0)
    }

    return(
        <>
            {isWide ? (
                <div className = "min-w-[230px] flex flex-col shrink-0">
                    <p className = "font-montserrat font-bold text-[18px] header-menu-subtext cursor-pointer" onClick = {() => handleHeaderItemClick(headerData?.name)}>{headerData?.name || ""}</p>
                    {headerData?.children && headerData?.children?.length > 0 && headerData?.children?.length < 11 ? (
                        <div className = "flex flex-col mt-[33px] mb-[33px]">
                            {headerData?.children && headerData?.children?.length > 0 && headerData?.children?.map((headerDescItem, headerDescIndex) => (
                                <Fragment key={`header-bottom-main-${headerDescIndex}`}>
                                    <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer" onClick = {() => handleItemClick(headerDescItem, headerDescIndex)}>{headerDescItem?.name || ""} {(headerDescItem?.children && headerDescItem?.children?.length > 0) && '>'}</p>
                                    <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: menuState === (headerDescIndex + 1) ? '600px' : '0px', opacity: menuState === (headerDescIndex + 1) ? 1 : 0}}>
                                        {headerDescItem?.children && headerDescItem?.children?.length > 0 && headerDescItem?.children?.map((headerDescSubItem, headerDescSubIndex) => (
                                            <Fragment key={`header-bottom-sub-${headerDescSubIndex}`}>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]" onClick = {() => handleSubItemClick(headerDescSubItem, headerDescSubIndex)}>{headerDescSubItem?.name || ""} {(headerDescSubItem?.children && headerDescSubItem?.children?.length > 0) && '>'}</p>
                                                <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: (menuState === (headerDescIndex + 1) && subMenuState === (headerDescSubIndex + 1)) ? '600px' : '0px', opacity: (menuState === (headerDescIndex + 1) && subMenuState === (headerDescSubIndex + 1)) ? 1 : 0}}>
                                                    {headerDescSubItem?.children && headerDescSubItem?.children?.length > 0 && headerDescSubItem?.children?.map((headerDescFinalItem, index) => (
                                                        <p key={`header-bottom-item-${index}`}className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[30px]" onClick = {() => handleHeaderItemClick(headerDescFinalItem?.name)}>{headerDescFinalItem?.name || ""}</p>
                                                    ))}
                                                </div>
                                            </Fragment>
                                        ))}
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    ) : (
                        <div className = "flex mt-[33px] mb-[33px]">
                            <div className = "flex flex-col">
                                {headerData?.children && headerData?.children?.length > 0 && headerData?.children?.map((headerDescItem, headerDescIndex) => {
                                    return headerDescIndex < 11 && 
                                    <Fragment key={`header-bottom-main-${headerDescIndex}`}>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer" onClick = {() => handleItemClick(headerDescItem, headerDescIndex)}>{headerDescItem?.name || ""} {(headerDescItem?.children && headerDescItem?.children?.length > 0) && '>'}</p>
                                        <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: menuState === (headerDescIndex + 1) ? '600px' : '0px', opacity: menuState === (headerDescIndex + 1) ? 1 : 0}}>
                                            {headerDescItem?.children && headerDescItem?.children?.length > 0 && headerDescItem?.children?.map((headerDescSubItem, headerDescSubIndex) => (
                                                <Fragment key={`header-bottom-sub-${headerDescSubIndex}`}>
                                                    <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]" onClick = {() => handleSubItemClick(headerDescSubItem, headerDescSubIndex)}>{headerDescSubItem?.name || ""} {(headerDescSubItem?.children && headerDescSubItem?.children?.length > 0) && '>'}</p>
                                                    <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: (menuState === (headerDescIndex + 1) && subMenuState === (headerDescSubIndex + 1)) ? '600px' : '0px', opacity: (menuState === (headerDescIndex + 1) && subMenuState === (headerDescSubIndex + 1)) ? 1 : 0}}>
                                                        {headerDescSubItem?.children && headerDescSubItem?.children?.length > 0 && headerDescSubItem?.children?.map((headerDescFinalItem, index) => (
                                                            <p key={`header-bottom-item-${index}`} className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[30px]" onClick = {() => handleHeaderItemClick(headerDescFinalItem?.name)}>{headerDescFinalItem?.name || ""}</p>
                                                        ))}
                                                    </div>
                                                </Fragment>
                                            ))}
                                        </div>
                                    </Fragment>
                                })}
                            </div>
                            <div className = "flex flex-col ml-[25px] mr-[25px]">
                                {headerData?.children && headerData?.children?.length > 0 && headerData?.children?.map((headerDescItem, headerDescIndex) => {
                                    return headerDescIndex >= 11 && 
                                    <Fragment key={`header-bottom-main-${headerDescIndex}`}>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer" onClick = {() => handleItemClick(headerDescItem, headerDescIndex)}>{headerDescItem?.name || ""} {(headerDescItem?.children && headerDescItem?.children?.length > 0) && '>'}</p>
                                        <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: menuState === (headerDescIndex + 1) ? '600px' : '0px', opacity: menuState === (headerDescIndex + 1) ? 1 : 0}}>
                                            {headerDescItem?.children && headerDescItem?.children?.length > 0 && headerDescItem?.children?.map((headerDescSubItem, headerDescSubIndex) => (
                                                <Fragment key={`header-bottom-sub-${headerDescSubIndex}`}>
                                                    <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]" onClick = {() => handleSubItemClick(headerDescSubItem, headerDescSubIndex)}>{headerDescSubItem?.name || ""} {(headerDescSubItem?.children && headerDescSubItem?.children?.length > 0) && '>'}</p>
                                                    <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: (menuState === (headerDescIndex + 1) && subMenuState === (headerDescSubIndex + 1)) ? '600px' : '0px', opacity: (menuState === (headerDescIndex + 1) && subMenuState === (headerDescSubIndex + 1)) ? 1 : 0}}>
                                                        {headerDescSubItem?.children && headerDescSubItem?.children?.length > 0 && headerDescSubItem?.children?.map((headerDescFinalItem, index) => (
                                                            <p key={`header-bottom-item-${index}`} className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[30px]" onClick = {() => handleHeaderItemClick(headerDescFinalItem?.name)}>{headerDescFinalItem?.name || ""}</p>
                                                        ))}
                                                    </div>
                                                </Fragment>
                                            ))}
                                        </div>
                                    </Fragment>
                                })}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className = {`flex flex-col ${headerDataIndex !== 0 && 'ml-[94px]'}`}>
                    <p className = "font-montserrat font-bold text-[18px] header-menu-subtext cursor-pointer" onClick = {() => handleHeaderItemClick(headerData?.name)}>{headerData?.name || ""}</p>
                    <div className = "flex flex-col mt-[33px] ">
                        {headerData?.children && headerData?.children?.length > 0 && headerData?.children?.map((headerDescItem, headerDescIndex) => (
                            <Fragment key={`header-bottom-main-${headerDescIndex}`}>
                                <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer" onClick = {() => handleItemClick(headerDescItem, headerDescIndex)}>{headerDescItem?.name || ""} {(headerDescItem?.children && headerDescItem?.children?.length > 0) && '>'}</p>
                                <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: menuState === (headerDescIndex + 1) ? '600px' : '0px', opacity: menuState === (headerDescIndex + 1) ? 1 : 0}}>
                                    {headerDescItem?.children && headerDescItem?.children?.length > 0 && headerDescItem?.children?.map((headerDescSubItem, headerDescSubIndex) => (
                                        <Fragment key={`header-bottom-sub-${headerDescSubIndex}`}>
                                            <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]" onClick = {() => handleSubItemClick(headerDescSubItem, headerDescSubIndex)}>{headerDescSubItem?.name || ""} {(headerDescSubItem?.children && headerDescSubItem?.children?.length > 0) && '>'}</p>
                                            <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: (menuState === (headerDescIndex + 1) && subMenuState === (headerDescSubIndex + 1)) ? '600px' : '0px', opacity: (menuState === (headerDescIndex + 1) && subMenuState === (headerDescSubIndex + 1)) ? 1 : 0}}>
                                                {headerDescSubItem?.children && headerDescSubItem?.children?.length > 0 && headerDescSubItem?.children?.map((headerDescFinalItem, index) => (
                                                    <p key={`header-bottom-item-${index}`} className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[30px]" onClick = {() => handleHeaderItemClick(headerDescFinalItem?.name)}>{headerDescFinalItem?.name || ""}</p>
                                                ))}
                                            </div>
                                        </Fragment>
                                    ))}
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

function HeaderItemSubCategoryMobile(props) {
    const { data, isOpen, onClick, setBrochureModalId } = props;

    return(
        <div className = "w-full box-border px-[15px] flex flex-col justify-between">
            <div className = "flex justify-between items-center" onClick={onClick}>
                <p className = "font-montserrat text-[14px] text-black my-[12px] uppercase">{data?.name}</p>
                <div className = "animated" style = {{transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                    <IoIosArrowDown color = "#C98468" size = "1.25rem" />
                </div>
            </div>
            <div className = "w-full flex flex-col box-border pl-[15px] animated overflow-hidden" style = {{maxHeight: isOpen ? '1000px' : '0px', opacity: isOpen ? 1 : 0}}>
                {data?.children && data?.children?.length > 0 && data?.children?.map((subItem, subIndex) => (
                    <Fragment key={`mheader-item-sub-${subIndex}`}>
                        <p className = "font-montserrat text-[14px] text-black my-[12px] uppercase" onClick = {() => setBrochureModalId(subItem)}>{subItem?.name}</p>
                        {subIndex !== data?.children?.length - 1 && (
                            <div className = "h-[1px] w-full bg-[#acacac]" />
                        )}
                    </Fragment>
                ))}
            </div>
            <div className = "h-[1px] w-full bg-[#acacac]" />
        </div>
    )
}

function HeaderBottomDesktop(props) {
    const [ menuControl, setMenuControl ] = useState(0);
    const [ topHeaderMenuControl, setTopHeaderMenuControl ] = useState(0);
    const [ topHeaderList, setTopHeaderList ] = useState([]);
    const [ searchState, setSearchState ] = useState("");
    const [ headerList, setHeaderList ] =  useState([]);
    const [ brochureModalId, setBrochureModalId ] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const { cartCount } = props;

    useEffect(() => {
      window.addEventListener('scroll', isSticky);
      getHeaderList();
      axios.get(BASE_URL + menu + "?show=true").then(response => {
        setTopHeaderList(response.data.data)
      }).catch(error => console.log(error))

      return () => {
        window.removeEventListener('scroll', isSticky);
      };
    }, []);

    const toggleMenuControl = (state) => {
      setMenuControl(state);
    }

    const handleSearchChange = event => {
      setSearchState(event.target.value)
    }

    const handleSearchKeyUp = event => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            handleSearchSubmit();
        }
    }

    const isSticky = (e) => {
      const headerStick = document.getElementById('header-stick');
      const headerNonStick = document.getElementById('header-non-stick');
      const scrollTop = window.scrollY;

      if (scrollTop >= 200) {
        headerStick.classList.add('is-sticky');
        headerNonStick.classList.add('is-non-sticky');
      } else {
        headerStick.classList.remove('is-sticky')
        headerNonStick.classList.remove('is-non-sticky')
      }
    };

    const handleRouteAndClose = (route) => {
      setMenuControl(0);
      navigate(route);
    }

    const getHeaderList = () => {
      axios.get(BASE_URL + category + "?show=true").then(response => {
        setHeaderList(response?.data?.data || [])
      }).catch(error => console.log(error))
    }

    const handleSearchSubmit = () => {
        if(searchState) {
            const currentPage = location.pathname;
            if(currentPage === "/inventory-page") {
                navigate(`inventory-page?search=${searchState}`);
                setSearchState("")
                navigate(0);
            }
            else {
                navigate(`inventory-page?search=${searchState}`);
                setSearchState("")
            }
        }
    }

    const handleHeaderItemClick = (item) => {
        const currentPage = location.pathname;
        if(currentPage === "/inventory-page") {
            navigate(`inventory-page?search=${item?.toLowerCase()}`);
            navigate(0);
        }
        else {
            navigate(`inventory-page?search=${item?.toLowerCase()}`);
        }
        setMenuControl(0)
        setTopHeaderMenuControl(0)
    }

    return (
        <>
            <div id="header-stick" className="not-show">
                <div className="bg-[#F7F6F5] w-full flex justify-center pt-[10px] pb-[10px]">
                    <Container maxWidth = "lg">
                        <Grid container>
                            <Grid item md={6}>
                                <Grid item md = {11}>
                                    <Grid container justifyContent="space-between" alignItems="center" className="mt-[10px]">
                                        {topHeaderList && topHeaderList.map(((headerItem, headerIndex) => (
                                            <Fragment key={`header-bottom-item-main-${headerIndex}`}>
                                                {headerItem?.children && headerItem?.children?.length > 0 ? (
                                                    <div className = "h-full" onMouseOver={() => setTopHeaderMenuControl(headerIndex + 1)} onMouseLeave={() => setTopHeaderMenuControl(0)}>
                                                        <p className = "font-montserrat font-regular text-[15px] text-redOrange-100 cursor-pointer" onClick = {() => navigate(headerItem?.type_id !== 1 && (headerItem?.link || headerItem?.url || "/"))}>{headerItem?.name || ""}</p>
                                                        <div className = "absolute header-menu-extended-container" style = {{opacity: topHeaderMenuControl === (headerIndex + 1) ? 1 : 0, transform: topHeaderMenuControl === (headerIndex + 1) ? 'translateY(0px)' : 'translateY(-10px)', zIndex: topHeaderMenuControl  === (headerIndex + 1) ? 99 : -1, pointerEvents: topHeaderMenuControl  === (headerIndex + 1) ? 'all' : 'none'}}>
                                                            {headerItem?.type_id === 1 ? (
                                                                <MenuGeneric>
                                                                    <div className = "flex">
                                                                        {headerItem?.children?.map((headerSubItem, index) => (
                                                                            <div key={`header-bottom-item-sub-${index}`} className = "flex flex-col w-[210px] m-[13px] box-border cursor-pointer" onClick = {() => setBrochureModalId(headerSubItem)}>
                                                                                <div className = {`w-full h-[134px] bg-[${headerSubItem?.color || "#99D1B3"}]/[0.54] flex justify-center`}>
                                                                                    <img className = "w-full h-full object-cover" src = {headerSubItem?.image || ""} alt = "tienerkamer"  />
                                                                                </div>
                                                                                {/* <p className = "font-montserrat font-bold text-[16px] text-[#C98468] mt-[9px] tracking-wider">{headerSubItem?.name}</p> */}
                                                                                <p className = "font-montserrat font-light text-[16px] mb-0 tracking-wider mt-[9px]" style={{ color: 'black' }}>{headerSubItem?.name || ""}</p>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </MenuGeneric>
                                                            ) : (
                                                                <MenuGeneric>
                                                                    <div className = "flex flex-col">
                                                                        {headerItem?.children?.map((headerSubItem, index) => (
                                                                            <p key={`header-bottom-item-sub-${index}`} className = "font-montserrat font-light text-[16px] header-menu-subtext cursor-pointer leading-10" onClick = {() => navigate(headerSubItem?.url || "/")}>{headerSubItem?.name || ""}</p>
                                                                        ))}
                                                                    </div>
                                                                </MenuGeneric>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <Link to={headerItem?.url || "/"} className="link-style-class">
                                                        <p className = "font-montserrat font-regular text-[15px] text-redOrange-100 cursor-pointer">{headerItem?.name}</p>
                                                    </Link>
                                                )}
                                            </Fragment>
                                        )))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <Grid container>
                                    <Grid item md={10}>
                                        <div className = "h-[38px] w-[420px] flex flex-row overflow-hidden flex-nowrap">
                                            <div className = "w-full h-full px-[20px] border-[#C98468] border-[1px] box-border rounded-l-[20px] flex items-center bg-white">
                                                <input type="text" placeholder="Zoek naar producten, categorieën, merken,…" className="w-full h-full font-montserrat text-[15px] text-black font-semibold outline-none bg-transparent" value = {searchState} onKeyUp={handleSearchKeyUp} onChange = {handleSearchChange} style={{ textOverflow: 'ellipsis' }} />
                                            </div>
                                            <button type = "button" className = "w-[108px] h-full bg-[#C98468] rounded-r-[20px] flex flex-row justify-center items-center shrink-0" onClick = {handleSearchSubmit}>
                                                <p className = "font-montserrat font-bold text-[15px] text-white">Zoeken</p>
                                            </button>
                                        </div>
                                    </Grid>

                                    <Grid item md={2}>
                                        <Grid container>
                                            <Grid item md={4}>
                                                <div onClick={()=> handleRouteAndClose('/info')} className="cursor-pointer pt-[10px]">
                                                    <img
                                                        src={Person}
                                                        alt="person"
                                                        className="h-[16px] mr-[5px]"
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div onClick={()=> handleRouteAndClose('/favorites')} className="cursor-pointer pt-[10px]">
                                                    <img
                                                        src={Heart}
                                                        alt="heart"
                                                        className="h-[16px] mr-[5px]"
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div className = "relative cursor-pointer">
                                                    <div onClick={()=> handleRouteAndClose('/cart')} className = "cursor-pointer pt-[10px]">
                                                        <img
                                                            src={Feather}
                                                            alt="feather"
                                                            className="h-[16px]"
                                                        />
                                                    </div>
                                                   {cartCount > 0 && (
                                                     <div className = "w-[14px] h-[14px] absolute rounded-[10px] right-[8px] bottom-[-7px] bg-[#C98468] flex justify-center items-center">
                                                        <p className = "font-[Montserrat] text-white font-regular text-[9px]">{cartCount    }</p>
                                                    </div>
                                                   )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Container>
                </div>
                {/* sticky header, bottom menu part */}
                <Grid container justifyContent = "center" alignItems = "center">
                    <Container maxWidth = "lg">
                        <div className = "h-[61px] w-full flex flex-row justify-between items-center">
                            {headerList && headerList?.length > 0 && headerList?.map((headerItem, headerIndex) => (
                                <div key={`header-bottom-smain-${headerIndex}`} className = {`h-full ${headerItem?.children?.length < 5 && 'relative flex justify-center'}`}>
                                    <div className = "h-full flex justify-center items-center" onMouseOver={() => toggleMenuControl(headerIndex + 1)} onMouseLeave={() => toggleMenuControl(0)}>
                                        <p className = "font-montserrat font-semibold header-menu-text" style = {{color: menuControl === headerIndex + 1 ? '#404040' : '#ffffff', fontSize: menuControl === headerIndex + 1 ? '18px' : '17px'}} onClick = {() => headerItem?.type_id === 2 && navigate(headerItem?.link || "")}>{headerItem?.name || ""}</p>
                                    </div>
                                    {headerItem?.type_id === 0 && (
                                        <div className = {`absolute top-[100%] ${headerIndex >= (headerList?.length - 3) ? 'right-0' : headerIndex <= 2 ? 'left-0' : ''} header-menu-extended-container`} style = {{opacity: menuControl === headerIndex + 1 ? 1 : 0, transform: menuControl === headerIndex + 1 ? 'translateY(0px)' : 'translateY(-10px)', zIndex: menuControl === headerIndex + 1 ? 99 : -1, pointerEvents: menuControl  === headerIndex + 1 ? 'all' : 'none'}}>
                                            {headerItem?.children && headerItem?.children?.length > 4 ? (
                                                <>
                                                    <MenuGenericWide onMouseOver={() => toggleMenuControl(headerIndex + 1)} onMouseLeave={() => toggleMenuControl(0)}>
                                                        {headerItem?.children && headerItem?.children?.length > 0 && headerItem?.children?.map((headerSubItem, headerSubIndex) => (
                                                            <HeaderItemSubCategory key={`header-bottom-ssub-${headerSubIndex}`} headerData = {headerSubItem} navigate = {navigate} location = {location} />
                                                        ))}
                                                    </MenuGenericWide>
                                                </>
                                            ) : (
                                                <>
                                                    <MenuGeneric onMouseOver={() => toggleMenuControl(headerIndex + 1)} onMouseLeave={() => toggleMenuControl(0)}>
                                                        {headerItem?.children && headerItem?.children?.length > 0 && headerItem?.children?.map((headerSubItem, headerSubIndex) => (
                                                            <div key={`header-bottom-ssub-${headerSubIndex}`} className = {`flex flex-col ${headerSubIndex !== 0 && 'ml-[94px]'}`}>
                                                                <p className = "font-montserrat font-bold text-[18px] header-menu-subtext cursor-pointer" onClick = {() => handleHeaderItemClick(headerSubItem?.name)}>{headerSubItem?.name || ""}</p>
                                                                <div className = "flex">
                                                                    <div className = "flex flex-col">
                                                                        {headerSubItem?.children && headerSubItem?.children?.length > 0 && headerSubItem?.children?.map((headerDescItem, headerDescIndex) => {
                                                                            return headerDescIndex < 11 && (
                                                                                <p key={`header-bottom-desc-item-${headerDescIndex}`} className = {`font-montserrat font-light text-[16px] header-menu-subtext cursor-pointer leading-10 ${headerDescIndex === 0 && 'mt-[33px]'}`} onClick = {() => handleHeaderItemClick(headerDescItem?.name)}>{headerDescItem?.name || ""}</p>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    <div className = "flex flex-col ml-[25px]">
                                                                        {headerSubItem?.children && headerSubItem?.children?.length > 0 && headerSubItem?.children?.map((headerDescItem, headerDescIndex) => {
                                                                            return headerDescIndex >= 11 && (
                                                                                <p key={`header-bottom-desc-item-${headerDescIndex}`} className = {`font-montserrat font-light text-[16px] header-menu-subtext cursor-pointer leading-10 ${headerDescIndex === 11 && 'mt-[33px]'}`} onClick = {() => handleHeaderItemClick(headerDescItem?.name)}>{headerDescItem?.name || ""}</p>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </MenuGeneric>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    {headerItem?.type_id === 1 && (
                                        <div className = "absolute left-0 header-menu-extended-container" style = {{opacity: menuControl === headerIndex + 1 ? 1 : 0, transform: menuControl === headerIndex + 1 ? 'translateY(0px)' : 'translateY(-10px)', zIndex: menuControl  === headerIndex + 1 ? 99 : -1, pointerEvents: menuControl === headerIndex + 1 ? 'all' : 'none'}}>
                                            <MenuGenericWide onMouseOver={() => toggleMenuControl(headerIndex + 1)} onMouseLeave={() => toggleMenuControl(0)}>
                                                {headerItem?.children && headerItem?.children?.length > 0 && headerItem?.children?.map((headerSubItem, headerSubIndex) => (
                                                    <div key={`header-bottom-sub-item-${headerSubIndex}`} className = "flex flex-col w-[210px] m-[13px] box-border cursor-pointer shrink-0" onClick = {() => navigate(headerSubItem?.link || "")}>
                                                        <div className = {`w-full h-[134px] bg-[${headerSubItem?.color}]/[0.54] flex justify-center`}>
                                                            <img className = "w-[160px] h-[138px] object-contain" src = {headerSubItem?.image} alt = "category" />
                                                        </div>
                                                        <p className = "font-montserrat font-light text-[16px] mt-[9px] tracking-wider header-menu-subtext">{headerSubItem?.name || ""}</p>
                                                    </div>
                                                ))}
                                            </MenuGenericWide>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </Container>
                </Grid>
            </div>
            {/* non sticky, default header */}
            <Grid id="header-non-stick" container justifyContent = "center" alignItems = "center" className = "header-menu relative">
                <Container maxWidth = "lg">
                    <div className = "h-[61px] w-full flex flex-row justify-between items-center">
                        {headerList && headerList?.length > 0 && headerList?.map((headerItem, headerIndex) => (
                            <div key={`header-bottom-nsmain-${headerIndex}`} className = {`h-full ${headerItem?.children?.length < 5 && 'relative flex justify-center'}`}>
                                <div className = "h-full flex justify-center items-center" onMouseOver={() => toggleMenuControl(headerIndex + 1)} onMouseLeave={() => toggleMenuControl(0)}>
                                    <p className = "font-montserrat font-semibold header-menu-text" style = {{color: menuControl === headerIndex + 1 ? '#C98468' : '#404040', fontSize: menuControl === headerIndex + 1 ? '18px' : '17px'}} onClick = {() => headerItem?.type_id === 2 && navigate(headerItem?.link || "")}>{headerItem?.name || ""}</p>
                                </div>
                                {headerItem?.type_id === 0 && (
                                    <div className = {`absolute top-[100%] ${headerIndex >= (headerList?.length - 3) ? 'right-0' : headerIndex <= 2 ? 'left-0' : ''} header-menu-extended-container`} style = {{opacity: menuControl === headerIndex + 1 ? 1 : 0, transform: menuControl === headerIndex + 1 ? 'translateY(0px)' : 'translateY(-10px)', zIndex: menuControl  === headerIndex + 1 ? 99 : -1}}>
                                        {headerItem?.children && headerItem?.children?.length > 4 ? (
                                            <>
                                                {/* if there are more than 5 sub menu items then we load menu generic wide */}
                                                <MenuGenericWide onMouseOver={() => toggleMenuControl(headerIndex + 1)} onMouseLeave={() => toggleMenuControl(0)}>
                                                    {headerItem?.children && headerItem?.children?.length > 0 && headerItem?.children?.map((headerSubItem, headerSubIndex) => (
                                                        <HeaderItemSubCategory key={`header-bottom-nssub-${headerSubIndex}`} menuToggle={toggleMenuControl} headerData = {headerSubItem} navigate = {navigate} location = {location} />
                                                    ))}
                                                </MenuGenericWide>
                                            </>
                                        ) : (
                                            <>
                                                <MenuGeneric onMouseOver={() => toggleMenuControl(headerIndex + 1)} onMouseLeave={() => toggleMenuControl(0)}>
                                                    {headerItem?.children && headerItem?.children?.length > 0 && headerItem?.children?.map((headerSubItem, headerSubIndex) => (
                                                        <div key={`header-bottom-nssub-${headerSubIndex}`} className = {`flex flex-col ${headerSubIndex !== 0 && 'ml-[94px]'}`}>
                                                            <p className = "font-montserrat font-bold text-[18px] header-menu-subtext cursor-pointer" onClick = {() => handleHeaderItemClick(headerSubItem?.name)}>{headerSubItem?.name || ""}</p>
                                                            <div className = "flex">
                                                                <div className = "flex flex-col">
                                                                    {headerSubItem?.children && headerSubItem?.children?.length > 0 && headerSubItem?.children?.map((headerDescItem, headerDescIndex) => {
                                                                        return headerDescIndex < 11 && (
                                                                            <p key={`header-bottom-nsdesc-${headerDescIndex}`} className = {`font-montserrat font-light text-[16px] header-menu-subtext cursor-pointer leading-10 ${headerDescIndex === 0 && 'mt-[33px]'}`} onClick = {() => handleHeaderItemClick(headerDescItem?.name)}>{headerDescItem?.name || ""}</p>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className = "flex flex-col ml-[25px]">
                                                                    {headerSubItem?.children && headerSubItem?.children?.length > 0 && headerSubItem?.children?.map((headerDescItem, headerDescIndex) => {
                                                                        return headerDescIndex >= 11 && (
                                                                            <p key={`header-bottom-nssub-${headerDescIndex}`} className = {`font-montserrat font-light text-[16px] header-menu-subtext cursor-pointer leading-10 ${headerDescIndex === 11 && 'mt-[33px]'}`} onClick = {() => handleHeaderItemClick(headerDescItem?.name)}>{headerDescItem?.name || ""}</p>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </MenuGeneric>
                                            </>
                                        )}
                                    </div>
                                )}
                                {headerItem?.type_id === 1 && (
                                    <div className = "absolute left-0 header-menu-extended-container" style = {{opacity: menuControl === headerIndex + 1 ? 1 : 0, transform: menuControl === headerIndex + 1 ? 'translateY(0px)' : 'translateY(-10px)', zIndex: menuControl  === headerIndex + 1 ? 99 : -1}}>
                                        <MenuGenericWide onMouseOver={() => toggleMenuControl(headerIndex + 1)} onMouseLeave={() => toggleMenuControl(0)}>
                                            {headerItem?.children && headerItem?.children?.length > 0 && headerItem?.children?.map((headerSubItem, headerSubIndex) => (
                                                <div key={`header-bottom-nssub1 -${headerSubIndex}`} className = "flex flex-col w-[210px] m-[13px] box-border cursor-pointer shrink-0" onClick = {() => {
                                                    navigate(headerSubItem?.link || "")
                                                    setMenuControl(0)
                                                    setTopHeaderMenuControl(0)
                                                }}>
                                                    <div className = {`w-full h-[134px] bg-[${headerSubItem?.color}]/[0.54] flex justify-center`}>
                                                        <img className = "w-[160px] h-[138px] object-contain" src = {headerSubItem?.image} alt = "category" />
                                                    </div>
                                                    <p className = "font-montserrat font-light text-[16px] mt-[9px] tracking-wider">{headerSubItem?.name || ""}</p>
                                                </div>
                                            ))}
                                        </MenuGenericWide>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </Container>
            </Grid>
            {brochureModalId && (
                <Brochures brochureId = {brochureModalId} onClose = {() => setBrochureModalId(null)} />
            )}
        </>
    );

}

function HeaderBottomMobile(props) {
    const [ menuState, setMenuState ] = useState(false);
    const [ menuCoreControl, setMenuCoreControl ] = useState(0)
    const [ menuCategoryControl, setMenuCategoryControl ] = useState(0)
    const [ menuSubCategoryControl, setMenuSubCategoryControl ] = useState(0);
    const [ menuDescControl, setMenuDescControl ] = useState(0)
    const [ headerList, setHeaderList ] = useState([]);
    const [ topHeaderList, setTopHeaderList ] = useState([]);
    const [ searchState, setSearchState ] = useState("");
    const [ brochureModalId, setBrochureModalId ] = useState(null);
    
    const navigate = useNavigate();
    const location = useLocation();
    const userData = JSON.parse(localStorage.getItem('user-data'));
    const { cartCount } = props;

    useEffect(() => {
        getHeaderList();
        axios.get(BASE_URL + menu + "?show=true").then(response => {
            setTopHeaderList(response.data.data)
          }).catch(error => console.log(error))
    }, [])

    const handleMenuClick = () => {
        setMenuState(!menuState)
    }

    const openCoreMenuItem = index => {
        if(menuCoreControl === index) {
            setMenuCoreControl(0)
        }
        else {
            setMenuCoreControl(index)
        }
    }

    const openCategoryMenuItem = (index, isLink) => {
        if(isLink) {
            navigate(isLink)
            setMenuState(false)
        }
        else {
            if(menuCategoryControl === (index + 1)) {
                setMenuCategoryControl(0)
            }
            else {
                setMenuCategoryControl(index + 1)
            }
        }
    }

    const openSubCategoryMenuItem = (index, isLink) => {
        if(isLink) {
            console.log(isLink, 'openSubCategoryMenuItem');
            navigate(isLink)
            setMenuState(false)
        }
        else {
            if(menuSubCategoryControl === (index + 1)) {
                setMenuSubCategoryControl(0)
            }
            else {
                setMenuSubCategoryControl(index + 1)
            }
        }
    }

    const openDescMenuItem = (index, isLink, item) => {
        if(isLink) {
            console.log(isLink, 'openDescMenuItem');
            //navigate(isLink)
            handleHeaderItemClick(item);
            setMenuState(false)
        }
        else {
            if(menuDescControl === (index + 1)) {
                setMenuDescControl(0)
            }
            else {
                setMenuDescControl(index + 1)
            }
        }
    }

    const handleHeaderItemClick = (item) => {
        const currentPage = location.pathname;
        if(currentPage === "/inventory-page") {
            navigate(`inventory-page?search=${item?.toLowerCase()}`);
            navigate(0);
        }
        else {
            navigate(`inventory-page?search=${item?.toLowerCase()}`);
        }
    }

    const closeMenuItem = (route) => {
        setMenuState(false)
        setMenuCoreControl(0);
        if (route) {
            navigate(route);
        }
    }

    const getHeaderList = () => {
        axios.get(BASE_URL + category + "?show=true").then(response => {
            setHeaderList(response?.data?.data || [])
        }).catch(error => console.log(error))
    }

    const handleSearchChange = event => {
        setSearchState(event.target.value)
    }

    const handleSearchKeyUp = event => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            handleSearchSubmit();
        }
    }

    const handleSearchSubmit = () => {
        if(searchState) {
            const currentPage = location.pathname;
            if(currentPage === "/inventory-page") {
                navigate(`inventory-page?search=${searchState}`);
                setSearchState("")
                navigate(0);
            }
            else {
                navigate(`inventory-page?search=${searchState}`);
                setSearchState("")
            }
        }
    }

    const openBrochureModal = id => {
        setBrochureModalId(id)
    }

    return(
        <div className = "flex flex-col w-full">
            <div className = "h-[61px] w-full flex items-center justify-between box-border p-[15px]">
                <button type = "button" className = "h-[20px] w-[30px] flex flex-col justify-between cursor-pointer" onClick = {handleMenuClick}>
                    <div className = "h-[2px] w-full bg-[#C98468]" />
                    <div className = "h-[2px] w-full bg-[#C98468]" />
                    <div className = "h-[2px] w-full bg-[#C98468]" />
                </button>
                <button type = "button" className = "cursor-pointer" onClick={() => navigate(`/`, {state: {comingFrom: "header"}})}>
                    <img src = {Logo} alt = "logo" className = "w-[194px] object-contain translate-y-[-12px]" />
                </button>
                <button type = "button" className = "flex justify-center items-center cursor-pointer relative">
                    <div className = "relative" onClick = {() => navigate("/cart")}>
                        <IoCartOutline color = "#C98468" size = "2rem" />
                    </div>
                    {cartCount > 0 && (
                        <div className = "w-[14px] h-[14px] absolute rounded-[10px] right-[-7px] bottom-[-7px] bg-[#C98468] flex justify-center items-center">
                            <p className = "font-[Montserrat] text-white font-regular text-[9px]">{cartCount}</p>
                        </div>
                    )}
                </button>
            </div>
            {menuState &&
                <div className = "header-menu-extended-container" style = {{opacity: menuState ? 1 : 0, transform: menuState ? 'translateY(0px)' : 'translateY(-15px)', maxHeight: menuState ? '2000px' : '0px'}}>
                    <div className = "w-full box-border px-[15px] flex flex-col justify-between overflow-hidden">
                        <div className = "h-[1px] w-full bg-[#acacac]" />
                        <div className = "flex justify-between items-center" onClick = {() => openCoreMenuItem(1)}>
                            <p className = "font-montserrat text-[14px] text-black my-[12px]">CATAGORIEËN</p>
                            <div className = "animated" style = {{transform: menuCoreControl === 1 ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                                <IoIosArrowDown color = "#C98468" size = "1.25rem" />
                            </div>
                        </div>
                        <div className = "w-full flex flex-col box-border pl-[15px] animated" style = {{opacity: menuCoreControl === 1 ? 1 : 0, maxHeight: menuCoreControl === 1 ? '2000px' : '0px'}}>
                            {headerList && headerList.length > 0 && headerList.map((headerItem, headerIndex) => (
                                <div key={`mheader-bottom-item-${headerIndex}`} className = "w-full box-border flex flex-col justify-between">
                                    <div className = "flex justify-between items-center cursor-pointer" onClick={() => openCategoryMenuItem(headerIndex, headerItem?.children?.length > 0 ? null : headerItem?.link)}>
                                        <p className = "font-montserrat text-[14px] text-black my-[12px] uppercase">{headerItem?.name || ""}</p>
                                        <div className = "animated" style = {{transform: menuCategoryControl === (headerIndex + 1) ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                                            {headerItem?.children?.length > 0 && (
                                                <IoIosArrowDown color = "#C98468" size = "1.25rem" />
                                            )}
                                        </div>
                                    </div>
                                    <div className = "w-full flex flex-col box-border pl-[15px] animated overflow-hidden" style = {{maxHeight: menuCategoryControl === (headerIndex + 1) ? '1000px' : '0px', opacity: menuCategoryControl === (headerIndex + 1) ? 1 : 0}}>
                                        {headerItem?.children && headerItem?.children?.length > 0 && headerItem?.children?.map((headerSubItem, headerSubIndex) => (
                                            <Fragment key={`mheader-bottom-sub-item-${headerSubIndex}`}>
                                                <div className = "flex justify-between items-center cursor-pointer" onClick={() => openSubCategoryMenuItem(headerSubIndex, headerSubItem?.children?.length > 0 ? null : headerSubItem?.link)}>
                                                    <p className = "font-montserrat text-[14px] text-black my-[12px] uppercase">{headerSubItem?.name || ""}</p>
                                                    <div className = "animated" style = {{transform: menuSubCategoryControl === (headerSubIndex + 1) ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                                                        {headerSubItem?.children?.length > 0 && (
                                                            <IoIosArrowDown color = "#C98468" size = "1.25rem" />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className = "w-full flex flex-col box-border pl-[15px] animated overflow-hidden" style = {{maxHeight: menuSubCategoryControl === (headerSubIndex + 1) ? '1000px' : '0px', opacity: menuSubCategoryControl === (headerSubIndex + 1) ? 1 : 0}}>
                                                    {headerSubItem?.children && headerSubItem?.children?.length > 0 && headerSubItem?.children?.map((headerDescItem, headerDescIndex) => (
                                                        <div key={`mheader-bottom-desc-${headerDescIndex}`}>
                                                            <p onClick={() => openDescMenuItem(headerDescIndex, headerDescItem?.children?.length > 0 ? null : headerDescItem?.link, headerDescItem?.name)} className = "font-montserrat text-[14px] text-black my-[12px] uppercase">{headerDescItem?.name || ""}</p>
                                                            {headerDescIndex !== (headerSubItem?.children?.length - 1) && (
                                                                <div className = "h-[1px] w-full bg-[#acacac]" />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                                {headerSubIndex !== (headerItem?.children?.length - 1) && (
                                                    <div className = "h-[1px] w-full bg-[#acacac]" />
                                                )}
                                            </Fragment>
                                        ))}
                                    </div>
                                    {headerIndex !== (headerList?.length - 1) && (
                                        <div className = "h-[1px] w-full bg-[#acacac]" />
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className = "h-[1px] w-full bg-[#acacac]" />
                    </div>
                    {topHeaderList && topHeaderList?.length > 0 && topHeaderList?.map((headerItem, headerIndex) => (
                        <Fragment key={`mheader-bottom-tp-${headerIndex}`}>
                            {headerItem?.children && headerItem?.children?.length > 0 ? (
                                <HeaderItemSubCategoryMobile data = {headerItem} isOpen = {menuCoreControl === headerIndex} onClick = {() => setMenuCoreControl(menuCoreControl === headerIndex ? 0 : headerIndex)} setBrochureModalId = {setBrochureModalId} />
                            ) : (
                                <div className = "w-full box-border px-[15px] flex flex-col justify-between">
                                    <p onClick={() => closeMenuItem(headerItem?.link || headerItem?.url || "/")} className = "font-montserrat text-[14px] text-black my-[12px] uppercase">{headerItem?.name}</p>
                                    <div className = "h-[1px] w-full bg-[#acacac]" />
                                </div>
                            )}
                        </Fragment>
                    ))}
                    <div className = "w-full box-border px-[15px] flex flex-col justify-between">
                      <div className = "flex items-center my-[12px] cursor-pointer">
                        <IoHeartOutline color = "#B96A4B" size = "1.25rem" />
                        <p onClick={() => closeMenuItem('/favorites')} className = "font-montserrat text-[14px] text-[#B96A4B] ml-[13px]">WENSLIJST</p>
                      </div>
                      <div className = "h-[1px] w-full bg-[#acacac]" />
                    </div>
                    <div className = "w-full box-border px-[15px] flex flex-col justify-between cursor-pointer">
                      <div className = "flex items-center my-[12px]">
                        <IoPersonOutline color = "#B96A4B" size = "1.25rem" />
                        <p onClick={() => closeMenuItem('/info')} className = "font-montserrat text-[14px] text-[#B96A4B] ml-[13px]">MIJN ACCOUNT</p>
                      </div>
                    </div>
                </div>
            }
            <div className = "w-full flex box-border px-[10px] mt-[15px]">
                <div className = "h-[41px] w-full flex flex-row overflow-hidden flex-nowrap">
                    <div className = "w-full h-full px-[24px] border-[#C98468] border-[1px] box-border rounded-l-[20px] flex items-center bg-white">
                        <input type="text" style={{ overflow: 'ellipsis' }} placeholder="Zoek naar producten, categorieën, merken,…" className="w-full h-full font-montserrat text-[12px] font-regular outline-none bg-transparent" value = {searchState} onKeyUp={handleSearchKeyUp} onChange = {handleSearchChange} />
                    </div>
                    <button type = "button" className = "w-[108px] h-full bg-[#C98468] rounded-r-[20px] flex flex-row justify-center items-center shrink-0" onClick = {handleSearchSubmit}>
                        <p className = "font-montserrat font-bold text-[12px] text-white">Zoeken</p>
                    </button>
                </div>
            </div>
            {brochureModalId && (
                <Brochures brochureId = {brochureModalId} onClose = {() => setBrochureModalId(null)} />
            )}
        </div>
    )
}

export default function HeaderBottom(props) {
    const { cartCount } = props;

    return(
        <Grid container>
            <div className = "hidden lg:flex w-full">
                <HeaderBottomDesktop cartCount = {cartCount} />
            </div>
            <div className = "flex lg:hidden w-full">
                <HeaderBottomMobile cartCount = {cartCount} />
            </div>
        </Grid>
    )
}

HeaderBottomDesktop.propTypes = {
  setMenuControl: PropTypes.func,
  cartCount: PropTypes.number
}

HeaderBottomDesktop.defaultProps = {
    cartCount: 0
}

HeaderBottomMobile.propTypes = {

}

HeaderItemSubCategory.defaultProps = {
  headerData: {},
  headerDataIndex: 0,
  navigate: () => {},
  isWide: true
}

HeaderItemSubCategory.propTypes = {
  headerData: PropTypes.object,
  headerDataIndex: PropTypes.number,
  navigate: PropTypes.func,
  isWide: PropTypes.bool
}

HeaderBottom.propTypes = {
    cartCount: PropTypes.number
}

HeaderBottom.defaultProps = {
    cartCount: 0
}
