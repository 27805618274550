import React, { useState } from "react";
import InputText from "../../../components/inputs/text";

export default function ApplicationForm({
  fields,
  hanldeChangeFields,
  isMobile
}) {
  const Heading = ({ label, mb, mainHeadings = true }) => {
    const props = mainHeadings
      ? `ms:text-[40px] text-[35px] font-dmserif mb-[${mb}] text-[#404040]`
      : `ms:text-[27px] text-[20px] font-semibold font-montserrat mb-[${mb}] text-[#C98468]`;
    return <div className={props}>{label}</div>;
  };

  return (
    <div className="mb-[60px]">
      <Heading mb="27px" label="Soliciteer" />
      <div className="grid_2_columns mt-[25px]">
        <InputText
          id={fields[0]?.id}
          value={fields[0]?.value}
          handleChange={hanldeChangeFields}
          label="NAAM"
          name="name"
          placeHolderText="Naam"
          errorState = {fields[0]?.error}
        />
        <InputText
          id={fields[1]?.id}
          value={fields[1]?.value}
          handleChange={hanldeChangeFields}
          label="VOORNAAM"
          name="voornam"
          placeHolderText="Voornaam"
          parentStyleProps = "md:mt-[0px] mt-[25px] text-start"
          errorState = {fields[1]?.error}
        />
      </div>
      <div className="md:grid grid_columns_7_2 mt-[25px]">
        <InputText
          id={fields[2]?.fields[0].id}
          label="ADRES"
          name={"2"}
          placeHolderText="Straat"
          value={fields[2]?.fields[0].value}
          handleChange={hanldeChangeFields}
        />
        <div className="grid mini_grid_2_columns md:mt-[0px] mt-[25px]">
          <InputText
            id={fields[2]?.fields[1].id}
            label="NR"
            labelColor = {!isMobile? "#fff" : ""}
            name={"2"}
            placeHolderText= "Nr"
            value={fields[2]?.fields[1].value}
            handleChange={hanldeChangeFields}
          />
          <InputText
            id={fields[2]?.fields[2].id}
            label="BUS"
            labelColor = {!isMobile? "#fff" : ""}
            name={"2"}
            placeHolderText= "Bus"
            value={fields[2]?.fields[2].value}
            handleChange={hanldeChangeFields}
          />
        </div>
      </div>
      <div className="md:grid grid_columns_7_2">
        <InputText
          id={fields[2]?.fields[3].id}
          label="GEMEENTE"
          name={"2"}
          placeHolderText={"Gemeente"}
          value={fields[2]?.fields[3].value}
          handleChange={hanldeChangeFields}
          parentStyleProps = "md:mt-[0px] mt-[25px] text-start"
          labelStyleProps = "md:hidden inline font-[Montserrat] font-semibold text-[14px]"
        />
        <InputText
          id={fields[2]?.fields[4].id}
          label="POSTCODE"
          name={"2"}
          placeHolderText={"Postcode"}
          value={fields[2]?.fields[4].value}
          handleChange={hanldeChangeFields}
          parentStyleProps = "md:mt-[0px] mt-[25px] text-start"
          labelStyleProps = "md:hidden inline font-[Montserrat] font-semibold text-[14px]"
        />
      </div>
      <div className="md:grid grid_2_columns mt-[25px]">
        <InputText
          id={fields[3]?.id}
          value={fields[3]?.value}
          label="EMAIL"
          name="personal_email"
          placeHolderText="Example@email.com"
          handleChange={hanldeChangeFields}
          errorState = {fields[3]?.error}
        />
        <InputText
          id={fields[4]?.id}
          value={fields[4]?.value}
          label="GSM NUMMER"
          name="phone"
          placeHolderText="+32"
          handleChange={hanldeChangeFields}
          parentStyleProps = "md:mt-[0px] mt-[25px] text-start" 
          errorState = {fields[4]?.error}
        />
      </div>
      <div className="md:grid grid_2_columns mt-[25px]">
        <InputText
          id={fields[5]?.id}
          value={fields[5]?.value}
          label="GEBOORTE DATUM"
          name="date_birth"
          placeHolderText="DD/MM/JJJJ"
          handleChange={hanldeChangeFields}
          type="date"
        />
      </div>
    </div>
  );
}
