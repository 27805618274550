import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import CategoryCard from "../../components/button_card/index";
import Details from "../../components/project_category_details/index";
import { getAllProjects } from "../../redux/actions/project";
import "./categories.scss";

const SelectedCategory = ({ getAllProjects, project }) => {
  let { categoryId } = useParams();
  const [projectsList, setProjectsList] = useState([]);
  const [categoryDescription, setCategoryDescription] = useState();
  const [categoryName, setCategoryName] = useState();
  const options = {
    icons: {},
    items: [
      { to: "/", label: "Home" },
      { to: "/project-categories", label: "Projecten" },
      { to: "/", label: `${categoryName}`, active: true },
    ],
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  useEffect(() => {
    project?.all_categories?.forEach((p) => {
      if (p.id === categoryId && p.children.length > 0) {
        setProjectsList(p.children);
        setCategoryName(p.name);
        setCategoryDescription({
          title: p.name,
          description: p.description,
        });
      }
    });
  }, [project.all_categories, categoryId]);

  return (
    <>
      <Details options={options} pageDetails={categoryDescription} />
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="categories-selected h-auto mb-[20px] mt-[40px] gap-[20px]">
              {projectsList.map((obj, index) => (
                <Fragment key={`s-cat-${index}`}>
                  {obj?.show && (
                    <CategoryCard obj={obj} />
                  )}
                </Fragment>
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const mapStateToProps = ({ project, loader }) => ({
  project,
  loader,
});
export default connect(mapStateToProps, {
  getAllProjects,
})(SelectedCategory);
