import React, { useEffect, useState } from "react";
import { Grid, Container } from "@mui/material";
import InputText from "../../components/inputs/text";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, reset_password } from "../../redux/constants/ApiEndPoints";
import axios from "axios";
import SuccessModal from "../../components/success_modal/success_modal";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./reset_password.scss";

export default function ResetPassword() {
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [error, setError] = useState("")
    const [modalState, setModalState] = useState(false)

    const { search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(search)
        setEmail(query.get("email"))
        setToken(query.get("token"))
    }, [])

    const resetForm = useFormik({
        initialValues: {
            password: "",
            password_confirmation: "",
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().min(8, 'wachtwoord moet 8 tekens lang zijn.').required(" "),
            password_confirmation: Yup.string()
            .oneOf([Yup.ref('password')], 'Wachtwoorden komen niet overeen').required(" "),
        }),
        onSubmit: (values) => {
            const payload = {
                email,
                token,
                ...values
            }
            axios.post(BASE_URL + reset_password, payload).then(response => {
                setModalState(true)
            }).catch(error => console.log(error))
        },
    });

    const handleNavigation = () => {
        navigate("/info");
        setModalState(false)
    }

    return(
        <Grid container justifyContent = "center" alignItems = "center">
            <Container maxWidth = "lg">
                <Grid container justifyContent = "center">
                    <form onSubmit={resetForm.handleSubmit}>
                        <div className="flex flex-col justify-center">
                            <p className="font-[SerifDisplay] font-regular text-[35px] text-[#404040] mt-[25px]">Wachtwoord opnieuw instellen</p>
                            <p className="font-[Montserrat] font-regular text-[16px] text-[#404040] mt-[5px]">voer een nieuw wachtwoord in</p>
                            <InputText
                                label='Nieuw wachtwoord'
                                parentStyleProps='text-left mt-[25px]'
                                labelStyleProps="font-montserrat font-semibold text-[14px] uppercase"
                                type='password'
                                name='password'
                                inputStyleProps="border border-[#000000] w-full mt-[10px] py-2 px-3"
                                value={resetForm.values.password}
                                handleChange={resetForm.handleChange}
                                errorState={resetForm.errors.password && resetForm.touched.password}
                            />
                            <InputText
                                label='Bevestiging nieuw wachtwoord'
                                parentStyleProps='text-left mt-[25px]'
                                labelStyleProps="font-montserrat font-semibold text-[14px] uppercase"
                                type='password'
                                name='password_confirmation'
                                inputStyleProps="border border-[#000000] w-full mt-[10px] py-2 px-3"
                                value={resetForm.values.password_confirmation}
                                handleChange={resetForm.handleChange}
                                errorState={resetForm.errors.password_confirmation && resetForm.touched.password_confirmation}
                            />
                            {resetForm.errors.password && resetForm.touched.password && (
                                <p className="font-[Montserrat] font-medium text-[16px] text-[red] mt-[15px]">{resetForm.errors.password}</p>
                            )}
                            {resetForm.errors.password_confirmation && resetForm.touched.password_confirmation && (
                                <p className="font-[Montserrat] font-medium text-[16px] text-[red] mt-[15px]">{resetForm.errors.password_confirmation}</p>
                            )}
                            <div className="w-full flex justify-center mt-[25px]">
                                <button type="submit" className="flex items-center justify-center cursor-pointer text-[18px] h-[45px] w-[220px] font-montserrat bg-redOrange-100 hover:bg-redOrange-100 text-white font-semibold rounded-full">
                                    Aanmelden
                                </button>
                            </div>
                        </div>
                    </form>
                </Grid>
            </Container>
            <SuccessModal modalState = {modalState} message = "wachtwoord is succesvol gereset" onTimeout = {handleNavigation} />
        </Grid>
    )
}
