import React, { Fragment } from "react";

import "./pagination.scss";

const Pagination = (props) => {
  const handlePagination = (current) => {
    props.pagination(current);
  };

  return (
    <div className="page">
      <ul className="page-list sm:w-full">
        <li className="page-item hidden md:inline">
          <a
            className={`page-link ${
              props.current === 1
                ? "disabled-link"
                : props.current > 1
                ? "active-link"
                : ""
            }`}
            href="#"
            onClick={() => handlePagination(props.current - 1)}
          >
            Vorig
          </a>
        </li>
        {props.total < 7 ? (
          <>
            {Array.apply(0, Array(props.total)).map((arr, i) => (
              <Fragment key={`pl-${i}`}>
                <li
                  className={`page-item ${
                    props.current === i + 1 ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePagination(i + 1)}
                  >
                    {i + 1}
                  </a>
                </li>
              </Fragment>
            ))}
          </>
        ) : props.current % 5 >= 0 &&
          props.current > 4 &&
          props.current + 2 < props.total ? (
          <>
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(1)}
              >
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link disabled" href="#">
                ...
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(props.current - 1)}
              >
                {props.current - 1}
              </a>
            </li>
            <li className="page-item active">
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(props.current)}
              >
                {props.current}
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(props.current + 1)}
              >
                {props.current + 1}
              </a>
            </li>
            <li className="page-item">
              <a className="page-link disabled" href="#">
                ...
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(props.total)}
              >
                {props.total}
              </a>
            </li>
          </>
        ) : props.current % 5 >= 0 &&
          props.current > 4 &&
          props.current + 2 >= props.total ? (
          <>
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(1)}
              >
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link disabled" href="#">
                ...
              </a>
            </li>
            <li
              className={`page-item ${
                props.current === props.total - 3 ? "active" : ""
              }`}
            >
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(props.total - 3)}
              >
                {props.total - 3}
              </a>
            </li>
            <li
              className={`page-item ${
                props.current === props.total - 2 ? "active" : ""
              }`}
            >
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(props.total - 2)}
              >
                {props.total - 2}
              </a>
            </li>
            <li
              className={`page-item ${
                props.current === props.total - 1 ? "active" : ""
              }`}
            >
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(props.total - 1)}
              >
                {props.total - 1}
              </a>
            </li>
            <li
              className={`page-item ${
                props.current === props.total ? "active" : ""
              }`}
            >
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(props.total)}
              >
                {props.total}
              </a>
            </li>
          </>
        ) : (
          <>
            {Array.apply(0, Array(5)).map((arr, i) => (
              <Fragment key={`pl-${i}`}>
                <li
                  className={`page-item ${
                    props.current === i + 1 ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePagination(i + 1)}
                  >
                    {i + 1}
                  </a>
                </li>
              </Fragment>
            ))}
            <li className="page-item">
              <a className="page-link disabled" href="#">
                ...
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                onClick={() => handlePagination(props.total)}
              >
                {props.total}
              </a>
            </li>
          </>
        )}
        <li className="page-item">
          <a
            className={`${
              props.current === props.total
                ? "page-link disabled-link"
                : props.current < props.total
                ? "page-link active-link"
                : ""
            }`}
            href="#"
            onClick={() => handlePagination(props.current + 1)}
          >
            {props.total.length > 0 && "Volgende"}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
