import React from "react";
import PropTypes from "prop-types";

export default function MenuGeneric(props) {
    const { translate, padding, onMouseOver, onMouseLeave } = props;
    
    return(
        <div className = {`p-[40px] header-menu-extended flex justify-between bg-white`} style = {{transform: `translateX(${translate}px)`, padding:`${padding}px`}} onMouseOver = {onMouseOver} onMouseLeave = {onMouseLeave}>
            {props.children}
        </div>
    )
}

MenuGeneric.propTypes = {
    translate: PropTypes.number,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func
}

MenuGeneric.defaultProps = {
    translate: 0,
    onMouseOver: () => {},
    onMouseLeave: () => {}
}