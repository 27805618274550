import logo1 from '../../assets/images/logos/1.png';
import logo2 from '../../assets/images/logos/2.png';
import logo3 from '../../assets/images/logos/3.png';
import logo4 from '../../assets/images/logos/4.png';
import logo5 from '../../assets/images/logos/5.png';
import logo6 from '../../assets/images/logos/6.png';
import logo7 from '../../assets/images/logos/7.png';
import logo8 from '../../assets/images/logos/8.png';

export const checkConstants = [
  {
    id: 0,
    name: "Izegem",
    selected: true,
  },
  {
    id: 1,
    name: `Massenhoven`,
    selected: true,
  },
];

export const all_brands = [
  {
    id: 1,
    title: "Arper",
    type: "Izegem",
    logo: logo1,
  },
  {
    id: 2,
    title: "Blomus",
    type: "Massenhoven",
    logo: logo2,
  },
  {
    id: 3,
    title: "Arper2",
    type: "Izegem",
    logo: logo3,
  },
  {
    id: 4,
    title: "Blomus2",
    type: "Massenhoven",
    logo: logo4,
  },
  {
    id: 5,
    title: "Arper3",
    type: "Izegem",
    logo: logo5,
  },
  {
    id: 6,
    title: "Blomus3",
    type: "Massenhoven",
    logo: logo6,
  },
  {
    id: 7,
    title: "Arper4",
    type: "Izegem",
    logo: logo7,
  },
  {
    id: 8,
    title: "Blomus4",
    type: "Massenhoven",
    logo: logo8,
  },
  // {
  //   id: 9,
  //   title: "Arper5",
  //   type: "Izegem",
  //   logo: logo9,
  // },
  // {
  //   id: 10,
  //   title: "Blomus5",
  //   type: "Massenhoven",
  //   logo: logo10,
  // },
  // {
  //   id: 11,
  //   title: "Arper6",
  //   type: "Izegem",
  //   logo: logo11,
  // },
  // {
  //   id: 12,
  //   title: "Blomus6",
  //   type: "Massenhoven",
  //   logo: logo12,
  // },
  // {
  //   id: 13,
  //   title: "Arper7",
  //   type: "Izegem",
  //   logo: logo13,
  // },
  // {
  //   id: 14,
  //   title: "Blomus7",
  //   type: "Massenhoven",
  //   logo: logo14,
  // },
  // {
  //   id: 15,
  //   title: "Arper8",
  //   type: "Izegem",
  //   logo: logo15,
  // },
  // {
  //   id: 16,
  //   title: "Blomus8",
  //   type: "Massenhoven",
  //   logo: logo16,
  // },
];