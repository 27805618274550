export const checkConstants = [
  {
    id: 0,
    name: "Izegem",
    selected: true,
  },
  {
    id: 1,
    name: `Massenhoven`,
    selected: true,
  },
];

export const jobsList = [
  {
    id: 1,
    title: "Sous-Chef",
    description: "Short description of the fonction",
    location: "Massenhoven",
    type: "Fulltime/part time/hours…",
  },
  {
    id: 2,
    title: "Interieuradviseure M/V afdeling wonen",
    description: "Short description of the fonction",
    location: "Izegem",
    type: "Fulltime/part time/hours…",
  },
  {
    id: 3,
    title: "Receptionist",
    description: "Short description of the fonction",
    location: "Massenhoven",
    type: "Fulltime/part time/hours…",
  },
  {
    id: 4,
    title: "Receptionist",
    description: "Short description of the fonction",
    location: "Izegem",
    type: "Fulltime/part time/hours…",
  },
  {
    id: 5,
    title: "Receptionist",
    description: "Short description of the fonction",
    location: "Massenhoven",
    type: "Fulltime/part time/hours…",
  },
  {
    id: 6,
    title: "Receptionist",
    description: "Short description of the fonction",
    location: "Massenhoven",
    type: "Fulltime/part time/hours…",
  },
];

export const jobApplicationFields = [
  {
    id: 0,
    label: "NAAM",
    name: "name",
    value: null,
    placeholder: "Naam",
    error: false,
    errorMsg: "",
  },
  {
    id: 1,
    label: "VOORNAAM",
    name: "firstName",
    value: null,
    placeholder: "Naam",
    error: false,
    errorMsg: "",
  },
  {
    id: 2,
    label: "ADRES",
    name: "address",
    fields: [
      { id: 0, value: null, name: "Straat" },
      { id: 1, value: null, name: "Nr" },
      { id: 2, value: null, name: "Bus" },
      { id: 3, value: null, name: "Gemeente" },
      { id: 4, value: null, name: "Postcode" },
    ],
    error: false,
    errorMsg: "",
  },
  {
    id: 3,
    label: "EMAIL",
    name: "email",
    value: null,
    placeholder: "Example@email.com",
    error: false,
    errorMsg: "",
  },
  {
    id: 4,
    label: "GSM NUMMER",
    name: "number",
    value: null,
    placeholder: "+32",
    error: false,
    errorMsg: "",
  },
  {
    id: 5,
    label: "GEBOORTE DATUM",
    name: "date",
    value: null,
    placeholder: "DD/MM/jjjj",
    error: false,
    errorMsg: "",
  },
  {
    id: 6,
    label: "Documents",
    name: "documents",
    value: [],
    placeholder: "",
    error: false,
    errorMsg: "",
  },
];

export const jobApplicationFiles = [
  {
    id: 0,
    btnTitle: "Kies bestand",
    value: null,
    description: "Upload uw CV (sterk aanbevolen)",
    error: false,
    errorMsg: "",
  },
  {
    id: 1,
    btnTitle: "Kies bestand",
    value: null,
    description: "Upload uw motivatiebrief (sterk aanbevolen)",
    error: false,
    errorMsg: "",
  },
  {
    id: 2,
    btnTitle: "Kies bestand",
    value: null,
    description: "Upload uw ID (sterk aanbevolen)",
    error: false,
    errorMsg: "",
  },
  {
    id: 3,
    btnTitle: "Kies bestand",
    value: null,
    description: "Upload uw foto",
    error: false,
    errorMsg: "",
  },
];
