import request from './../../common/request';

export const getAllCategories = (setFetching, setResponse) => {
    if (setFetching) {
        setFetching(true);
    }

    const url = '/category';
    const response = request.get(url);

    response
        .then((res) => {
            if (setFetching) {
                setFetching(false);
            }
            let list = res.data.data.find(f => f.name === 'Meubelen');
            list = list?.children;
            setResponse(list);
        })
        .catch(() => {
            if (setFetching) {
                setFetching(false);
            }
        });
};

export const getCategoryById = (setFetching, setResponse, id) => {
    if (setFetching) {
        setFetching(true);
    }

    const url = `/category/show/${id}`;
    const response = request.get(url);

    response
        .then((res) => {
            if (setFetching) {
                setFetching(false);
            }
            let list = res.data.data;
            setResponse(list);
        })
        .catch(() => {
            if (setFetching) {
                setFetching(false);
            }
        });
};
