import { GET_ALL_PROMOIONS } from "../constants/constants";
const INIT_STATE = {
  promotions: [],
};
const promotion = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PROMOIONS:
      return {
        ...state,
        promotions: action.payload,
      };

    default:
      return state;
  }
};

export default promotion;
