import React from "react";
import { Grid, Container } from "@mui/material";
import RoundedButton from "../../components/rounded_button/rounded_button";

import "./choose_file.scss";

export default function ChooseFile({ id, description, btnTitle, fileHandler }) {
  return (
    <Grid container className="mt-4">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="choose-container">
          <div className="choose-text">
            <span className="font-[Montserrat] fs-16">{description}</span>
          </div>
          <div className="choose-btn">
            <RoundedButton
              id={id}
              title={btnTitle}
              fileHandler={fileHandler}
              type={"file"}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
