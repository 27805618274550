import React, { useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import axios from "axios";
import { BASE_URL, sign_in, forgot_password } from '../../../redux/constants/ApiEndPoints';
import LoginTopBar from '../../../components/top_bars/login_top_bar';
import InputText from '../../../components/inputs/text';
import PropTypes from "prop-types";
import LockModal from '../../../components/lock_modal/lock_modal';

import './login.scss';
import ForgetPassword from "./forgotPassword";
import { REGEX } from '../../../constants/constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const LoginPage = (props) => {
    const [forgotPasswordModalState, setForgotPasswordModalState] = useState(false)
    const [errorStates, setErrorStates ] = useState({
        success: false
    })
    const navigate = useNavigate();

    const loginForm = useFormik({
        initialValues: {
            email: "",
            password: "",
            type: 1,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().matches(REGEX.EMAIL, { message: "Ongeldig e-mailadres" }).required("Verplicht"),
            password: Yup.string().required("Verplicht"),
        }),
        onSubmit: (values, { resetForm }) => {
            axios.post(BASE_URL + sign_in, values).then(response => {
                const token = response.data.data.info.auth.token;
                let userData = { ...response.data.data.info }
                delete userData.auth;
                localStorage.setItem('token', token);
                localStorage.setItem('user-data', JSON.stringify(userData));
                navigationPath ? navigate(navigationPath) : navigate(0)
            }).catch(error => {
                if (error?.response?.data?.data?.message === "Ongeldig e-mailadres of wachtwoord") {
                    setErrorStates({ success: true })
                }
            })
        },
    });

    const loginLabelClass = 'font-montserrat font-semibold text-[14px]';
    const loginInputClass = 'border border-[#000000] w-full mt-[10px] py-2 px-3';
    const cardParentClass = 'text-center px-[25px] md:px-[115px] h-fit login_card_parent';
    const mainHeadingClass = 'text-[30px] font-dmserif login_main_heading';
    const subHeadingClass = 'text-[16px] mb:[15px] md:mb-[45px] font-montserrat login_sub_heading';
    const buttonClass = "text-[18px] h-[45px] w-[220px] font-montserrat bg-redOrange-100 hover:bg-redOrange-100 text-white font-semibold rounded-full"
    const { navigationPath } = props;

    const handleForgotPasswordModalClose = () => {
        setForgotPasswordModalState(false)
    }

    const NewRegistrationCard = () => {
        return (
            <div className={cardParentClass}>
                <div className={mainHeadingClass}>
                    Registreren
                </div>
                <div className={subHeadingClass}>
                    Indien je nog geen account hebt op deze website
                </div>
                <Link to='/register'>
                    <button className={`${buttonClass} mt-[75px] login_button`}>
                        Registreer
                    </button>
                </Link>
            </div>
        )
    }

    return (
        <div>
            <LoginTopBar isActive = {false} />    
            <Grid container justifyContent= "center" alignItems = "center">
                <div className='loginpage_body_parent mb:mb-[94px]'>
                    <div className={cardParentClass}>
                        <form onSubmit={loginForm.handleSubmit}>
                            <div className={mainHeadingClass}>
                                Log in
                            </div>
                            <div className={subHeadingClass}>
                                Indien je al een account  hebt  opt deze  website
                            </div>
                            <InputText
                                label='EMAIL'
                                parentStyleProps='text-left'
                                labelStyleProps={loginLabelClass}
                                type='text'
                                name='email'
                                inputStyleProps={loginInputClass}
                                value={loginForm.values.email}
                                handleChange={loginForm.handleChange}
                                errorState={loginForm.errors.email && loginForm.touched.email}
                            />
                            <InputText
                                label='WACHTWOORD'
                                parentStyleProps='text-left mt-[20px] mb-[26px]'
                                labelStyleProps={loginLabelClass}
                                type='password'
                                name='password'
                                inputStyleProps={loginInputClass}
                                value={loginForm.values.password}
                                handleChange={loginForm.handleChange}
                                errorState={loginForm.errors.password && loginForm.touched.password}
                            />

                            <div className='underline font-montserrat text-[16px] mb-[45px] cursor-pointer' onClick={() => setForgotPasswordModalState(true)}>
                                Wachtwoord vergeten?
                            </div>
                            <button type='submit' className={buttonClass}>
                                Aanmelden
                            </button>
                            {errorStates?.success && (
                                <p className="font-montserrat font-regular text-[16px] text-[red] mt-[10px]">Email en/of wachtwoord is niet correct.</p>
                            )}
                        </form>
                        <ForgetPassword
                            forgotPasswordModalState={forgotPasswordModalState}
                            setForgotPasswordModalState={setForgotPasswordModalState}
                            handleForgotPasswordModalClose={handleForgotPasswordModalClose}
                        />
                    </div>
                    <NewRegistrationCard />
                </div>
            </Grid>
        </div>
    )
}

export default LoginPage;

LoginPage.defaultProps = {
    navigationPath: null
}

LoginPage.propTypes = {
    navigationPath: PropTypes.string
}
