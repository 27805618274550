import React, {useEffect, useState} from "react";
import { Grid, Container } from "@mui/material";

import BreadcrumbsItem from "../../components/breadcrumb/breadcrumb";
import {getCadeauData} from "./cadeau.api";

import "./cadeau.scss";
import StickyButton from "../../components/maakeenafspraak_sticky_button/sticky_button";

const CadeauPage = () => {
    const [isFetching, setIsFetching] = useState(false);
    const [cadeauData, setCadeauData] = useState([]);

    useEffect(() => {
        getCadeauData(setIsFetching, setCadeauData);
    }, []);

    const options = {
        icons: {},
        items: [
            { to: "/", label: "Home" },
            { to: "/", label: "Cadeau's", active: true },
        ]
    };

    return(
        <div id="cadeau">
            <StickyButton />
            <Grid container className="top-section pb-[15px]">
                <Container maxWidth="lg">
                    <Grid item md={12}>
                        <BreadcrumbsItem options={options} />
                    </Grid>

                    <Grid item md={12}>
                        <h3 className="top-text-heading">{cadeauData?.title}</h3>
                    </Grid>
                </Container>
            </Grid>

            <Container maxWidth="lg">
                <Grid
                    item
                    md={12}
                >
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <img src = {cadeauData?.image} className = "w-full max-h-[385px] object-contain" />
                    </div>
                </Grid>

                <Grid item md={12} className="form-text-1">
                    <div className="linkDiv">
                        <p dangerouslySetInnerHTML={{ __html: cadeauData?.content }}></p>
                    </div>
                </Grid>
            </Container>
        </div>
    )
};

export default CadeauPage;
