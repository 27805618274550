import React from "react";
import { Grid } from "@mui/material";

import "./../categories.scss";
import "react-responsive-modal/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Image1 = ({ images, projectImages, onOpenModal }) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt-10 pb-[10px] pr-[10px]">
      <div style={{ height: 532 }}>
        <img
            src={projectImages && projectImages[0]?.image}
            alt="img"
            className="categories-images"
            onClick={() => onOpenModal(0)}
        />
      </div>
    </Grid>
  );
};

export default Image1;
