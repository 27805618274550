import React from "react";
import { Grid, Container } from "@mui/material";

import "./cookies.scss";
import StickyButton from "../../components/maakeenafspraak_sticky_button/sticky_button";

const CookiesPage = () => {
    return (
        <div id="cookies-page">
            <StickyButton />

            <Grid container className="top-section pb-[15px]">
                <Container maxWidth="lg">

                    <Grid item md={12}>
                        <h3 className="top-text-heading pt-[15px] mt-[10px] mb-[10px]" style={{ lineHeight: 'normal' }}>Websitegebruik & cookies bij Top Interieur</h3>
                    </Grid>
                </Container>
            </Grid>
            <Container maxWidth="lg">
                <Grid container className="pt-[17px] text-text">
                    <Grid item md={12}>
                        <p>
                            <h3 className="text-heading pt-[15px] mt-[10px] mb-[10px]">Wat zijn cookies?</h3>
                            <p>
                                Cookies zijn kleine stukjes informatie die je computer of mobiele apparaat download als je een website bezoekt. Cookies hebben verschillende functies; zo helpen ze Top Interieur te begrijpen hoe de website wordt gebruikt, zorgen zij ervoor dat je efficiënt kunt navigeren door de website, herinneren ze je voorkeuren en verbeteren ze het gebruik van de website in het algemeen. Cookies kunnen er bovendien voor zorgen dat online advertenties beter zijn afgestemd op je persoonlijke voorkeuren.

                                <br/>
                                <br/>
                                Sommige mensen denken dat cookies worden gebruikt om privégegevens of wachtwoorden te onderscheppen. Dit is zeker niet het geval. Cookies zijn volkomen veilig. Op deze pagina vertellen wij over de cookies die wij gebruiken.
                            </p>

                            <div id="privacy">
                                <h3 className="text-heading pt-[15px] mt-[10px] mb-[10px]">Jouw privacyinstellingen</h3>
                                <p>Je hebt zelf controle over welke cookies je wilt accepteren.</p>
                            </div>
                        </p>
                    </Grid>
                    <Grid item md={6} className="pr-[15px] pt-[25px]">
                        <p className="sub-head">Benodigde cookies</p>
                        <p>
                            Bepaalde cookies zijn vereist voor de correcte werking van de website, bijvoorbeeld het onthouden van jouw winkelwagen of inlog-status.
                        </p>
                    </Grid>
                    <Grid item md={6} className="pt-[25px]">
                        <p className="sub-head">Overige cookies</p>
                        <p>
                            Deze cookies helpen ons jou te identificeren en verzamelt jouw voorkeuren, waardoor wij de website beter kunnen afstemmen op wat jij zoekt.
                        </p>
                    </Grid>
                    <Grid item md={12}>
                        <h3 className="text-heading pt-[15px] mt-[10px] mb-[10px]">Welke cookies zijn er?</h3>
                        <p className="sub-head">Top Interieur maakt gebruik van de volgende type cookies</p>
                        <p className="small-heading pt-[40px] pb-[10px]">Noodzakelijke cookies</p>

                        Deze cookies zijn essentieel omdat ze je in staat stellen te bewegen op de website en de mogelijkheden ervan te gebruiken. Zoals de toegang tot de beveiligde omgeving. Deze cookies bevatten geen persoonlijke informatie en worden niet gebruikt voor marketingdoeleinden. Ze onthouden ook niet waar je bent geweest op het internet.


                        <h3 className="text-heading pt-[15px] mt-[10px] mb-[10px]">Weten cookies wie ik ben?</h3>
                        Nee. Je naam, adres, leeftijd en andere persoonlijke gegevens kan een cookie niet achterhalen of onthouden.

                        <h3 className="text-heading pt-[15px] mt-[10px] mb-[10px]">Wat kan ik doen?</h3>
                        De meeste browsers kun je zodanig instellen dat cookies worden geweigerd of dat je een bericht krijgt wanneer je een cookie ontvangt. Hoewel je de website nog steeds kunt bezoeken, is het mogelijk dat als je cookies weigert, bepaalde diensten of elementen van de website niet optimaal meer kunnen functioneren. Cookies kun je alleen zelf verwijderen, aangezien ze op je eigen computer worden opgeslagen. Raadpleeg hiervoor de Help-functie en/of instellingen van je browser.
                    </Grid>
                </Grid>

            </Container>
        </div>
    );
};

export default CookiesPage;
