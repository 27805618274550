import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import "./registration_confirm.scss";
import { BASE_URL, registration_confirm } from "../../../redux/constants/ApiEndPoints";
import SuccessModal from "../../../components/success_modal/success_modal";

export default function RegistrationConfirm() {
    const [ successModalState, setSuccessModalState ] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = location.search;
        const parsedQuery = new URLSearchParams(query);
        const email = parsedQuery.get("email");
        
        axios.get(BASE_URL + registration_confirm + email).then(response => {
            setSuccessModalState(true)
        }).catch(error => console.log(error))
    }, [])

    const onConfirmation = () => {
        navigate("/info")
    }

    return(
        <>
            <SuccessModal modalState = {successModalState} message = "E-mail succesvol bevestigd" onTimeout = {onConfirmation} timeoutDuration = {4000} />
        </>
    )
}