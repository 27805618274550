import { Routes, Route, BrowserRouter } from "react-router-dom";
import RoutesWithHeader from "./router/routesWithHeader";
import RoutesWithoutHeader from "./router/routesWithoutHeader";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import "./App.css";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536
    }
  }
})

function App() {
  return (
    <ThemeProvider theme = {theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/success-message" element={<RoutesWithoutHeader />} />
          <Route path="/*" element={<RoutesWithHeader />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
