import React from "react";
import { useNavigate } from "react-router-dom";
import "./central_action.scss";

const CentralAction = ({ image, title, btnText, color, boxType, url, styles, size }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`left_action flex relative font-[dmserif] ${size === "large" ? "flex-[2.5]" : size === "medium" ? "flex-[2]" : "flex-[1.5]" } ${
        boxType === 0 && `bg-[${color}]`
      }`}
      style={styles}
    >
      <div
        className={`absolute w-full h-full text-center text-white left_action`}
      >
        <div className="px-[15px] relative text-center h-full top-[50%] flex flex-col justify-between left_action_padding_div box-border py-[50px]">
          <div className="text-[40px] font-extrabold">{title || <div className="pt-[15px]">&nbsp;&nbsp;</div>}</div>
          <div className="left_action_button_div">
            {url && btnText && (
              <button
                className="text-[14px] box-border px-[45px] left_action_button font-[Montserrat] bg-transparent hover:bg-[#C98468] text-white-100  h-[41px] border border-white hover:border-transparent rounded-full"
                onClick={() => navigate(`${url}`)}
              >
                {btnText}
              </button>
            )}
          </div>
        </div>
      </div>
      {boxType === 1 && (
        <img className="w-full h-full central_action" alt="right" src={image} />
      )}
    </div>
  );
};

export default CentralAction;
