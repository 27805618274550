import React from 'react';
import RegisterPage from './register';

const Register = () => {
    return (
        
        <RegisterPage />
    )
}

export default Register;