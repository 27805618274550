import React, { useState, useEffect } from "react";
import DropdownMenu from "../dropdown/dropdown";

export default function AlphabetSearch({ allBrands }) {
  const [searchInput, setSearchInput] = useState("");
  const [alphabet, setAlphabet] = useState(null);
  const [active, setActive] = useState(false);
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    setItemList([...allBrands]);
  }, [allBrands]);

  const handleClick = (event) => {
    setAlphabet(event.target.value);
    setActive(true);
  };

  const handleOver = (event) => {
    setAlphabet(event.target.value);
    setActive(false);
  };

  const handleRemove = () => {
    setAlphabet(null);
    setActive(false);
  };

  const filterItems = (itemList) => {
    let result = [];
    if (itemList && (searchInput || alphabet)) {
      result = itemList?.filter(
        (element) =>
          element?.second_name?.charAt(0).toLowerCase() === alphabet.toLowerCase()
      );
    } else {
      result = itemList || [];
    }
    // result = result?.map(
    //   (item) => item.second_name && <li>{item.second_name}</li>
    // );
    return result;
  };

  const filteredList = filterItems(itemList);

  const prepareAlphabets = () => {
    let result = [];
    for (let i = 65; i < 91; i++) {
      result.push(
        <div key={`b-alphabet-${i}`}>
          <button
            type="button"
            className={
              String.fromCharCode(i) === alphabet && active
                ? "brands-alphabet-selected font-[Montserrat]"
                : String.fromCharCode(i) === alphabet && !active
                ? "brands-alphabet-search font-[Montserrat]"
                : "brands-alphabet-search font-[Montserrat]"
            }
            key={i}
            onClick={handleClick}
            onMouseOver={handleOver}
            value={String.fromCharCode(i)}
          >
            {String.fromCharCode(i)}
          </button>
        </div>
      );
    }
    return result;
  };

  return (
    <div onMouseLeave={handleRemove} className="justify-center">
      <div className="brands-alphabet-container pt-6">{prepareAlphabets()}</div>
      {
        <DropdownMenu
          options={filteredList}
          alphabet={alphabet}
          filteredList={filteredList}
        />
      }
    </div>
  );
}
