import React, { useEffect } from "react";
import PropTypes from "prop-types";

import "./success_modal.scss";

export default function SuccessModal(props) {
    const { modalState, message, onTimeout, timeoutDuration } = props;

    useEffect(() => {
        // when modal is triggered to true, that means the api has hit and updated modalState
        if(modalState) {
            handleTimeout();
        }
    }, [modalState])

    const handleTimeout = () => {
        setTimeout(() => {
            onTimeout();
        }, timeoutDuration)
    }

    return(
        <div className = "fixed w-screen h-screen top-0 left-0 z-[1000] flex justify-center items-center fade-in-bck" style = {{display: modalState ? 'flex' : 'none'}}>
            <div className = "absolute left-0 top-0 w-full h-full bg-[#fff]/[0.6]" />
            <div className = "w-[924px] max-w-[90%] h-[272px] rounded-[5px] bg-[#C78267] flex justify-center items-center relative">
                <p className = "font-[SerifDisplay] text-white text-[58px] text-center">{message}</p>
            </div>
        </div>
    )
}

SuccessModal.propTypes = {
    modalState: PropTypes.bool,
    message: PropTypes.string,
    onTimeout: PropTypes.func,
    timeoutDuration: PropTypes.number
}

SuccessModal.defaultProps = {
    modalState: false,
    message: "",
    onTimeout: () => {},
    timeoutDuration: 2000
}