import React, { useState } from "react";
import { Grid, Container } from "@mui/material";

import "./slider.scss";

const Slider = ({ children }) => {
  const [stateIndex, setIndex] = useState(0);

  const dotClicked = (index, isImgClicked, from) => {
    const setIndexUpdate = (index, iClicked) => {
      if (iClicked) {
        if (from === "left") {
          return index === 0 ? children.length - 1 : index - 1;
        } else return index === children.length - 1 ? 0 : index + 1;
      }
      return index;
    };

    setIndex(setIndexUpdate(index, isImgClicked));
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Container maxWidth="lg">
        <div className="mt-[35px] md:h-[500px] h-[300px] relative overflow-hidden">
          <div
            className="slider_innerContainer flex relative h-full cursor-pointer relative"
            style={{ left: -100 * stateIndex + "%" }}
          >
            {children.map((child, index) => (
              <div
                className="w-full h-full shrink-0 relative"
                style={{
                  color: index === stateIndex ? "#c98468" : "lightgray",
                }}
                key={index}
                data-index={index}
              >
                <div
                  className="product__slider__left"
                  onClick={() => dotClicked(index, true, "left")}
                />
                <div
                  className="product__slider__right"
                  onClick={() => dotClicked(index, true, "right")}
                />
                {child}
              </div>
            ))}
          </div>
          <div className="absolute text-center w-full bottom-[15px] flex justify-center">
            {children.map((child, index) => (
              <div
                className="cursor-pointer rounded-full w-[7px] h-[7px] mr-[5px]"
                style={{
                  background: index === stateIndex ? "#c98468" : "lightgray",
                }}
                key={index}
                data-index={index}
                onClick={() => dotClicked(index, false)}
              />
            ))}
          </div>
        </div>
      </Container>
    </Grid>
  );
};

export default Slider;
