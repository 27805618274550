import axios from "../axios/axios";
import { get_page_details } from "../constants/ApiEndPoints";
import { GET_PROJECT_PAGE_DETAILS } from "../constants/constants";

export const getProjectPageDetails = () => {
  return async (dispatch) => {
    const { data } = await axios.get(get_page_details);
    dispatch({ type: GET_PROJECT_PAGE_DETAILS, payload: data.data });
    return data;
  };
};
