import axios from "../axios/axios";
import { get_boxes } from "../constants/ApiEndPoints";
import { GET_ALL_BOX } from "../constants/constants";

export const getBoxes = () => {
  return async (dispatch) => {
    const { data } = await axios.get(get_boxes);
    dispatch({ type: GET_ALL_BOX, payload: data.data });
    return data;
  };
};
