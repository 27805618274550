import React from "react";
import { Grid } from "@mui/material";

import "./../categories.scss";
import "react-responsive-modal/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Image4 = ({ images, projectImages, onOpenModal }) => {
  return (
    <>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt-10 pb-[10px] pr-[10px]">
            <div style={{ height: 532 }}>
                <img
                    src={projectImages && projectImages[0]?.image}
                    alt="img"
                    className="categories-images"
                    onClick={() => onOpenModal(0)}
                />
            </div>
        </Grid>
        <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="mt-10 pb-[10px] pr-[10px]">
                <div style={{ height: 532 }}>
                    <img
                        src={projectImages && projectImages[1]?.image}
                        alt="img"
                        className="categories-images"
                        onClick={() => onOpenModal(1)}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className="mt-10 pb-[10px]">

                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt-10 pb-[10px] pr-[10px]">
                        <div style={{ height: 260 }}>
                            <img
                                src={projectImages && projectImages[2]?.image}
                                alt="img"
                                className="categories-images"
                                onClick={() => onOpenModal(2)}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt-10 pb-[10px] pr-[10px]">
                        <div style={{ height: 262 }}>
                            <img
                                src={projectImages && projectImages[3]?.image}
                                alt="img"
                                className="categories-images"
                                onClick={() => onOpenModal(3)}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
  );
};

export default Image4;
