import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import "./dropdown.scss";

export default function Dropdown(props) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);

  const { options, filteredList, alphabet } = props;

  const renderOptions = () => {
    if (!options) {
      return;
    }

    return options.map((option, i) => {
      return (
        option && (
          <li
            key={i}
            onClick={() => navigate(`/inventory-page?brand=` + option?.id + `&brandname=` + option?.name)}
            className={
              "dropdown__list-item font-[Montserrat]" +
              (i === selected ? "dropdown__list-item--active" : "")
            }
          >
            {option?.second_name}
          </li>
        )
      );
    });
  };

  return (
    <div
      className={
        alphabet && filteredList.length !== 0
          ? "dropdown dropdown__light"
          : ""
      }
    >
      {alphabet && filteredList && (
        <ul className={"dropdown__list dropdown__list--active"}>
          {renderOptions()}
        </ul>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  alphabet: PropTypes.string,
  filteredList: PropTypes.array,
  options: PropTypes.array
}

Dropdown.defaultProps = {
  alphabet: "",
  filteredList: [],
  options: []
}