import { GET_ALL_COLLECTION } from "../constants/constants";
const INIT_STATE = {
  all_collections: [],
};
const collection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_COLLECTION:
      return {
        ...state,
        all_collections: action.payload,
      };

    default:
      return state;
  }
};

export default collection;
