import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { getFullURL } from "../../../helpers/getfullURL";
import TPLogo from "../../../images/brand_logos/top_interieur.svg";

const ImagePart = ({ product, selectedImage, setSelectedImage, pageId }) => {
  const [childImages, setChildImages] = useState([]);
  useEffect(() => {
    let imgs = product.product_images;
    if (Array.isArray(imgs) && imgs.length > 0) {
      let res = imgs.filter((img) => img.url !== product.main_image);
      if (res) setChildImages(res);
    }
  }, [product]);

  const renderSmallImage = () => {
    if(pageId === "product") {
      return (product?.main_image && product?.main_image !== "");
    }
    else {
      return (product?.picture && product?.picture !== "");
    }
  }

  return (
    <Container className="mt-5">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="py-10" style={{ height: 400 }}>
            <img src={(selectedImage && selectedImage !== "") ? getFullURL(selectedImage) : TPLogo} alt="main" 
            style={{ maxHeight: '350px' }}/>
          </div>
        </Grid>

        {renderSmallImage() && (
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <div
              className="py-10 product" style={{ height: '100%' }}
              onClick={() => setSelectedImage(pageId === "product" ? product.main_image : product.picture)}
            >
              <div className={`product-short-image ${selectedImage === (pageId === "product" ? product?.main_image : product?.picture) &&
                "product__active__image"
                }`}
                style={{ maxHeight: 200, minHeight: 100, height: '100%' }} >
                <img className="m-auto"
                  style={{ maxHeight: 190 }}
                  src={getFullURL(pageId === "product" ? product?.main_image : product?.picture)}
                  alt="product"
                />
              </div>
            </div>
          </Grid>
        )}

        {childImages?.map((obj) => (
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <div className="py-10" onClick={() => setSelectedImage(obj.url)} style={{ height: '100%' }}>
              <div className={`product-short-image ${selectedImage === obj.url && "product__active__image"
                }`}
                style={{ maxHeight: 200, minHeight: 100, height: '100%' }} >
                <img className="m-auto"
                  style={{ maxHeight: 190 }}
                  src={getFullURL(obj?.url)}
                  alt="product"
                />
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ImagePart;
