import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  createRef,
} from "react";
import { Grid, Container } from "@mui/material";
import {
  BASE_URL,
  updateClient,
  getSingleClient,
} from "../../../redux/constants/ApiEndPoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import RadioGroup from "../../../components/inputs/radio_group";
import InputText from "../../../components/inputs/text";
import CheckBox from "../../../components/inputs/checkbox";
import SubmitButton from "../../../components/submit_section";

import "./account_page.scss";
import ForgetPassword from "../../authentication/login/forgotPassword";
import { REGEX } from "../../../constants/constants";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { cloneDeep, trim } from "lodash";

const Heading = (props) => {
  const { label, mb, mainHeadings } = props;
  const headingClass = mainHeadings
    ? `ms:text-[40px] text-[35px] font-dmserif mb-[${mb}] text-[#404040]`
    : `ms:text-[27px] text-[20px] font-semibold font-montserrat mb-[${mb}] text-[#C98468]`;
  return <div className={headingClass}>{label}</div>;
};

// top most part of the registration form, includes radio button and login form
const EmailPassword = forwardRef((props, ref) => {
  const { rbState, setRBState, data } = props;
  // const emailRef = useRef(0);
  // const confirmEmailRef = useRef(0);
  // const passwordRef = useRef(0);
  // const confirmPasswordRef = useRef(0);

  useEffect(() => {
    if (data) {
      const { email, business } = data;
      // emailRef.current.setValue(email)
      // confirmEmailRef.current.setValue(email)
      setRBState(business ? "ZAKELIJK" : "PARTICULIER");
    }
  }, [data]);

  useImperativeHandle(ref, () => ({
    getValues: () => {
      // const email = emailRef.current.getValue();
      // const confirmEmail = confirmEmailRef.current.getValue();
      // const password = passwordRef.current.getValue();
      // const confirmPassword = confirmPasswordRef.current.getValue();
      // return { email, confirmEmail, password, confirmPassword };
    },
  }));

  return (
    <div className=" mb-[50px]">
      {/* <RadioGroup
        rbState={rbState}
        setRBState={setRBState}
        labels={["PARTICULIER", "ZAKELIJK"]}
        styleProps="text-[#27292C] ml-[200px] font-semibold text-[14px]"
        spanStyleProps="pr_radio_group ml-[-20px]"
        spaceBetween="|||"
      /> */}
      {/* <div className='grid_account_page grid_2_columns ms:mt-[33px] mt-[70px]'>
                <InputText 
                    label='EMAIL'
                    name='email'
                    placeHolderText='Email'
                    ref = {emailRef}
                />
                <InputText 
                    label='BEVESTIG EMAIL'
                    name='confirm_email'
                    placeHolderText='Bevestig Email'
                    ref = {confirmEmailRef}
                />
            </div>
            <div className='grid_account_page grid_2_columns mt-[25px] ms:mt-[0px]'>
                <InputText 
                    label='WACHTWOORD'
                    type='password'
                    name='password'
                    placeHolderText='Wachtwoord'
                    ref = {passwordRef}
                />
                <InputText 
                    label='BEVESTIG WACHTWOORD'
                    type='password'
                    name='confirm_password'
                    placeHolderText='Bevestig wachtwoord'
                    ref = {confirmPasswordRef}
                />
            </div>                  */}
    </div>
  );
});

// second part of the registration form, address part with optional business related fields
const Address = forwardRef((props, ref) => {
  const { isBusiness, mb, formHandler } = props;

  useImperativeHandle(ref, () => ({
    
  }));

  // const parseSQLDateTime = (value) => {
  //   const datetime = value.split("T");
  //   const dateString = datetime[0];
  //   const timeString = datetime[1];
  //   const dateArray = dateString.split("-");
  //   const timeArray = timeString.split(":");
  //   const date = new Date(
  //     dateArray[0],
  //     parseInt(dateArray[1]) - 1,
  //     dateArray[2],
  //     timeArray[0]
  //   );
  //   return {
  //     date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
  //     time: `${date.getHours()}:${date?.getMinutes()}`,
  //   };
  // };
  const parseSQLDateTime = (value) => {
    const dateArray = value.split("/");
    const date = new Date(`${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`);
    console.log(date);
    return { date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`, time: `00:00:00` }
  }

  return (
    <div className="mb-[60px]">
      <Heading mb="27px" label="Huidig adres" />
      {isBusiness && (
        <div className="grid_account_page grid_2_columns mt-[70px] ms:mt-[24px]">
          <InputText
            label="BEDRIJFSNAAM"
            name="company"
            placeHolderText="Bedrijfsnaam"
            value={formHandler.values.company}
            handleChange={formHandler.handleChange}
            errorState={formHandler.errors.company && formHandler.touched.company}
          />
          <InputText
            label="BTW NR"
            name="company_number"
            placeHolderText="BE"
            value={formHandler.values.company_number}
            handleChange={formHandler.handleChange}
            errorState={formHandler.errors.company_number && formHandler.touched.company_number}
          />
        </div>
      )}
      <div className="grid_account_page grid_2_columns mt-[25px]">
        <InputText
          label="NAAM"
          name="firstName"
          placeHolderText="Naam"
          value={formHandler.values.firstName}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.firstName && formHandler.touched.firstName}
        />
        <InputText
          label="VOORNAAM"
          name="lastName"
          placeHolderText="Naam"
          value={formHandler.values.lastName}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.lastName && formHandler.touched.lastName}
        />
      </div>
      <div className="grid_account_page grid_columns_7_2 mt-[15px]">
        <InputText
          label="ADRES"
          name="address.street_1"
          placeHolderText="Straat"
          value={formHandler.values.address?.street_1}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.address?.street_1 && formHandler.touched.address?.street_1}
        />
        <div className="grid_account_page mini_grid_2_columns">
          <InputText
            label="NR"
            labelColor="#fff"
            name="address.number_1"
            placeHolderText="Nr"
            value={formHandler.values.address?.number_1}
            handleChange={formHandler.handleChange}
            errorState={formHandler.errors.address?.number_1 && formHandler.touched.address?.number_1}
          />
          <InputText
            label="BUS"
            labelColor="#fff"
            name="address.number_2"
            placeHolderText="Bus"
            value={formHandler.values.address?.number_2}
            handleChange={formHandler.handleChange}
            errorState={formHandler.errors.address?.number_2 && formHandler.touched.address?.number_2}
          />
        </div>
      </div>
      <div className={`grid_account_page grid_columns_7_2 mb-[${mb}]`}>
        <InputText
          label="GEMEENTE"
          labelColor="#fff"
          name="address.city"
          placeHolderText="Gemeente"
          value={formHandler.values.address?.city}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.address?.city && formHandler.touched.address?.city}
        />
        <InputText
          label="POSTCODE"
          labelColor="#fff"
          name="address.zip_code"
          inputStyleProps="border border-[#000000] ms:w-[48%] mt-[10px] py-2 px-3"
          placeHolderText="Postcode"
          value={formHandler.values.address?.zip_code}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.address?.zip_code && formHandler.touched.address?.zip_code}
        />
      </div>
      <div className="grid_account_page grid_2_columns mt-[25px]">
        <InputText
          label="EMAIL"
          name="address.email"
          placeHolderText="Example@email.com"
          value={formHandler.values.address?.email}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.address?.email && formHandler.touched.address?.email}
        />
        <div>
          <InputText
            label="TELEFOON NUMMER"
            name="phone"
            placeHolderText="+32"
            value={formHandler.values.phone}
            handleChange={formHandler.handleChange}
            errorState={formHandler.errors.phone && formHandler.touched.phone}
          />
          {formHandler.errors.phone && formHandler.touched.phone && <p className='text-left text-[red]'>{formHandler.errors.phone}</p>}
        </div>
      </div>
      <div className="grid_account_page grid_2_columns mt-[25px]">
        <InputText
          label="GEBOORTE DATUM"
          name="dob"
          placeHolderText="DD/MM/JJJJ"
          value={formHandler.values.dob}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.dob && formHandler.touched.dob}
        />
        <div>
          <InputText
            label="GSM NUMMER"
            name="mobile"
            placeHolderText="+32 4"
            value={formHandler.values.mobile}
            handleChange={formHandler.handleChange}
            errorState={formHandler.errors.mobile && formHandler.touched.mobile}
          />
          {formHandler.errors.mobile && formHandler.touched.mobile && <p className='text-left text-[red]'>{formHandler.errors.mobile}</p>}
        </div>
        {formHandler.errors.dob && formHandler.touched.dob && <p className = "font-[Montserrat] font-medium text-[16px] text-[red]">ongeldige datum DD/MM/JJJJ</p>}
      </div>
    </div>
  );
});

// third part of the registration form, basic contact info for secondary person
const SecondPersonContact = forwardRef((props, ref) => {

  const {formHandler } = props;

  useImperativeHandle(ref, () => ({
    
  }));
  return (
    <div className="mb-[45px]">
      <Heading mb="27px" label="2de contact persoon" mainHeadings={false} />
      <div className="grid_account_page grid_2_columns mt-[25px]">
        <InputText
          label="NAAM"
          name="second_contact.firstName"
          placeHolderText="Naam"
          value={formHandler.values.second_contact?.firstName}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.second_contact?.firstName && formHandler.touched.second_contact?.firstName}
        />
        <InputText
          label="VOORNAAM"
          name="second_contact.lastName"
          placeHolderText="Naam"
          value={formHandler.values.second_contact?.lastName}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.second_contact?.lastName && formHandler.touched.second_contact?.lastName}
        />
      </div>

      <div className="grid_account_page grid_2_columns mt-[25px]">
        <InputText
          label="EMAIL"
          name="second_contact.email"
          placeHolderText="Example@email.com"
          value={formHandler.values.second_contact?.email}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.second_contact?.email && formHandler.touched.second_contact?.email}
        />
        <InputText
          label="TELEFOON NUMMER"
          name="second_contact.phone"
          placeHolderText="+32"
          value={formHandler.values.second_contact?.phone}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.second_contact?.phone && formHandler.touched.second_contact?.phone}
        />
      </div>
    </div>
  );
});

const AddressBilling = forwardRef((props, ref) => {
  const { mb, formHandler } = props;

  useImperativeHandle(ref, () => ({
    
  }));

  return (
    <>
      <div className="grid_account_page grid_columns_7_2 mt-[15px]">
        <InputText
          label="ADRES"
          name="billingForm.street_1"
          placeHolderText="Straat"
          value={formHandler.values.billingForm?.street_1}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.billingForm?.street_1 && formHandler.touched.billingForm?.street_1}
        />
        <div className="grid_account_page mini_grid_2_columns">
          <InputText
            label="NR"
            labelColor="#fff"
            name="billingForm.number_1"
            placeHolderText="Nr"
            value={formHandler.values.billingForm?.number_1}
            handleChange={formHandler.handleChange}
            errorState={formHandler.errors.billingForm?.number_1 && formHandler.touched.billingForm?.number_1}
          />
          <InputText
            label="BUS"
            labelColor="#fff"
            name="billingForm.number_2"
            placeHolderText="Bus"
            value={formHandler.values.billingForm?.number_2}
            handleChange={formHandler.handleChange}
            errorState={formHandler.errors.billingForm?.number_2 && formHandler.touched.billingForm?.number_2}
          />
        </div>
      </div>
      <div className={`grid_account_page grid_columns_7_2 mb-[${mb}]`}>
        <InputText
          label="GEMEENTE"
          labelColor="#fff"
          name="billingForm.city"
          placeHolderText="Gemeente"
          value={formHandler.values.billingForm?.city}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.billingForm?.city && formHandler.touched.billingForm?.city}
        />
        <InputText
          label="POSTCODE"
          labelColor="#fff"
          name="billingForm.zip_code"
          inputStyleProps="border border-[#000000] ms:w-[48%] mt-[10px] py-2 px-3"
          placeHolderText="Postcode"
          value={formHandler.values.billingForm?.zip_code}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.billingForm?.zip_code && formHandler.touched.billingForm?.zip_code}
        />
      </div>
    </>
  );
});

const AddressLiving = forwardRef((props, ref) => {
  const { mb, formHandler } = props;

  useImperativeHandle(ref, () => ({
    
  }));
  return (
    <>
      <div className="grid_account_page grid_columns_7_2 mt-[15px]">
        <InputText
          label="ADRES"
          name="livingForm.street_1"
          placeHolderText="Straat"
          value={formHandler.values.livingForm?.street_1}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.livingForm?.street_1 && formHandler.touched.livingForm?.street_1}
        />
        <div className="grid_account_page mini_grid_2_columns">
          <InputText
            label="NR"
            labelColor="#fff"
            name="livingForm.number_1"
            placeHolderText="Nr"
            value={formHandler.values.livingForm?.number_1}
            handleChange={formHandler.handleChange}
            errorState={formHandler.errors.livingForm?.number_1 && formHandler.touched.livingForm?.number_1}
          />
          <InputText
            label="BUS"
            labelColor="#fff"
            name="livingForm.number_2"
            placeHolderText="Bus"
            value={formHandler.values.livingForm?.number_2}
            handleChange={formHandler.handleChange}
            errorState={formHandler.errors.livingForm?.number_2 && formHandler.touched.livingForm?.number_2}
          />
        </div>
      </div>
      <div className={`grid_account_page grid_columns_7_2 mb-[${mb}]`}>
        <InputText
          label="GEMEENTE"
          labelColor="#fff"
          name="livingForm.city"
          placeHolderText="Gemeente"
          value={formHandler.values.livingForm?.city}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.livingForm?.city && formHandler.touched.livingForm?.city}
        />
        <InputText
          label="POSTCODE"
          labelColor="#fff"
          name="livingForm.zip_code"
          inputStyleProps="border border-[#000000] ms:w-[48%] mt-[10px] py-2 px-3"
          placeHolderText="Postcode"
          value={formHandler.values.livingForm?.zip_code}
          handleChange={formHandler.handleChange}
          errorState={formHandler.errors.livingForm?.zip_code && formHandler.touched.livingForm?.zip_code}
        />
      </div>
    </>
  );
});

const AccountPage = (props) => {
  const [clientData, setClientData] = useState(null);
  const [rbState, setRBState] = useState("PARTICULIER");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState([]);
  const [forgotPasswordModalState, setForgotPasswordModalState] = useState(false)

  const userData = JSON.parse(localStorage.getItem("user-data"));
  const navigate = useNavigate();
  const { setBillingData } = props;

  const handleForgotPasswordModalClose = () => {
    setForgotPasswordModalState(false)
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  useEffect(() => {
    if (userData?.id) {
      axios
        .get(BASE_URL + getSingleClient + userData?.id, {headers})
        .then((response) => {
          const data = response.data.data[0][0];
          setClientData(data);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    if (clientData && clientData.id) {
      let clientDetails = cloneDeep(clientData);
      clientDetails.registerType = clientDetails.business ? "ZAKELIJK" : "PARTICULIER";
      clientDetails.address = (({ street_1, number_1, number_2, zip_code, city, email, billing, delivery }) => ({
        street_1, number_1, number_2, zip_code, city, email, billing, delivery
      }))(clientDetails?.addresses[0] || {});

      clientDetails.billingForm = (({ street_1, number_1, number_2, zip_code, city }) => ({
        street_1, number_1, number_2, zip_code, city
      }))(clientDetails?.addresses[1] || {});

      clientDetails.livingForm = (({ street_1, number_1, number_2, zip_code, city }) => ({
        street_1, number_1, number_2, zip_code, city
      }))(clientDetails?.addresses[2] || {});

      clientDetails.second_contact = (({ email, firstName, lastName, phone }) => ({ email, firstName, lastName, phone }))(clientDetails?.second_contact || {});
      delete clientDetails.addresses;
      delete clientDetails.orders;
      billingInfoForm.setValues(clientDetails);
    }
  }, [clientData]);

  const Heading = ({ label, mb, mainHeadings = true }) => {
    const props = mainHeadings
      ? `ms:text-[40px] text-[35px] font-dmserif mb-[${mb}] text-[#404040]`
      : `ms:text-[27px] text-[20px] font-semibold font-montserrat mb-[${mb}] text-[#C98468]`;
    return <div className={props}>{label}</div>;
  };

  const billingInfoForm = useFormik({
    initialValues: {
      registerType: "PARTICULIER",
      // address form section fields
      firstName: "",
      lastName: "",
      company: "",
      company_number: "",
      phone: "",
      dob: "",
      mobile: "",
      address: {
        street_1: "",
        number_1: "",
        number_2: "",
        zip_code: "",
        city: "",
        email: "",
        billing: true,
        delivery: true
      },
      // SecondPersonContact form section fields
      second_contact: {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      },
      // AddressBilling form section fields
      billingForm: {
        street_1: "",
        number_1: "",
        number_2: "",
        city: "",
        zip_code: "",
      },
      // AddressLiving form section fields
      livingForm: {
        street_1: "",
        number_1: "",
        number_2: "",
        city: "",
        zip_code: "",
      }
    },
    validationSchema: Yup.object().shape({
      registerType: Yup.string().required(" ").oneOf(["PARTICULIER", "ZAKELIJK"]),
      firstName: Yup.string().required(" "),
      lastName: Yup.string().required(" "),
      company: Yup.string().when('registerType', ([registerType], field) => registerType === 'ZAKELIJK' ? field.required(" ") : field).nullable(true),
      company_number: Yup.string().when('registerType', ([registerType], field) => registerType === 'ZAKELIJK' ? field.required(" ") : field).nullable(true),
      phone: Yup.string().matches(REGEX.PHONE, { message: " " }).when('mobile', ([mobile], field) => !mobile ? field.required("Gelieve telefoon of gsm in te vullen") : field),
      dob: Yup.string().matches(REGEX.DATE, { message: "Ongeldig DOB" }).required("Verplicht"),
      mobile: Yup.string().matches(REGEX.PHONE, { message: " " }).when('phone', ([phone], field) => !phone ? field.required("Gelieve telefoon of gsm in te vullen") : field),
      address: Yup.object().shape({
        street_1: Yup.string().required(" "),
        number_1: Yup.string().required(" "),
        number_2: Yup.string().optional(),
        zip_code: Yup.string().required(" "),
        city: Yup.string().required(" "),
        email: Yup.string().matches(REGEX.EMAIL).required(" "),
        billing: Yup.boolean().required(" "),
        delivery: Yup.boolean().required(" "),
      }),
      // SecondPersonContact form section fields
      second_contact: Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        email: Yup.string().matches(REGEX.EMAIL),
        phone: Yup.string().matches(REGEX.PHONE),
      }),
      // AddressBilling form section fields
      billingForm: Yup.object().when("address", {
        is: (address) => address?.billing === false,
        then: () => Yup.object().shape({
          street_1: Yup.string().required(),
          number_1: Yup.string().required(),
          number_2: Yup.string().optional(),
          city: Yup.string().required(),
          zip_code: Yup.string().required(),
        }),
      }),
      // AddressLiving form section fields
      livingForm: Yup.object().when("address", {
        is: (address) => address?.delivery === false,
        then: () => Yup.object().shape({
          street_1: Yup.string().required(),
          number_1: Yup.string().required(),
          number_2: Yup.string().optional(),
          city: Yup.string().required(),
          zip_code: Yup.string().required(),
        }),
      }),
    }, [['phone', 'mobile']]),
    onSubmit: (values) => {
      setErrorMessage("")
      let payload = {
        business: values.registerType === "ZAKELIJK" ? true : false,
        firstName: values.firstName,
        lastName: values.lastName,
        company: values.company,
        company_number: values.company_number,
        addresses: [values.address],
        phone: values.phone,
        dob: values.dob,
        mobile: values.mobile
      }
      // billing form
      if (!values.address.billing) {
        values.billingForm.billing = true;
        values.billingForm.delivery = false;
      }
      payload.addresses.push(!values.address.billing ? values.billingForm : { ...values.address });

      // living form
      if (!values.address.delivery) {
        values.livingForm.billing = false;
        values.livingForm.delivery = true;
      }
      payload.addresses.push(!values.address.delivery ? values.livingForm : { ...values.address });

      values.second_contact.id = clientData?.second_contact?.id || undefined;
      
      if (Object.values(values.second_contact).find(item => item && trim(item) !== "")) {
        payload.second_contact = values.second_contact;
      }
      axios.put(BASE_URL + updateClient + clientData?.id, payload, { headers })
        .then((response) => {
          setBillingData([...payload.addresses]);
          navigate("/checkout/delivery");
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.data) {
            if (error?.response?.data?.data?.message.includes("ER_DUP_ENTRY")) {
              const errmsg = [];
              errmsg.push('Deze email werd reeds eerder gebruikt door u in het systeem. Gelieven uw wachtwoord');
              errmsg.push('opnieuw in te stellen..');
              setErrorMessage2(errmsg)
            }
          }
        });
    },
  });

  return (
    <div>
      <Grid container justifyContent="center" alignItems="center">
        <Container maxWidth="lg">
          <div className="edit_body mx-[12%] mt-[40px] text-center">
            <form onSubmit={billingInfoForm.handleSubmit}>
              <EmailPassword
                rbState={rbState}
                setRBState={setRBState}
                formHandler={billingInfoForm}
              />
              <Address
                isBusiness={clientData?.business}
                formHandler={billingInfoForm}
              />
              <SecondPersonContact
                formHandler={billingInfoForm}
              />
              <div className="mt-[45px]">
                <div className="mb-[20px]">
                  <Heading mb="10px" label="Facturatieadres" />
                  <CheckBox
                    label="identiek aan het huidig adres"
                    name="checkbox_1"
                    checked={billingInfoForm.values.address.billing}
                    setChecked={(value)=>billingInfoForm.setFieldValue('address.billing', value)}
                    parentClassProp='w-full flex justify-center'
                  />
                </div>
                {!billingInfoForm.values.address.billing && (
                  <AddressBilling
                    mb="50px"
                    formHandler={billingInfoForm}
                  />
                )}
                <div className="mb-[25px]">
                  <Heading mb="10px" label="Leveringsadres" />
                  <CheckBox
                    checked={billingInfoForm.values.address.delivery}
                    setChecked={(value)=>billingInfoForm.setFieldValue('address.delivery', value)}
                    label="identiek aan het huidig adres"
                    name="checkbox_2"
                    parentClassProp='w-full flex justify-center'
                  />
                </div>
                {!billingInfoForm.values.address.delivery && (
                  <AddressLiving
                    mb="50px"
                    formHandler={billingInfoForm}
                  />
                )}
              </div>
              <SubmitButton
                submitText="Volgende"
                link="/checkout/delivery"
                onClick={billingInfoForm.handleSubmit}
                alternative
              />
              {errorMessage && (
                <p className="font-[Montserrat] font-medium text-[16px] text-[red] mt-[15px]"> {{ errorMessage }}</p>
              )} {errorMessage2.length > 0 && (
                <p className="font-[Montserrat] font-medium text-[16px] text-[red] mt-[15px]"> {errorMessage2[0]} <p style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setForgotPasswordModalState(true)}> 'hier' </p> {errorMessage2[1]}</p>
              )}
            </form>
            <ForgetPassword
                forgotPasswordModalState={forgotPasswordModalState}
                setForgotPasswordModalState={setForgotPasswordModalState}
                handleForgotPasswordModalClose={handleForgotPasswordModalClose}
            />
          </div>
        </Container>
      </Grid>
    </div>
  );
};

export default AccountPage;

AccountPage.propTypes = {
  setBillingData: PropTypes.func,
};

AccountPage.defaultProps = {
  setBillingData: () => {},
};

Heading.propTypes = {
  label: PropTypes.string,
  mb: PropTypes.string,
  mainHeadings: PropTypes.bool,
};

Heading.defaultProps = {
  label: "",
  mb: "",
  mainHeadings: true,
};

EmailPassword.propTypes = {
  rbState: PropTypes.any,
  setRBState: PropTypes.func,
};

EmailPassword.defaultProps = {
  rbState: false,
  setRBState: () => {},
};

Address.propTypes = {
  isBusiness: PropTypes.bool,
  mb: PropTypes.string,
};

Address.defaultProps = {
  isBusiness: true,
  mb: "",
};

AddressBilling.propTypes = {
  mb: PropTypes.string,
};

AddressBilling.defaultProps = {
  mb: "",
};

AddressLiving.propTypes = {
  mb: PropTypes.string,
};

AddressLiving.defaultProps = {
  mb: "",
};
