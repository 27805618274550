import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiSearchLine } from "react-icons/ri"
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from "prop-types";
import axios from "axios";
import { BASE_URL, getMenuCategory } from '../../redux/constants/ApiEndPoints';
import Brochures from '../brochures/brochures';
import { getAllBrands } from "./footer.api";

import './footer_top.scss'

const useStyles = makeStyles(theme => ({
    notchedOutline: {
        borderWidth: "0px",
        borderColor: "grey !important"
    }
}));

const CustomTextField = (props) =>{
    const [searchState, setSearchState] = useState("")
    const { navigate, location } = props;

    const handleSearchChange = event => {
        setSearchState(event.target.value)
    }

    const handleSearchKeyUp = event => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            handleSearchSubmit();
        }
    }

    const handleSearchSubmit = () => {
        if(searchState) {
            const currentPage = location.pathname;
            if(currentPage === "/inventory-page") {
                navigate(`inventory-page?search=${searchState}`);
                setSearchState("")
                navigate(0);
            }
            else {
                navigate(`inventory-page?search=${searchState}`);
                setSearchState("")
            }
        }
    }

    return (
        <div className = "w-full flex justify-center">
            <div className = 'w-[448px] h-[40px] relative'>
                <div className = "w-full h-full absolute left-0 top-0 rounded-[20px] search-field" />
                <div className = "w-full h-full relative bg-white rounded-[20px] box-border px-[17px] flex items-center">
                    <input className = "font-[Montserrat] font-regular text-[14px] text-[#404040] h-full w-full outline-none" value = {searchState} onKeyUp={handleSearchKeyUp} onChange = {handleSearchChange} />
                    <div className = "shrink-0 cursor-pointer" onClick = {handleSearchSubmit}>
                        <RiSearchLine size = "1.5em" color = "#C98468" />
                    </div>
                </div>
            </div>
        </div>
    )
}

const CustomSlider = (props) => {
    const [isFetching, setIsFetching] = useState(false);
    const [brands, setBrands] = useState([]);

    const { navigate, location } = props;

    useEffect(() => {
        getAllBrands(setIsFetching, setBrands);
    }, []);

    const handleSearchSubmit = (id, name) => {
        if(id) {
            const currentPage = location.pathname;
            if(currentPage === "/inventory-page") {
                navigate(`inventory-page?brand=${id}&brandname=${name}`);
                navigate(0);
            }
            else {
                navigate(`inventory-page?brand=${id}&brandname=${name}`);
            }
        }
    }

    return (
        <div className="flex items-center w-fit container_slider mx-0 my-0">
            {brands?.map((b, index) => {
                return (
                    <Fragment key={`footer-top-slider-${index}`}>
                        {(b?.image && b?.image !== "null") ? (
                            <div className='max-h-[100px] max-w-[200px] w-auto ml-[50px] box-border cursor-pointer' onClick = {() => handleSearchSubmit(b?.id, b?.name)} key={index}>
                                <img
                                    height={24}
                                    className='slider_img' alt={b?.name}
                                    src={b?.image}
                                />
                            </div>
                        ) : (
                            <div className='shrink-0 max-h-[100px] max-w-[250px] w-auto ml-[50px] text-center box-border cursor-pointer' onClick = {() => handleSearchSubmit(b?.id, b?.name)} key={index}>
                                <span className = "text-black"><b>{b?.secondName || b?.name || ""}</b></span>
                            </div>
                        )}
                    </Fragment>
                );
            })}
        </div>
    )
}

const FooterTop = () => {
    const [ brochureData, setBrochureData ] = useState(null)
    const [ brochureModalId, setBrochureModalId ] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        axios.get(BASE_URL + getMenuCategory + "38").then(response => {
            setBrochureData(response?.data?.data)
        }).catch(error => console.log(error))
    }, [])

    const Button = ({text, onClick}) =>{
        return (
            <button className ="text-[12px] lg:inline min-w-[240px] h-[35px] font-light bg-redOrange-100 hover:bg-redOrange-100 text-white mb-5 lg:mr-3 rounded-full" onClick = {onClick}>
                {text}
            </button>
        )
    }

    const ButtonSection = ({heading, onClick, buttonText}) =>{
        return (
            <div className='font-montessart'>
                <div className='text-[16px] font-bold text-[#404040] mb-[15px]'>{heading}</div>
                <div className='button_footer_top'>
                    <Button text={buttonText || '2020-2021 catalogus'} onClick = {onClick}/>
                </div>
            </div>
        )
    }

    const brochureButtonClick = id => {
        setBrochureModalId(id)
    }

    return (
        <>
            <div className='w-full section_top pt-[80px] pb-[45px] text-redOrange-100 '>
                <Container maxWidth = "lg">
                    <Grid container>
                        <Grid item xs={12} md={6} className='text-center grid-footer-top lg:border-r-2 lg:border-t-2 ml-30 pt-10 pb-10'>
                            <div className='pb-5 text-2xl font-bold' >
                                Naar wat ben je op zoek?
                            </div>
                            <div>
                                <CustomTextField navigate = {navigate} location = {location} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} className='mx-[20px] text-center pt-10 lg:border-t-2'>
                            <div className='pb-5 text-2xl font-bold'>
                                Bekijk onze brochure
                            </div>
                            <div className='grid_1_1_footer'>
                                {brochureData?.children && brochureData?.children?.map((broch, index) => (
                                    <ButtonSection key={index} heading={broch?.name} buttonText = {broch?.pdf_name} onClick = {() => brochureButtonClick(broch)} />
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className='mt-[10px] mb-[10px] w-full overflow-x-hidden overflow-y-hidden'>
                <CustomSlider navigate = {navigate} location = {location} />
            </div>
            {brochureModalId && (
                <Brochures brochureId = {brochureModalId} onClose = {() => setBrochureModalId(null)} />
            )}
        </>
    )
}

export default FooterTop;

CustomTextField.propTypes = {
    navigate: PropTypes.func
}

CustomTextField.defaultProps = {
    navigate: () => {}
}
