import React, { useState, useEffect } from "react";
import { Grid, Container, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { detailsConstant, detailsConstant2 } from "./constants";
import CircleButton from "../../circled_btn/circleButton";
import RoundedButton from "../../rounded_button/rounded_button";
import { getFullURL } from "../../../helpers/getfullURL";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import axios from "axios";
import {
  BASE_URL,
  createWishlist,
  updateWishlist,
  getCart,
  updateCart,
  createCart,
  deleteWishList
} from "../../../redux/constants/ApiEndPoints";

// image imports here
import LogoTop from "../../../images/icons/logo_top.png";
import check from "../../../assets/icons/check.svg";
import close from "../../../assets/icons/close.svg";

const DetailsPart = (props) => {
  const [details, setDetails] = useState([...detailsConstant]);
  const [details2, setDetails2] = useState([...detailsConstant2]);
  const [wishlistObject, setWishlistObject] = useState(null);
  const [userCart, setUserCart] = useState({});

  const { product, pageId } = props;
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user-data"));

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  useEffect(() => {
    if (userData?.id) {
      axios
        .get(BASE_URL + getCart + userData?.id, {headers})
        .then((response) => {
          setUserCart(response.data.data);
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (product) {
      let items = [...details];
      let items2 = [...details2];
      if(pageId === "product") {
        product.product_locations?.forEach((location) => {
          if (location?.location?.name === "TMI") {
            items[1].image = check;
            items2[1].color = "green";
          }
          if (location?.location?.name === "TIM") {
            items[2].image = check;
            items2[2].color = "green";
          }
        });
      }
      else {
        if (product?.outlet?.name === "TMI") {
          items[1].image = check;
          items2[1].color = "green";
        }
        if (product?.outlet?.name === "TIM") {
          items[2].image = check;
          items2[2].color = "green";
        }
      }
      setDetails([...items]);
      setDetails2([...items2]);
    }
    // eslint-disable-next-line
  }, [product]);

  const handleFavoriteClick = () => {
    if (product && userData?.id) {
      if (wishlistObject) {
        axios
          .put(BASE_URL + updateWishlist + wishlistObject?.id, {
            is_favourite: !wishlistObject?.is_favourite,
          }, {headers})
          .then((response) => {
            setWishlistObject(response.data.data);
          })
          .catch((error) => console.log(error));
      } else {
        let doesWishlistExist = false;
        let wishlistItem = {};
        if(product?.wishlist) {
          // if the product contains the wishlist data, then go through this condition (inventory page)
          doesWishlistExist =
          product?.wishlist?.length > 0 &&
          product?.wishlist[0]?.user_id === userData?.id
            ? true
            : false;
            wishlistItem = product?.wishlist[0]
        }
        else {
          // if product does not contain wishlist data then manually search through wishlist object for the product (favorites page)
          // wishlist.forEach(item => {
          //   if(item?.product_id == product?.id && item?.is_favourite) {
          //     doesWishlistExist = true;
          //     wishlistItem = {...item};
          //   }
          // })
        }
        if (doesWishlistExist) {
          // if product exist using either of the two methods above then simply then remove it from the data (it means it has already been favorited)
          axios
            .delete(BASE_URL + deleteWishList + wishlistItem?.id, {headers}).then((response) => {
              setWishlistObject([]);
            }).catch((error) => console.log(error));
        } else {
          // if product does not exist using either of the two moethods above then add it to the list (it means it doesn't exist)
          const payload = pageId === "product" ? {
            user_id: userData?.id,
            product_id: product?.id,
            type: 1
          } : {
            user_id: userData?.id,
            sale_item_id: product?.id,
            type: 2
          }
          axios
            .post(BASE_URL + createWishlist, payload, {headers})
            .then((response) => {
              setWishlistObject(response.data.data);
            })
            .catch((error) => console.log(error));
        }
      }
    }
    else {
      navigate("/info")
    }
  };

  const displayFavoriteIcon = () => {
    if (wishlistObject) {
      return wishlistObject?.is_favourite;
    } else {
      if (
        product?.wishlist?.length > 0 &&
        product?.wishlist[0]?.user_id === userData?.id
      ) {
        return product?.wishlist[0]?.is_favourite;
      } else {
        return false;
      }
    }
  };

  const handleAddToCart = () => {
    if(userData?.id) {
      // first check if cart exists
      if (userCart?.id) {
        // then we check if item already exists in the cart
        const cartItemExists = pageId === "product" ? userCart?.products?.find(
          (cartItem) => (cartItem?.product_id === product?.id) 
        ) : userCart?.products?.find(
          (cartItem) => (cartItem?.sale_item_id === product?.id) 
        );
        if (cartItemExists) {
          // if already exists then just increase the quantity
          let newCartList = JSON.parse(JSON.stringify(userCart));
          const products = [];
          newCartList?.products?.forEach((cartItem) => {
            if(cartItem?.type === 1) {
              products.push({
                id: cartItem?.id,
                product_id: cartItem?.product_id,
                cart_id: cartItem?.cart_id,
                quantity:
                  cartItem?.product_id === cartItemExists?.product_id
                    ? cartItem?.quantity + 1
                    : cartItem?.quantity,
              })
            }
            else {
              products.push({
                id: cartItem?.id,
                sales_item_id: cartItem?.sales_item_id,
                cart_id: cartItem?.cart_id,
                quantity:
                  cartItem?.sales_item_id === cartItemExists?.sales_item_id
                    ? cartItem?.quantity + 1
                    : cartItem?.quantity,
              })
            }
          });
          const payload = {
            id: newCartList?.id,
            user_id: newCartList?.user_id,
            products,
          };
          axios
            .put(BASE_URL + updateCart + newCartList?.id, payload, {headers})
            .then((response) => {
              navigate("/cart");
              navigate(0);
            })
            .catch((error) => console.log(error));
        } else {
          // product doesn't exist so we add it
          let newCartList = JSON.parse(JSON.stringify(userCart));
          const products = [];
          newCartList?.products?.forEach((cartItem) => {
            if(cartItem?.type === 1) {
              products.push({
                id: cartItem?.id,
                product_id: cartItem?.product_id,
                cart_id: cartItem?.cart_id,
                quantity: cartItem?.quantity,
                type: 1
              })
            }
            else {
              products.push({
                id: cartItem?.id,
                sale_item_id: cartItem?.sales_item_id,
                cart_id: cartItem?.cart_id,
                quantity: cartItem?.quantity,
                type: 2
              })
            }
          });
          products.push(pageId === "product" ? {
            product_id: product?.id,
            quantity: 1,
            type: 1
          }: {
            sale_item_id: product?.id,
            quantity: 1,
            type: 2
          });
          const payload = {
            id: newCartList?.id,
            user_id: newCartList?.user_id,
            products,
          };
          axios
            .put(BASE_URL + updateCart + newCartList?.id, payload, {headers})
            .then((response) => {
              navigate("/cart");
              navigate(0);
            })
            .catch((error) => console.log(error));
        }
      } else {
        // cart doesn't exist yet so create it
        axios
          .post(BASE_URL + createCart, {
            user_id: userData?.id,
            total: 0,
            products: [
              {
                product_id: product?.id,
                quantity: 1,
              },
            ],
          }, {headers})
          .then((response) => {
            navigate("/cart");
            navigate(0);
          })
          .catch((error) => console.log(error));
      }
    }
    else {
      navigate("/info")
    }
  };

  const getDiscountPrice = () => {
    if(pageId === "sale" && product?.original_sales_price) {
      return product?.original_sales_price !== product?.new_sales_price ? `€ ${product?.original_sales_price}` : "";
    }
    else if(pageId === "product" && product?.promotion_price) {
      return product?.promotion_price !== product?.price ? `€ ${product?.price}` : "";
    }
    else {
      return "";
    }
  }

  const renderDiscountSpace = () => {
    if(pageId === "sale" && product?.original_sales_price && product?.original_sales_price !== product?.new_sales_price) {
      return true;
    }
    else if(pageId === "product" && product?.promotion_price && product?.promotion_price !== product?.price) {
      return true;
    }
    else {
      return false;
    }
  }

  const getSpecifications = () => {
    if(product?.id) {
      let specificationsArray = [];
      if(product?.delivery_time_weeks) {
        specificationsArray.push({
          id: 1,
          title: "Leveringstermijn Weken",
          value: product?.delivery_time_weeks
        })
      }
      if(product?.product_measurement) {
        specificationsArray.push({
          id: 2,
          title: "Afmeting_in_cm",
          value: `${product?.product_measurement?.width} x (d) ${product?.product_measurement?.depth_length} x (h) ${product?.product_measurement?.height} cm`
        })
        if(product?.product_measurement?.seat_height) {
          specificationsArray?.push({
            id: 3, 
            title: "Zithoogte",
            value: product?.product_measurement?.seat_height
          })
        }
        if(product?.product?.product_measurement?.seat_depth) {
          specificationsArray({
            id: 4,
            title: "Zitdiepte",
            value: product?.product_measurement?.seat_depth
          })
        }
        if(product?.product_measurement?.width_package) {
          specificationsArray({
            id: 5,
            title: "Breedte Verpakking",
            value: product?.product_measurement?.width_package
          })
        }
        if(product?.product_measurement?.height_package) {
          specificationsArray({
            id: 6,
            title: "Hoogte Verpakking",
            value: product?.product_measurement?.height_package
          })
        }
        if(product?.product_measurement?.length_package) {
          specificationsArray({
            id: 7,
            title: "Lengte Verpakking",
            value: product?.product_measurement?.length_package
          })
        }
      }
      if(product?.product_styles && product?.product_styles?.length > 0) {
        product?.product_styles.forEach((style, styleIndex) => {
          if(styleIndex === 0) {
            specificationsArray.push({
              id: 8, 
              title: "Stijl",
              value: style?.style?.name
            })
          }
          else {
            specificationsArray.push({
              id: 8 + styleIndex,
              title: "",
              value: style?.style?.name
            })
          }
        })
      }
      if(product?.product_filters && product?.product_styles?.length > 0) {
        const initialId = product?.product_styles && product?.product_styles?.length > 0 ? (7 + product?.product_styles?.length) : 9;
        product?.product_filters?.forEach((filter, filterIndex) => {
          if(filterIndex === 0) {
            specificationsArray.push({
              id: initialId, 
              title: "Uitvoering",
              value: filter?.material?.name
            })
          }
          else {
            specificationsArray.push({
              id: initialId + filterIndex, 
              title: "",
              value: filter?.material?.name
            })
          }
        })
      }
      return specificationsArray;
    }
    else {
      return [];
    }
  }

  const showroomLocation = (location) => {
    if(pageId === "product") {
      if(product?.product_locations && product?.product_locations?.length > 0) {
        let match = false;
        product?.product_locations?.forEach(productLocation => {
          if(productLocation?.location?.name === location) {
            match = true;
          }
        })
        return match;
      }
      else {
        return false;
      }
    } 
    else {
      if(product?.outlet?.name === location) {
        return true;
      }
      if(product?.outlet?.name === location) {
        return true;
      }
    }
  }

  const getStock = (location) => {
    if(pageId === "product") {
      if(product?.product_locations && product?.product_locations?.length > 0) {
        let stock = 0;
        product?.product_locations?.forEach(productLocation => {
          if(productLocation?.location?.name === location) {
            stock = productLocation?.stock;
          }
        })
        return stock === 0 ? "red" : 10 <= stock > 0 ? "orange" : "green";
      }
      else {
        return "red";
      }
    }
    else {
      return "red";
    }
  }
  return (
    <Container className="mt-5">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="flex items-center justify-between invisible">
            <span className="product-details-title font-dmserif">
              {product?.name || product?.card_id || ""}
            </span>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <span className="product-details-subtitle">Beschrijving</span>
              <div
                className="cursor-pointer"
                onClick={() => handleFavoriteClick()}
              >
                {displayFavoriteIcon() ? (
                  <IoMdHeart size="1.5em" color="#C98468" />
                ) : (
                  <IoMdHeartEmpty size="1.5em" color="#C98468" />
                )}
              </div>
            </div>
            <br />

            {product?.commercial_description ? (
              <div
                className="font-extralight description-text"
                dangerouslySetInnerHTML={{
                  __html: product?.commercial_description,
                }}
              />
            ) : product?.description ? (
              <div>{product?.description}</div>
            ) : (
              <span>{"-"}</span>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          className="product-section"
        >
          {product?.brand?.logo && product?.brand?.logo !== "" ? (
            <img
              src={
                getFullURL(product?.brand?.logo)
                  ? getFullURL(product?.brand?.logo)
                  : LogoTop
              }
              className="product-image"
              alt="brand"
            />
          ) : product?.brand?.name ? (
            <p className = "font-[Montserrat] font-bold text-[30px] text-[#404040]">{product?.brand?.name || ""}</p>
          ) : (
            <img src = {LogoTop} className = "product-image mb-[10px]" alt = "brand" />
          )}
          {pageId === "product" && product?.supplier_id && (
            <p className = "font-[Montserrat] font-bold text-[30px] text-[#404040]">{product?.supplier_id || ""}</p>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          className="product-section2"
        ></Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="pt-5 pb-5">
          <div>
            <span className="product-light-title3 ">Model in showroom</span>
          </div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="pt-5 pb-5">
          <div className="flex justify-center">
            <img src={showroomLocation("TMI") ? check : close} alt="icon" />
            <span className="product-light-title2 ml-2">Izegem</span>
          </div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="pt-5 pb-5">
          <div className="flex justify-center">
            <img src={showroomLocation("TIM") ? check : close} alt="icon" />
            <span className="product-light-title2 ml-2">Massenhoven</span>
          </div>
        </Grid>
        {pageId === "product" && (
          <>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="pt-5 pb-5">
              <div>
                <span className="product-light-title3 ">Model in voorraad</span>
              </div>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="pt-5 pb-5">
              <div className="flex justify-center">
                <CircleButton color={getStock("TMI")} />
                <span className="product-light-title2 ml-2">Izegem</span>
              </div>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="pt-5 pb-5">
              <div className="flex justify-center">
                <CircleButton color={getStock("TIM")} />
                <span className="product-light-title2 ml-2">Massenhoven</span>
              </div>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="product-section3"
        >
          <div className = "flex flex-col items-center">
            {(product?.price || product?.new_sales_price || product?.new_sales_price === 0) && (
              <p className = "font-[Montserrat] font-bold text-[24px] text-[#C98468]">Incl. BTW</p>
            )}
            <div className="flex justify-center">
              {product.price && !product.fixed && (
                <span className="product-light-title4">Vanaf</span>
              )}
              <span className="font-[Montserrat] font-bold text-[30px] text-[#404040] line-through">
                {getDiscountPrice()}
              </span>
              {/* space between the two prices, we don't add this value to either of the prices 
              otherwise it will render empty space and mess up alignment */}
              {renderDiscountSpace() && (
                <span className = "ml-8" />
              )}
              <span className="product-color-title">
                {(product?.price || product?.new_sales_price || product?.new_sales_price === 0) ? `€ ${product?.promotion_price || product?.price || product?.new_sales_price}` : ""}
              </span>
            </div>
          </div>
        </Grid>
        {(product.price && product.fixed) || (product?.new_sales_price || product?.new_sales_price === 0) ? (
          <>
          </>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="text-center sm:p-5 sm:px-40">
              <span className="product-details-subtitle2">
                Maak een afspraak om mijn combinatie te maken
              </span>
            </div>
          </Grid>
        )}
        {(product.price && product.fixed) || (product?.new_sales_price || product?.new_sales_price === 0) ? (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="product__bestel__button product-section4"
          >
            <div className="text-center">
              <RoundedButton title={"Bestel nu"} handler={handleAddToCart} />
            </div>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="text-center">
              <RoundedButton
                title={"Maak een afspraak"}
                handler={() => navigate("/afspraak-form/" + product?.id)}
              />
            </div>
          </Grid>
        )}
        {getSpecifications().length > 0 && pageId === "product" && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="pt-10">
              <span className="product-light-title5 d-block">Specificaties</span>
              <div className="table-container">
                <table className="product-table mt-2">
                  {getSpecifications().map(spec => (
                    <tr>
                      <td className="product-light-title6">{spec?.title}</td>
                      <td>{spec?.value}</td>
                    </tr>
                  ))}
                  
                  {/* <tr>
                    <td className="product-light-title6">Afmetingen (b)</td>
                    <td>305 x (d) 96 x (h) 73 cm</td>
                  </tr>
                  <tr>
                    <td className="product-light-title6">Zithoogte</td>
                    <td>46</td>
                  </tr>
                  <tr>
                    <td className="product-light-title6">Zitdiepte </td>
                    <td>66</td>
                  </tr>
                  <tr>
                    <td className="product-light-title6">Ruimte</td>
                    <td>Alleen geschikt voor binnen</td>
                  </tr> */}
                </table>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default DetailsPart;
