import axios from "../axios/axios";
import { get_products } from "../constants/ApiEndPoints";
import { ALL_PRODUCTS } from "../constants/constants";
import qs from "qs";
import { debounce } from "lodash";

export const getAllProducts = (page, filterQuery) => {
  const user = JSON.parse(localStorage.getItem("user-data"));
  return async (dispatch) => {
    const { data } = await axios.get(`${get_products}`, {
      params: {
        user_id: user?.id,
        page,
        ...filterQuery
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat" })
      }
    });
    dispatch({ type: ALL_PRODUCTS, payload: data });
    return data;
  };
};
