import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Container } from "@mui/material";
import { getBoxes } from "../../redux/actions/box";
import LeftAction from "./children/left_action";
import CentralAction from "./children/central_action";
import RightAction from "./children/right_action";
import "./index.scss";

const ItemsParent = ({ getBoxes, box }) => {
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    getBoxes();
  }, []);

  useEffect(() => {
    setBoxes([...box.all_boxes]);
  }, [box.all_boxes]);

  const getGridColumns = () => {
    let item  = ''
    boxes.forEach((b) => {
      if (b.size === 'medium') {
        item += ' 5fr ';
      }
      if (b.size === 'large') {
        item += ' 7fr ';
      }
      if (b.size === 'small') {
        item += ' 4fr ';
      }
    });

    return item || '5fr 6fr 4fr';
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Container maxWidth="lg">
        <div className="central-action flex h-[322px] mb-[20px] mt-[80px] gap-[15px]" style={{ gridTemplateColumns: getGridColumns() }}>
          {boxes?.map((b, i) => {
            return (
              <CentralAction
                boxType={b.box_type}
                image={b.image}
                title={b.name}
                color={b.color}
                btnText={b.button_text}
                url={b.url}
                key={i}
                size = {b?.size}
              />
            );
          })}
        </div>
      </Container>
    </Grid>
  );
};

const mapStateToProps = ({ box }) => ({ box });
export default connect(mapStateToProps, { getBoxes })(ItemsParent);
