import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Container } from "@mui/material";
import { getBoxes } from "../../redux/actions/box";
import CentralAction from "../../components/home_page_item_two/children/central_action";
import "../../components/home_page_item_two";

const ItemsParent = ({ getBoxes, box }) => {
    const [boxes, setBoxes] = useState([]);

    useEffect(() => {
        getBoxes();
    }, []);

    useEffect(() => {
        setBoxes([...box.all_boxes]);
    }, [box.all_boxes]);

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Container maxWidth="lg">
                <div className="items_parent h-auto mb-[20px] mt-[80px] gap-[15px]">
                    {boxes?.map((b, i) => {
                        return (
                            <CentralAction
                                boxType={b.box_type}
                                image={b.image}
                                title={b.name}
                                color={b.color}
                                btnText={b.button_text}
                                url={b.url}
                                key={i}
                                styles={b.box_type === 0 ? { height: 250 } : {}}
                            />
                        );
                    })}
                </div>
            </Container>
        </Grid>
    );
};

const mapStateToProps = ({ box }) => ({ box });
export default connect(mapStateToProps, { getBoxes })(ItemsParent);
