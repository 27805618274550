import React from 'react';
import Form from '../../components/afspraak_form_product';
import { useParams } from 'react-router-dom';

import './index.scss'

const AfspraakFormProduct = () => {
    const { id } = useParams();
    const userData = JSON.parse(localStorage.getItem('user-data'))

    return (<div className=''><Form productId = {id} userData = {userData} /></div>)
}

export default AfspraakFormProduct;