import axios from "../axios/axios";
import { category } from "../constants/ApiEndPoints";
import { GET_ALL_CATEGORIES } from "../constants/constants";

export const getCategories = () => {
  return async (dispatch) => {
    const { data } = await axios.get(category);
    dispatch({ type: GET_ALL_CATEGORIES, payload: data.data });
    return data;
  };
};
