import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { Grid, Container } from "@mui/material";
import BreadcrumbsItem from "../../components/breadcrumb/breadcrumb";
import {getAllCategories, getCategoryById} from "./categories.api";
import { Link } from "react-router-dom";

import "./categories.scss";
import StickyButton from "../../components/maakeenafspraak_sticky_button/sticky_button";

const Categories = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [mouseHoverState, setMouseHoverState] = useState(0);

  let { categoryId } = useParams();
  const navigate = useNavigate();
  const options = {
    icons: {},
    items: [
      { to: "/", label: "Home" },
      { to: "/", label: categoryData?.name || '', active: true },
    ],
  };

  useEffect(() => {
    getAllCategories(setIsFetching, setCategories);
    getCategoryById(setIsFetching, setCategoryData, categoryId);
  }, []);

  useEffect(() => {
    if (categoryId !== categoryData?.id) {
      getCategoryById(setIsFetching, setCategoryData, categoryId);
    }
  }, [categoryId]);

  const handleRouteAndClose = (route) => {
    navigate(route, {state: {roomName: categoryData?.name }});
  };

  return (
      <>
        <StickyButton />
        <Grid container>
          <Grid container className={""}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                  className="category-image-container"
                  style={{
                    backgroundImage: `url(${categoryData?.pageImage})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center' }}
              >
                <span className="category-image-text font-dmserif">
                  <div className="category-background">
                    <div className="category-margin" title={categoryData?.name}>{categoryData?.name}</div>
                  </div>
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid container className={""}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='py-[10px] text-[16px] font-montserrat bg-[#fafafa] text-[#27292C] afspraak_form_parent'>
                  <Container>
                    <ol style={{overflowX:'auto', zIndex:99}}>
                      <div className="pb-[20px] pt-[20px] some-custom-classname">
                        <Link to={"/"}>Home</Link>
                        <p className = "mx-[10px]">/</p>
                        <span className="active-breadcrumb-item">{categoryData?.name}</span>
                      </div>
                    </ol>
                  </Container>
              </div>
            </Grid>
          </Grid>
          <Container>
            <Grid container className={""}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="text-center mt-5">
                  <span className="fs-35 font-dmserif">{categoryData?.name}</span>
                </div>
                <div className="w-full collectie-container flex-wrap mt-16">
                  {categories?.length &&
                      categories?.map((category, index) => {
                        return (
                            <div
                                onClick={() => handleRouteAndClose(`/inventory-page?search=${category?.name}`)}
                                className="flex flex-col items-center mb-[49px] cursor-pointer"
                                onMouseOver={() => setMouseHoverState(category?.id)}
                                onMouseLeave={() => setMouseHoverState(0)}
                                key={`caegory-${index}`}
                            >
                              <div className={`h-[220px] w-[275px] bg-[${category?.color}]/[0.54] flex justify-center items-center relative`}>
                                <img
                                    className="h-[216px] w-[236px] object-contain translate-y-[-35px] animated"
                                    alt="badkamer"
                                    src={category?.image}
                                    style={{
                                      transform:
                                          mouseHoverState === category?.id
                                              ? "scale(1.1) translateY(-35px)"
                                              : "scale(1) translateY(-35px)",
                                    }}
                                />
                                <p className="font-montserrat font-semibold text-[16px] mt-[20px] tracking-[0.9px] category-card-text">
                                  {category?.name}
                                </p>
                              </div>
                            </div>
                        );
                      })}
                </div>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <div className="text-center mt-5">
                  <div className="linkDiv">
                    <p
                        className="fs-16 font-montserrat text-[#27292C] text-center mt-5"
                        dangerouslySetInnerHTML={{
                          __html: categoryData?.description
                        }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </>
  );
};

export default Categories;
