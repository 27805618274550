import React, { useState } from "react";
import { Grid, Container } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

export function MenuGenericWide(props) {
    const navigate = useNavigate();
    const [ activeSubMenu, setActiveSubMenu ] = useState(0)

    const { onMouseOver, onMouseLeave } = props;

    const handleMenuItemClick = (route) => {
        setActiveSubMenu(0);
        onMouseLeave();
        navigate(route);
    };

    return(
        <div className = "w-screen flex justify-center">
            <Grid container justifyContent= "center" alignItems = "center">
                <Container maxWidth = "lg">
                    <div className = "w-full p-[40px] header-menu-extended bg-white max-h-[700px] overflow-x-auto overflow-y-hidden menu-scrollbar" onMouseOver = {onMouseOver} onMouseLeave = {onMouseLeave}>
                        <div className = "flex flex-nowrap">
                            {props.children}
                        </div>
                    </div>
                </Container>
            </Grid>
        </div>
    )
}

export default function MenuCatalogue(props) {
    const navigate = useNavigate();
    const [ activeSubMenu, setActiveSubMenu ] = useState(0)

    const { onMouseOver, onMouseLeave } = props;

    const handleMenuItemClick = (route) => {
        setActiveSubMenu(0);
        onMouseLeave();
        navigate(route);
    };

    return(
        <div className = "w-screen flex justify-center">
            <Grid container justifyContent= "center" alignItems = "center">
                <Container maxWidth = "lg">
                    <div className = "h-[830px] w-full p-[40px] pr-0 header-menu-extended bg-white" onMouseOver = {onMouseOver} onMouseLeave = {onMouseLeave}>
                        <Grid container item md = {12} className = "h-full ">
                            <div className = "flex flex-[4] h-fit flex-col">
                                <Grid container className = "pb-[15px] relative">
                                    <div className = "header-menu-extended-border" />
                                    <Grid container item md direction = "column">
                                        <p onClick={() => handleMenuItemClick('/inventory-page')} className = "font-montserrat font-bold text-[18px] header-menu-subtext cursor-pointer">KASTEN</p>
                                        <div className = "flex flex-col mt-[33px]">
                                            <p onClick={() => handleMenuItemClick('/inventory-page')} className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Boekenkasten</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Buffetkasten</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Dressoirs</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Opbergkasten</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Schoenkasten</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Meubelen</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Vitrinekasten</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Wandcombinaties</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Wandkasten</p>
                                        </div>
                                    </Grid>
                                    <Grid container item md direction = "column" >
                                        <p className = "font-montserrat font-bold text-[18px] header-menu-subtext">OPBERGEN</p>
                                        <div className = "flex flex-col mt-[33px]">
                                            <p onClick={() => handleMenuItemClick('/inventory-page')} className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Boekenkasten</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Draaideurkasten</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Dressings & Maatwerk</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Kastaccesories</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Opbergkasten</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Opbergsystemen</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Schoenkasten</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Schuifdeurkasten</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Wandcombinaties</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Wandplanken</p>
                                        </div>
                                    </Grid>
                                    <Grid container item md direction = "column">
                                        <p className = "font-montserrat font-bold text-[18px] header-menu-subtext">STOELEN</p>
                                        <div className = "flex flex-col mt-[33px]">
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Bureaustoelen</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Eetkamerstoelen</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Fauteuils</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Klapstoelen</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Poefs & Hockers</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Relaxzetels</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Schommelstoelen</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Voetenbanken</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Zitzakken</p>
                                        </div>
                                    </Grid>
                                    <Grid container item md direction = "column">
                                        <p className = "font-montserrat font-bold text-[18px] header-menu-subtext">ZETELS & SALONS</p>
                                        <div className = "flex flex-col mt-[33px]">
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">2-Zits Salons</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">3-Zits Salons</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">4-Zits Salons</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">5-Zits Salons</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Daybedden</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Fauteuils</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Hoeksalons</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Poefs & Hockers</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Relaxzetels</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Slaapbanken</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Voetenbanken</p>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container className = "mt-[15px]">
                                    <Grid container item md direction = "column">
                                        <p className = "font-montserrat font-bold text-[18px] header-menu-subtext">KRUKKEN</p>
                                        <div className = "flex flex-col mt-[33px]">
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Barkrukken</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Krukken</p>
                                        </div>
                                    </Grid>
                                    <Grid container item md direction = "column">
                                        <p className = "font-montserrat font-bold text-[18px] header-menu-subtext">MAATWERK</p>
                                        <div className = "flex flex-col mt-[33px]">
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Bedbrug op Maat</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Berging op Maat</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Bureaukasten op Maat</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Dressing op Maat</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Kleerkast op Maat</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Woonkamermeubel op Maat</p>
                                        </div>
                                    </Grid>
                                    <Grid container item md direction = "column">
                                        <p className = "font-montserrat font-bold text-[18px] header-menu-subtext">SETS</p>
                                        <div className = "flex flex-col mt-[33px]">
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Eetkamersets</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Kinderkamersets</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Slaapkamersets</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Tuinmeubelsets</p>
                                        </div>
                                    </Grid>
                                    <Grid container item md direction = "column">
                                        <p className = "font-montserrat font-bold text-[18px] header-menu-subtext">TAFELS</p>
                                        <div className = "flex flex-col mt-[33px]">
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Bartafels</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Bijzettafels</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Bureau’s</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Eettafels</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Salontafels</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Trolleys</p>
                                            <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Wandtafels</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className = "flex flex-1 h-full">
                                <Grid container item md direction = "column" wrap = "nowrap">
                                    <p className = "font-montserrat font-bold text-[18px] header-menu-subtext">SLAPEN</p>
                                    <div className = "flex flex-col mt-[33px]">
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Bedbanken</p>
                                        <div className = "flex flex-col">
                                            <button type = "button" className = "flex relative" onClick = {() => setActiveSubMenu(1)}>
                                                <p onClick={() => handleMenuItemClick('/inventory-page')} className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer animated" style = {{opacity: activeSubMenu === 1 ? 0 : 1}}>{"Bedden >"}</p>
                                                <p onClick={() => handleMenuItemClick('/inventory-page')} className="font-montserrat font-bold text-[16px] text-black leading-8 cursor-pointer animated absolute" style = {{opacity: activeSubMenu === 1 ? 1 : 0}}>{"Bedden >"}</p>
                                            </button>
                                            <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: activeSubMenu === 1 ? '300px' : '0px', opacity: activeSubMenu === 1 ? 1 : 0}}>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Gestoffeerd Bed</p>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Houten Bed</p>
                                            </div>
                                        </div>
                                        <div className = "flex flex-col">
                                            <button type = "button" className = "flex relative" onClick = {() => setActiveSubMenu(2)}>
                                                <p onClick={() => handleMenuItemClick('/inventory-page')} className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer animated" style = {{opacity: activeSubMenu === 2 ? 0 : 1}}>{"Boxprings >"}</p>
                                                <p onClick={() => handleMenuItemClick('/inventory-page')} className="font-montserrat font-bold text-[16px] text-black leading-8 cursor-pointer animated absolute" style = {{opacity: activeSubMenu === 2 ? 1 : 0}}>{"Boxprings>"}</p>
                                            </button>
                                            <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: activeSubMenu === 2 ? '300px' : '0px', opacity: activeSubMenu === 2 ? 1 : 0}}>
                                                <p onClick={() => handleMenuItemClick('/inventory-page')} className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Boxprings</p>
                                                <p onClick={() => handleMenuItemClick('/inventory-page')} className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Boxprings met Opbergruimte</p>
                                                <p onClick={() => handleMenuItemClick('/inventory-page')} className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Electrische Boxprings</p>
                                            </div>
                                        </div>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Commodes</p>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Eenpersoonsbedden</p>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Hoogslapers</p>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Kledingkasten</p>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Kledingrekken</p>
                                        <div className = "flex flex-col">
                                            <button type = "button" className = "flex relative" onClick = {() => setActiveSubMenu(3)}>
                                                <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer animated" style = {{opacity: activeSubMenu === 3 ? 0 : 1}}>{"Lattenbodems >"}</p>
                                                <p className="font-montserrat font-bold text-[16px] text-black leading-8 cursor-pointer animated absolute" style = {{opacity: activeSubMenu === 3 ? 1 : 0}}>{"Lattenbodems >"}</p>
                                            </button>
                                            <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: activeSubMenu === 3 ? '300px' : '0px', opacity: activeSubMenu === 3 ? 1 : 0}}>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Lattenbodem</p>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Electrische Lattenbodem</p>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Potenset</p>
                                            </div>
                                        </div>
                                        <div className = "flex flex-col">
                                            <button type = "button" className = "flex relative" onClick = {() => setActiveSubMenu(4)}>
                                                <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer animated" style = {{opacity: activeSubMenu === 4 ? 0 : 1}}>{"Matrassen >"}</p>
                                                <p className="font-montserrat font-bold text-[16px] text-black leading-8 cursor-pointer animated absolute" style = {{opacity: activeSubMenu === 4 ? 1 : 0}}>{"Matrassen >"}</p>
                                            </button>
                                            <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: activeSubMenu === 4 ? '300px' : '0px', opacity: activeSubMenu === 4 ? 1 : 0}}>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Koudschuim Matras</p>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Latex Matras</p>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Traagschuim Matras</p>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Pocketvering Matras</p>
                                            </div>
                                        </div>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Nachtkasten</p>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Opklapbedden</p>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Seniorenbedden</p>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Stapelbedden</p>
                                        <div className = "flex flex-col">
                                            <button type = "button" className = "flex relative" onClick = {() => setActiveSubMenu(5)}>
                                                <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer animated" style = {{opacity: activeSubMenu === 5 ? 0 : 1}}>{"Topdekmatrassen >"}</p>
                                                <p className="font-montserrat font-bold text-[16px] text-black leading-8 cursor-pointer animated absolute" style = {{opacity: activeSubMenu === 5 ? 1 : 0}}>{"Topdekmatrassen >"}</p>
                                            </button>
                                            <div className = "flex flex-col animated overflow-hidden" style = {{maxHeight: activeSubMenu === 5 ? '300px' : '0px', opacity: activeSubMenu === 5 ? 1 : 0}}>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Koudschuim Topper</p>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Latex Topper</p>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Split Topper</p>
                                                <p className="font-montserrat font-light text-[14px] header-menu-subtext leading-8 cursor-pointer ml-[15px]">Traagschuim Topper</p>
                                            </div>
                                        </div>
                                        <p className="font-montserrat font-light text-[16px] header-menu-subtext leading-8 cursor-pointer">Tweepersoonsbedden</p>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </div>
                </Container>
            </Grid>
        </div>
    )
}

MenuCatalogue.defaultProps = {
    onMouseOver: () => {},
    onMouseLeave: () => {}
}

MenuCatalogue.propTypes = {
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func
}
