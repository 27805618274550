import React, { useState }  from "react";
import { Grid, Container } from "@mui/material";

import StickyButton from '../../components/maakeenafspraak_sticky_button/sticky_button';
import HomePageCarousel from '../../components/homepage_main_carousel';
import ImagesWithAction from '../../components/home_page_item_two';
import CookieAlert from '../../components/cookie_alert/cookie_alert';
import Collectie from "./collectie/collectie";
import HomeBoxes from "./homepageBoxes";
import { cookieConfig, cookieExist } from '../../components/cookie_alert/cookie_configuration';

import "./homepage.scss";

export default function Homepage() {

    function useWindowSize() {
        const [size, setSize] = React.useState([0]);
        React.useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth]);
            }
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    }

    const isMobile = useWindowSize() < 1025;

    return (
    <div>
      <HomePageCarousel />
        {isMobile ? (
            <HomeBoxes />
        ): (
            <ImagesWithAction />
        )}
      <Collectie />
      <StickyButton />
    </div>
  );
}
