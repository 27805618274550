import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Breadcrumb1 from "./breadcrumb1";
import { useNavigate } from 'react-router-dom';

import './breadcrumb.scss';

const Breadcrumb = ({
    options
}) => {
    const navigate = useNavigate();
    return (
        <Breadcrumb1>
            {options.items.map(({ to, label, active }) => {
                return (
                    <div key={to} className="pb-[20px] pt-[20px] some-custom-classname ">
                        {active ? (
                          <span className="active-breadcrumb-item">{label}</span>
                        ) : (
                            <a href={to}>
                                {label}
                            </a>
                        )}
                    </div>
                );
            })}
        </Breadcrumb1>
    );
};

Breadcrumb.propTypes = {
    options: PropTypes.object.isRequired,
}

export default Breadcrumb;
