export const checkURLValidity = (url) => {
  let result =
    url &&
    url
      .split(".")
      .some(
        (o, i, arr) =>
          o[arr.length - 1] === ("jpeg" || "png" || "PNG" || "JPEG" || "svg")
      );
  return result;
};
