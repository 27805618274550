import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import { getCollections } from "../../../redux/actions/collection";
import { getCategories } from "../../../redux/actions/category";
import "./collectie.scss";

function Collectie(props) {
  const [mouseHoverState, setMouseHoverState] = useState(0);
  const [collections, setCollections] = useState([]);
  const [brands, setBrands] = useState();
  const { itemColor } = Collectie.styles;
  const navigate = useNavigate();

  useEffect(() => {
    getMultiples();
    // eslint-disable-next-line
  }, []);

  const getMultiples = async () => {
    try {
      const [{ data: collectionsData }, { data: categoriesData }] =
        await Promise.all([props.getCollections(), props.getCategories()]);
      let result = categoriesData.find((d) => d.name === "Ruimtes");
      if (result) setBrands(result);
      setCollections([...collectionsData]);
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Container maxWidth="lg">
        <Grid container>
          {/* first grid used to provide spacing between above part of homepage */}
          <Grid container>
            <div className="mt-[89px]" />
          </Grid>
          <Grid container justifyContent="center">
            <h1 className="font-dmserif text-[50px] text-black">Collectie</h1>
          </Grid>
          {/* third grid used to provide spacing between header and collection items */}
          <Grid container>
            <div className="mt-[41px]" />
          </Grid>
          <div className="w-full collectie-container flex-wrap">
            {brands?.children?.map((b, i) => {
              return (
                b.show && (
                  <Link to={b.link} key={b.id}>
                    <div
                      className="flex flex-col items-center mb-[49px] cursor-pointer"
                      onMouseOver={() => setMouseHoverState(i + 1)}
                      onMouseLeave={() => setMouseHoverState(0)}
                    >
                      <div
                        className={`h-[208px] w-[260px] bg-[${b.color}]/[0.54] flex justify-center items-center`}
                      >
                        <img
                          className="h-[219px] w-[209px] object-contain animated"
                          alt="tienerkamer"
                          src={b.image}
                          style={{
                            transform:
                              mouseHoverState === i + 1
                                ? "scale(1.1) translateY(-15px)"
                                : "scale(1) translateY(-15px)",
                          }}
                        />
                      </div>
                      <p
                        className="font-montserrat font-semibold text-[15px] mt-[20px] tracking-[0.9px]"
                        style={{ itemColor }}
                      >
                        {b.name}
                      </p>
                    </div>
                  </Link>
                )
              );
            })}
          </div>

          <Grid container justifyContent="center">
            <h1 className="font-dmserif text-[50px] text-black">Merken</h1>
          </Grid>
          <div className="w-full collectie-container flex-wrap mt-20">
            <Grid container className = "collectie-merken-container" >
              {collections?.map((c, i) => {
                return (
                  c.show && (
                    <div
                      className="flex flex-col mb-[49px] cursor-pointer"
                      onMouseOver={() => setMouseHoverState(i + 1)}
                      onMouseLeave={() => setMouseHoverState(0)}
                      onClick = {() => navigate("inventory-page?brand=" + c?.brand_id + "&brandname=" + c?.name)}
                      key = {c?.id}
                    >
                      <div
                        className={`h-[208px] w-[260px] bg-[${c.color}]/[0.54] flex justify-center items-center`}
                      >
                        <img
                          className="h-full w-full object-cover animated"
                          alt="tienerkamer"
                          src={c.image}
                          style={{
                            transform:
                              mouseHoverState === i + 1
                                ? "scale(1.1) translateY(-15px)"
                                : "scale(1) translateY(-15px)",
                          }}
                        />
                      </div>
                      <p
                        className="w-[260px] text-center font-montserrat font-semibold text-[15px] mt-[20px] tracking-[0.9px]"
                        style={{ itemColor }}
                      >
                        {c.name}
                      </p>
                    </div>
                  )
                );
              })}
            </Grid>
          </div>
        </Grid>
      </Container>
    </Grid>
  );
}

Collectie.styles = {
  itemColor: {
    color: "black",
  },
};

const mapStateToProps = ({ collection, category }) => ({
  collection,
  category,
});
export default connect(mapStateToProps, { getCollections, getCategories })(
  Collectie
);
