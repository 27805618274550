import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import DropDown from '../../../components/inputs/drop_down';
import RadioGroup from '../../../components/inputs/radio_group';
import axios from "axios";
import { BASE_URL, getDeliveryOptions } from '../../../redux/constants/ApiEndPoints';
import { AiOutlineFilePdf, AiOutlineFileImage  } from 'react-icons/ai';
import { IoDocumentOutline, IoCloseCircleOutline } from 'react-icons/io5';

import './delivery.scss';

const houseType = ["Appartement", "Rijhuis", "Herenhuis", "Koppelwoning", "Losstaande woning", "Bel-etage"];
const floorType = ["Gelijkvloers", "1ste verdieping", "2de verdieping", "ede verdieping", "4de verdieping", "verdieping"];

const Delivery = forwardRef((props, ref) => {

    const dropDownLabelProps = 'text-left font-montserrat font-semibold text-[14px] mb-[5px]'
    const [ houseList, setHouseList ] = useState([]);
    const [rbState1, setRBState1]  = useState('');
    const [rbState2, setRBState2]  = useState('');
    const [ houseDropdownState, setHouseDropdownState ] = useState(null);
    const [ floorDropdownState, setFloorDropdownState ] = useState(null);
    const [ filesState, setFilesState ] = useState([]);
    const [ errorStates, setErrorStates ] = useState({
        radioOne: false,
        radioTwo: false,
        dropdownOne: false,
        dropdownTwo: false
    })

    const fileInputRef = useRef(0);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    useImperativeHandle(ref, () => ({
        getDeliveryData: () => {
            const house_id = houseDropdownState;
            const floor = floorDropdownState;
            const files = filesState;
            let errorState = {
                radioOne: false,
                radioTwo: false,
                dropdownOne: false,
                dropdownTwo: false
            }
            if(!houseDropdownState) {
                errorState.dropdownOne = true;
            }
            else {
                errorState.dropdownOne = false;
            }
            if(!floorDropdownState) {
                errorState.dropdownTwo = true;
            }
            else {
                errorState.dropdownTwo = false;
            }
            if(rbState1 === "") {
                errorState.radioOne = true;
            }
            else {
                errorState.radioOne = false;
            }
            if(rbState2 === "") {
                errorState.radioTwo = true;
            }
            else {
                errorState.radioTwo = false;
            }
            setErrorStates(errorState)
            if(!errorState.radioOne && !errorState.radioTwo && !errorState.dropdownOne && !errorState.dropdownTwo) {
                return {
                    house_id,
                    floor,
                    files,
                    isSpacious: rbState1 === "JA" ? true : false,
                    isElevator: rbState2 === "JA" ? true : false
                }
            }
            else {
                return null;
            }
        }
    }))

    useEffect(() => {
        axios.get(BASE_URL + getDeliveryOptions, {headers}).then(response => {
            setHouseList(response.data.data)
        }).catch(error => console.log(error))
    }, [])

    const getFileType = (filetype) => {
        if(filetype?.type) {
            const { type } = filetype;
            if(type?.includes("pdf")) {
                return <AiOutlineFilePdf size = "1.5em" color = "#C98468" />
            }
            else if(type?.includes("doc")) {
                return <IoDocumentOutline size = "1.5em" color = "#C98468" />
            }
            else {
                return <AiOutlineFileImage size = "1.5em" color = "#C98468" />
            }
        }
        else {
            return <></>
        }
    }

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    }

    const handleFileSelect = (event) => {
        const filesStateCopy = [...filesState];
        filesStateCopy.push(event.target.files[0])
        setFilesState(filesStateCopy || []);
    }

    const handleFileRemove = (fileIndex) => {
        const fileStateCopy = [...filesState];
        fileStateCopy.splice(fileIndex, 1);
        setFilesState(fileStateCopy || []);
    }

    const RowDelivery = ({label, mtRadio, rbState, setRBState, errorState}) => {
        return (
            <div className='grid_5_1 bg-[#fafafa] pl-[20px] mb-[10px]'>
                <div className = {`py-[19px] text-[16px] text-${errorState ? "[red]" : "[#404040]"} font-montserrat md:mr-[100px]`}>
                    {label}
                </div>
                <div className={`text-right mobile-padding w-full mt-[${mtRadio}]`}>
                    <RadioGroup 
                        rbMobileProp={true}
                        rbState={rbState}
                        setRBState={setRBState}
                        labels={['JA', 'NEE']}
                        spaceBefore='.....'
                        spaceBetween='.....'
                        labelProps='ml-[5px]'
                        styleProps='text-[14px] text-[#27292C] font-semibold font-montessart'
                        spaceColor='text-[#fafafa]'
                    />
                </div>
            </div>
        )
    }
    
    const RowsDelivery = () => {
        return (
            <div className='mb-[15px]'>
                <RowDelivery
                    label='Zijn deuren, ramen, trapzaal e.d. voldoende groot?'
                    mtRadio='22px'
                    rbState={rbState1}
                    setRBState={setRBState1}
                    errorState = {errorStates?.radioOne}
                />
                <RowDelivery
                    label='Hebt u toelating om de lift te gebruiken? Zo via de trap te leveren (€ 15 per verdiep, vanaf 2e verdiep)'
                    mtRadio='33px'
                    rbState={rbState2}
                    setRBState={setRBState2}
                    errorState = {errorStates?.radioTwo}
                />
            </div>

        )
    }

    const UploadFiles = () => {
        return(
            <>
                <div className='grid_5_1 py-[19px] bg-[#fafafa] px-[20px] mb-[10px]'>
                    <div className='pt-[10px] text-[16px] text-[#404040] font-montserrat'>
                        Upload foto's van de doorgang voor de levering
                    </div>
                    <div className='text-right w-full md:w-auto md:block flex justify-center'>
                        <button className="float-right text-[18px] mobile-padding-button h-[45px] w-[189px] bg-redOrange-100 text-white font-semibold rounded-full" onClick = {handleUploadButtonClick}>
                            Kies bestanden
                        </button>
                        <input type = "file" className = "hidden" ref = {fileInputRef} onChange = {handleFileSelect} />
                    </div>
                </div>
                {filesState && filesState?.length > 0 && filesState?.map((fileItem, fileIndex) => (
                    <div key={`osiles-${fileIndex}`} className='grid_5_1 py-[19px] bg-[#fafafa] px-[20px] mb-[10px] items-center'>
                        <div className = "flex items-center pt-[10px]">
                            <div className = "mr-[15px]">
                                {getFileType(fileItem)}
                            </div>
                            <div className='text-[16px] text-[#404040] font-montserrat'>
                                {fileItem?.name || ""}
                            </div>
                        </div>
                        <div className = "cursor-pointer flex justify-end" onClick = {() => handleFileRemove(fileIndex)}>
                            <IoCloseCircleOutline size = "2em" color = "#C98468" />
                        </div>
                    </div>
                ))}
            </>
        )
    }

    return (
        <div>
            <div className='grid_1_1 mb-[15px]'>
                <DropDown
                    contentArr={houseList[0]?.children || houseType}
                    width='full'
                    class_='bdd_qty p_singular border_color_redOrange'
                    label={houseList[0]?.name}
                    labelProps={dropDownLabelProps}
                    onChange = {value => setHouseDropdownState(value)}
                    placeholderValue = ""
                    enablePlaceholder
                    errorState = {errorStates.dropdownOne}
                />
                <DropDown
                    contentArr={houseList[1]?.children || floorType}
                    width='full'
                    class_='bdd_qty p_singular border_color_redOrange'
                    label={houseList[1]?.name}
                    labelProps={dropDownLabelProps}
                    onChange = {value => setFloorDropdownState(value)}
                    placeholderValue = ""
                    enablePlaceholder
                    errorState = {errorStates.dropdownTwo}
                />
            </div>
            <RowsDelivery />
            <UploadFiles /> 
        </div>
    )
})

export default Delivery;