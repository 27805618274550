import axios from "../axios/axios";
import {
  send_appointment_email,
  job_application_email,
} from "../constants/ApiEndPoints";
const config = {
  headers: { "content-type": "multipart/form-data" },
};

export const sendAppointmentEmail = (values) => {
  return async (dispatch) => {
    const { data } = await axios.post(send_appointment_email, {
      lastname: values.lastname,
      title: values.title,
      phone: values.phone,
      email: values.email,
      location: values.location,
      reciever_email: values.reciever_email,
      contact_through: values.contact_through,
      brand: values.brand || null,
      description: values.description || null,
      product_image: values.product_image || null,
      type: values.type || false
    });
    return data;
  };
};

export const jobApplicationEmail = (values, docs) => {
  let formData = new FormData();
  for (let i = 0; i < docs.length; i++) {
    formData.append("documents", docs[i]);
  }
  Object.keys(values).forEach((key) =>
    formData.append(key, JSON.stringify(values[key]))
  );
  return async (dispatch) => {
    const { data } = await axios.post(job_application_email, formData);
    return data;
  };
};
