import React from "react";
import { Container } from "@mui/material";
import useBreadcrumb from "./use-breadcrumb";
import BreadcrumbCollapser from "./breadcrumb-collapser";

const BreadcrumbItem = ({children, ...props}) => (
    <li className="breadcrumb-item" {...props}>
        {children}
    </li>
);

const BreadcrumbSeparator = ({children, ...props}) => (
    <li className="breadcrumb-separator" {...props}>
        {children}
    </li>
);

const toBreadcrumbItem = (child, index) => (
    <BreadcrumbItem key={`breadcrumb_item${index}`}>{child}</BreadcrumbItem>
);

const withSeparator = (lastIndex, separator) => (acc, child, index) => {
    const notLast = index < lastIndex;
    if (notLast) {
        acc.push([
            child,
            <BreadcrumbSeparator key={`breadcrumb_sep${index}`}>
               /
            </BreadcrumbSeparator>
        ]);
    } else {
        acc.push(child);
    }
    return acc;
};

const withCollapse = ({
    itemsBefore,
    itemsAfter,
    children,
    totalItems,
    open
}) => [
    ...children.slice(0, itemsBefore),
    <BreadcrumbCollapser
        title="Expand"
        key="collapsed-seperator"
        onClick={open}
    />,
    ...children.slice(totalItems - itemsAfter, totalItems)
];

const Breadcrumb = ({separator, collapse = {}, ...props}) => {
    let children = React.Children.toArray(props.children);
    if(!children[children.length - 1]?.props?.children?.props?.children) {
        children.pop();
    }

    const {expanded, open} = useBreadcrumb();
    const {itemsBefore = 1, itemsAfter = 1, max = 1} = collapse;
    const totalItems = children.length;
    const lastIndex = totalItems - 1;

    children = children
        .map(toBreadcrumbItem)
        .reduce(withSeparator(lastIndex, separator), []);

    if (!expanded || totalItems <= max) {
        children = withCollapse({
            itemsBefore,
            itemsAfter,
            max,
            children,
            totalItems,
            open
        });
    }

    return (
        <div className='py-[10px] text-[16px] font-montserrat bg-[#fafafa]  text-[#27292C] afspraak_form_parent'>
            <Container><ol style={{overflowX:'auto', zIndex:99}}>{children}</ol></Container>
        </div>
    )
};

export default Breadcrumb;
