import request from './../../common/request';

export const getAllAppointments = (setResponse, setFetching) => {
    if (setFetching) {
        setFetching(true);
    }

    const url = '/appointment';
    const response = request.get(url);

    response
        .then((res) => {
            if (setFetching) {
                setFetching(false);
            }
            const abc = res?.data?.data;
            setResponse(abc);
        })
        .catch(() => {
            if (setFetching) {
                setFetching(false);
            }
        });
};
