import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Carousel from "./main_carousel";
import { getCarousels } from "../../redux/actions/carousel";

const HomePageCarousel = (props) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    props.getCarousels();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let imgs = [];
    props.carousel?.all_carousels?.map((obj) => (obj?.show) && imgs.push(obj.image));
    setImages([...imgs]);
  }, [props.carousel.all_carousels]);

  return <Carousel imgs={images ? images : []} />;
};

const mapStateToProps = ({ carousel }) => ({ carousel });
export default connect(mapStateToProps, { getCarousels })(HomePageCarousel);
