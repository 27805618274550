import React, {Fragment, useEffect, useState} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import BreakpointController from "../../components/breakpoint_controller/breakpoint_controller";
import {getHeaderText} from "./header.api";
import { BASE_URL, menu, getCart } from "../../redux/constants/ApiEndPoints";
import MenuGeneric from "../header_bottom/components/menu_generic";
import Brochures from "../brochures/brochures";

// images and icons
import CrossIcon from "../../images/icons/Cross_icon.svg"
import LogoTop from "../../images/icons/logo_top.png"
import Heart from "../../images/icons/heart.svg"
import Feather from "../../images/icons/feather.svg"
import Person from "../../images/icons/person_2.svg"

import "./header_top.scss";
import axios from "axios";

const HeaderTop = (props) => {
    const [ blackHeaderState, setBlackHeaderState ] = useState(true)
    const [ searchState, setSearchState ] = useState("");
    const [ menuControl, setMenuControl ] = useState(0)
    const [ isFetching, setIsFetching ] = useState(false);
    const [ headerText, setHeaderText ] = useState([]);
    const [ topHeaderList, setTopHeaderList ] = useState([]);
    const [ brochureModalId, setBrochureModalId ] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const userData = JSON.parse(localStorage.getItem('user-data'))
    const { cartCount } = props;

    useEffect(() => {
        getHeaderText(setIsFetching, setHeaderText);
        axios.get(BASE_URL + menu + "?show=true").then(response => {
            setTopHeaderList(response.data.data);
        }).catch(error => console.log(error))
    }, []);

    const handleCloseButton = () => {
        setBlackHeaderState(false)
    }

    const handleSearchChange = event => {
        setSearchState(event.target.value)
    }

    const handleSearchKeyUp = event => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            handleSearchSubmit();
        }
    }

    const handleSearchSubmit = () => {
        if(searchState) {
            const currentPage = location?.pathname;
            if(currentPage === "/inventory-page") {
                navigate(`inventory-page?search=${searchState}`);
                setSearchState("")
                navigate(0);
            }
            else {
                navigate(`inventory-page?search=${searchState}`);
                setSearchState("")
            }
        }
    }

    const openBrochureModal = id => {
        setBrochureModalId(id)
    }

    return(
        <div>
            {headerText[0]?.show && (
                <div className="header-top-text h-auto sm:h-[41px] bg-black w-full flex flex-row items-center justify-center relative overflow-hidden animated" style = {{opacity: blackHeaderState ? 1 : 0, maxHeight: blackHeaderState ? '200px' : '0px'}}>
                    <BreakpointController breakpoint = "lg">
                        <p className="text-white text-[12px] text-center mx-[45px] font-regular" dangerouslySetInnerHTML={{ __html: headerText[0]?.description }} />
                    </BreakpointController>
                    <BreakpointController breakpoint = "lg" direction = "up">
                        <p className="text-white text-[12px] text-center mx-[45px] font-regular" dangerouslySetInnerHTML={{ __html: headerText[0]?.description }} />
                    </BreakpointController>
                    <button type = "button" className = "cursor-pointer absolute right-[10px]" onClick = {handleCloseButton}>
                        <img
                            src={CrossIcon}
                            alt="cross"
                            className="h-[21px] w-[21px] object-contain"
                        />
                    </button>
                </div>
            )}
            <BreakpointController breakpoint = "md">
                <>
                    <div className="h-[48px] bg-redOrange-100 w-full flex flex-row items-center justify-center">
                        <Container maxWidth = "lg">
                            <Grid container justifyContent="flex-end" alignItems="center">
                                {topHeaderList && topHeaderList.map(((headerItem, headerIndex) => (
                                    <Fragment key={`header-top-main-${headerIndex}`}>
                                        {headerItem?.children && headerItem?.children?.length > 0 ? (
                                            <div className = "h-full flex justify-center relative" onMouseOver={() => setMenuControl(headerIndex + 1)} onMouseLeave={() => setMenuControl(0)}>
                                                <p className = "font-montserrat font-regular text-[15px] text-white cursor-pointer">{headerItem?.name || ""}</p>
                                                <div className = "absolute header-menu-extended-container mt-3 top-[100%]" style = {{opacity: menuControl === (headerIndex + 1) ? 1 : 0, transform: menuControl === (headerIndex + 1) ? 'translateY(0px)' : 'translateY(-10px)', zIndex: menuControl  === (headerIndex + 1) ? 99 : -1}}>
                                                    {headerItem?.type_id === 1 ? (
                                                        <MenuGeneric>
                                                            <div className = "flex">
                                                                {headerItem?.children?.map((headerSubItem, index) => (
                                                                    <div key={`header-top-sub-${index}`} className = "flex flex-col w-[210px] m-[13px] box-border cursor-pointer" onClick = {() => openBrochureModal(headerSubItem)}>
                                                                        <div className = {`w-full h-[134px] bg-[${headerSubItem?.color || "#99D1B3"}]/[0.54] flex justify-center`}>
                                                                            <img className = "w-full h-full object-cover" src = {headerSubItem?.image || ""} alt = "tienerkamer"  />
                                                                        </div>
                                                                        <p className = "font-montserrat font-light text-[16px] mb-0 tracking-wider mt-[9px]" style={{ color: 'black' }}>{headerSubItem?.name || ""}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </MenuGeneric>
                                                    ) : (
                                                        <MenuGeneric>
                                                            <div className = "flex flex-col">
                                                                {headerItem?.children?.map((headerSubItem, index) => (
                                                                    <p key={`header-top-sub-${index}`} className = "font-montserrat font-light text-[16px] header-menu-subtext cursor-pointer leading-10" onClick = {() => navigate(headerSubItem?.url || "/")}>{headerSubItem?.name || ""}</p>
                                                                ))}
                                                            </div>
                                                        </MenuGeneric>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <Link to={headerItem?.url || "/"} className="link-style-class">
                                                <p className = "font-montserrat font-regular text-[15px] text-white cursor-pointer">{headerItem?.name}</p>
                                            </Link>
                                        )}
                                        {headerIndex !== (topHeaderList.length - 1) && (
                                            <p className = "font-montserrat font-regular text-[15px] text-white mx-[17px]">|</p>
                                        )}
                                    </Fragment>
                                )))}
                            </Grid>
                        </Container>
                    </div>
                    <div className="bg-[#F7F6F5] w-full flex justify-center">
                        <Container maxWidth = "lg">
                            <Grid container justifyContent= "space-between" alignItems="center">
                                <Link to="/">
                                    <img
                                        alt="logo"
                                        src={LogoTop}
                                        className="w-[373px] object-contain translate-y-[-25px] cursor-pointer"
                                    />
                                </Link>
                                <div className = "h-[41px] w-[507px] flex flex-row overflow-hidden flex-nowrap">
                                    <div className = "w-full h-full px-[24px] border-[#C98468] border-[1px] box-border rounded-l-[20px] flex items-center bg-white">
                                        <input type="text" style={{ overflow: 'ellipsis' }} placeholder="Zoek naar producten, categorieën, merken,…" className="w-full h-full font-montserrat text-[15px] font-semibold outline-none bg-transparent" value = {searchState} onKeyUp={handleSearchKeyUp} onChange = {handleSearchChange} />
                                    </div>
                                    <button type = "button" className = "w-[108px] h-full bg-[#C98468] rounded-r-[20px] flex flex-row justify-center items-center shrink-0" onClick = {handleSearchSubmit}>
                                        <p className = "font-montserrat font-bold text-[15px] text-white">Zoeken</p>
                                    </button>
                                </div>
                                <div className="flex">
                                    <div className="flex flex-row mr-[30px] cursor-pointer" onClick={() => navigate(`/info`)}>
                                        <img
                                            src={Person}
                                            alt="person"
                                            className="h-[20px] object-contain mr-[5px]"
                                        />
                                        <p className="font-montserrat font-regular text-[13px] text-[#C98468] capitalize">{userData?.firstName || "Account"}</p>
                                    </div>
                                    <div className="flex flex-row mr-[30px] cursor-pointer" onClick={() => navigate(`/favorites`,)}>
                                        <img
                                            src={Heart}
                                            alt="heart"
                                            className="h-[20px] object-contain mr-[5px]"
                                        />
                                        <p className="font-montserrat font-regular text-[13px] text-[#C98468]">Wensenlijst</p>
                                    </div>
                                    <div className = "cursor-pointer relative">
                                        <Link to={"/cart"}>
                                            <img
                                                src={Feather}
                                                alt="feather"
                                                className="h-[20px] object-contain relative"
                                            />
                                        </Link>
                                        {cartCount > 0 && (
                                            <div className = "w-[14px] h-[14px] absolute rounded-[10px] right-[-7px] bottom-[-7px] bg-[#C98468] flex justify-center items-center">
                                                <p className = "font-[Montserrat] text-white font-regular text-[9px]">{cartCount}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        </Container>
                    </div>
                </>
            </ BreakpointController>
            {brochureModalId && (
                <Brochures brochureId = {brochureModalId} onClose = {() => setBrochureModalId(null)} />
            )}
        </div>
    )
}

export default HeaderTop;
