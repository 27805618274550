import React from "react";
// import CustomCheckbox from "../custom_checkbox/customCheckbox";

export default function CheckboxList(props) {
  // let { checkboxList, handleCheckbox } = props;

  return (
    <div className="brands pt-14">
      <div className="text-center">
        <span className="fs-35 font-dmserif">Merken</span>
      </div>
      <div className="brands-checkbox-container pt-8 pb-14">
        {/* {checkboxList?.map((obj, index) => (
          <CustomCheckbox
            key={index}
            obj={obj}
            handleCheckbox={handleCheckbox}
          />
        ))} */}
      </div>
    </div>
  );
}
