import React from 'react';

import './radio_group.scss';

const RadioGroup =({
    labels,
    labelProps,
    rbState,
    setRBState,
    styleProps,
    spanStyleProps='',
    spaceBetween='',
    spaceAfter='',
    spaceColor='text-white',
    spaceBefore='',
    rbMobileProp = false
}) => { 
    const spacesClass = `${spaceColor} ${rbMobileProp ? 'mobile-space-show':''}`;

    return (
        <div className={`${rbMobileProp ? 'custom_radio_mobile_long_text':''} custom_radio font-montserrat flex justify-center ${styleProps}`}>
            {labels.map((label, index) => {
                return (
                    <div key={`radio-gitem-${index}`} className = "flex items-center mr-[45px]">
                        <div className = "h-[30px] w-[30px] rounded-[15px] border-[1px] border-[#C98468] flex justify-center items-center cursor-pointer mr-[15px] shrink-0" onClick = {() => setRBState(label)}>
                            {rbState === label && (
                                <div className = "h-[20px] w-[20px] rounded-[10px] bg-[#C98468]" />
                            )}
                        </div>
                        <p className = {"font-[Montserrat] font-regular text-[14px] text-black " + labelProps}>{label}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default RadioGroup;