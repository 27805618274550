import React from 'react';
import TopBar from './top_bar';
import PropTypes from "prop-types";

const LoginTopBar = (props) => {
    const inActive = ['Home','Woonkamers','Salons', 'Naam van de salon']
    const active = <span>Checkout</span>
    const { isActive } = props;

    return (
        <div style = {{display: isActive ? "block" : "none"}}>
            <TopBar
                activeProp={active}
                inActiveProps={inActive}
            />
        </div>
    )
}

export default LoginTopBar;

LoginTopBar.propTypes = {
    isActive: PropTypes.bool
}

LoginTopBar.defaultProps = {
    isActive: true
}