import check from "../../../assets/icons/check.svg";
import close from "../../../assets/icons/close.svg";

export const detailsConstant = [
  { name: "Model in showroom" },
  { name: "Izegem", image: close },
  { name: "Massenhoven", image: close },
];

export const detailsConstant2 = [
  { name: "Model in voorraad" },
  { name: "Izeghem", image: check, color: "red" },
  { name: "Massenhoven", image: close, color: "red" },
];
