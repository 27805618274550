import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import AllBrands from "../../components/filter_brand/brandsList";
import FilterBrands from "../../components/filter_brand/checkboxList";
import AlphabetSearch from "../../components/filter_brand/alphabetSearch";
import BreadcrumbsItem from "../../components/breadcrumb/breadcrumb";
import {
  checkConstants,
  all_brands,
} from "../../components/filter_brand/constants";
import { getBrands } from "../../redux/actions/brand";
import StickyButton from "../../components/maakeenafspraak_sticky_button/sticky_button";

function Brands({ getBrands, brand }) {
  const [checkboxList, setCheckboxList] = useState(checkConstants);
  const [allBrands, setAllBrands] = useState([]);
  const options = {
    icons: {},
    items: [
      { to: "/", label: "Home" },
      { to: "/brands-page", label: `Merken`, active: true },
    ],
  };

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAllBrands([...brand.all_brands]);
  }, [brand.all_brands]);

  const filterRecordsHandler = () => {
    let filterBrands = [];
    // checkboxList.map((obj) => {
    all_brands.forEach((obj2) => {
      // if (obj.selected && obj.name == obj2.type) filterBrands.push(obj2);
      filterBrands.push(obj2);
    });
    // });
    setAllBrands(filterBrands);
  };

  const handleCheckbox = (e) => {
    let index = e.currentTarget.id;
    let items = [...checkboxList];
    let prevValue = items[index].selected;
    items[index].selected = !prevValue;
    setCheckboxList([...items]);
    filterRecordsHandler();
  };

  return (
      <>
        <StickyButton />
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <BreadcrumbsItem options={options} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FilterBrands
                checkboxList={checkboxList}
                setCheckboxList={setCheckboxList}
                handleCheckbox={handleCheckbox}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <AlphabetSearch allBrands={allBrands} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <AllBrands allBrands={allBrands} />
          </Grid>
        </Grid>
      </>
  );
}
const mapStateToProps = ({ brand }) => ({ brand });
export default connect(mapStateToProps, { getBrands })(Brands);
