import axios from "../axios/axios";
import { get_sales } from "../constants/ApiEndPoints";
import { ALL_SALES } from "../constants/constants";
import qs from "qs";

export const getAllSales = (page, user_id, filterQuery) => {
  return async (dispatch) => {
    const { data } = await axios.get(`${get_sales}`, {
      params: {
        page,
        user_id,
        ...filterQuery
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat" })
      }
    });
    dispatch({ type: ALL_SALES, payload: data });
    return data;
  };
};
