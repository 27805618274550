import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { Modal } from "react-responsive-modal";
import CategoryCard from "../../components/button_card/index";
import { getAllProjects } from "../../redux/actions/project";
import Details from "../../components/project_category_details/index";
import Image1 from "./partials/image1";
import Image2 from "./partials/image2";
import Image3 from "./partials/image3";
import Image4 from "./partials/image4";
import Image5 from "./partials/image5";

import "./categories.scss";
import "react-responsive-modal/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ProjectDetails = ({ project, ...props }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [images, setImages] = useState();
  const [projectDescription, setProjectDescription] = useState();
  const [projectImages, setProjectImages] = useState([]);
  const [projectName, setProjectName] = useState();
  const [categoryName, setCategoryName] = useState();
  const [categoriesList, setCategoriesList] = useState([]);

  const { projectId, categoryId } = useParams();
  const options = {
    icons: {},
    items: [
      { to: "/", label: "Home" },
      { to: "/project-categories", label: "Projecten" },
      { to: `/project-categories/${categoryId}`, label: `${categoryName}` },
      { to: "/", label: `${projectName}`, active: true },
    ],
  };

  const getData = async () => {
    const data = await props.getAllProjects();
    setCategoriesList(data?.data || []);
  };


    useEffect(() => {
      getData();
  }, []);

  useEffect(() => {
    project?.all_categories?.map((p) => {
      if (Number(p.id) === Number(categoryId)) {
        if (p.children?.length > 0) {
          p.children?.map((c) => {
            if (c.id === projectId) {
              setCategoryName(p.name);
              setImages(c.images.length);
              setProjectImages(c.images);
              setProjectName(c.name);
              setProjectDescription({
                title: c.name,
                description: c.description,
              });
            }
            return true;
          });
        }
      }
      return true;
    });
  }, [project.all_categories, categoryId, projectId]);

  useEffect(() => {
    if (open) document.body.style.overflowY = "hidden";
    else document.body.style.overflowY = "scroll";
  }, [open]);

  const onOpenModal = (index) => {
    setSelected(index);
    setOpen(true);
  };

  const onCloseModal = () => setOpen(false);

  return (
    <Grid container>
      <Details options={options} pageDetails={projectDescription} />
      <Container>
        <Grid container justifyContent={"space-between"}>
          {images === 1 && (
              <Image1
                  images={images}
                  projectImages={projectImages}
                  onOpenModal={onOpenModal}
              />
          )}

          {images === 2 && (
              <Image2
                  images={images}
                  projectImages={projectImages}
                  onOpenModal={onOpenModal}
              />
          )}

          {images === 3 && (
              <Image3
                  images={images}
                  projectImages={projectImages}
                  onOpenModal={onOpenModal}
              />
          )}

          {images === 4 && (
              <Image4
                  images={images}
                  projectImages={projectImages}
                  onOpenModal={onOpenModal}
              />
          )}

          {images >= 5 && (
              <Image5
                  images={images}
                  projectImages={projectImages}
                  onOpenModal={onOpenModal}
              />
          )}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt-10">
            <div>
              <Modal open={open} onClose={onCloseModal} center>
                <div className="categories-carousel-container">
                  <Carousel infiniteLoop showArrows = {true} className = "w-full" stopOnHover={true} selectedItem={selected}>
                    {projectImages.map((img, index) => (
                      <div key={`cat-p-img-${index}`} className = "h-full">
                        <img src={img.image} alt="img" className = "object-contain" />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </Modal>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="text-center">
            <span className="fs-35 font-dmserif">
              Bekijk onze andere projecten
            </span>
          </div>
          <Container maxWidth="lg">
            <div className="categories-category h-auto mb-[20px] mt-[40px] gap-[20px]">
              {categoriesList.map((obj, index) => {
                return obj?.show && (
                  <CategoryCard key={`cat-project-${index}`} obj={obj} isDetail />
                )
              })}
            </div>
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ project, loader }) => ({
  project,
  loader,
});
export default connect(mapStateToProps, {
  getAllProjects,
})(ProjectDetails);
