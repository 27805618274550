import React, {useState} from "react";
import {Grid, InputAdornment, TextField} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import axios from "axios";
import { BASE_URL, clientNewsletter } from "../../../redux/constants/ApiEndPoints";
import SuccessModal from "../../../components/success_modal/success_modal";

import imageInstagram from "../../../assets/images/footer/instagram.svg";
import imagePintrest from "../../../assets/images/footer/pinterest.svg";
import imageFacebook from "../../../assets/images/footer/facebook.svg";
import imageTwitter from "../../../assets/images/footer/twitter.svg";
import imageHelp from "../../../assets/images/footer/info.svg";
import interierLogo from "../../../assets/images/footer/logo-footer.png";
import checkFilled from "../../../assets/icons/check-filled.svg";

import "../footer.scss";
import agiflyLogo from "../../../assets/images/footer/Logo_White_1.svg";
import { REGEX } from "../../../constants/constants";

const useStyles = makeStyles(theme => ({
    notchedOutline: {
        borderWidth: "0px",
        borderColor: "grey !important"
    }
}));

const FooterLower = ({
    footerData,
}) => {
    const classes = useStyles();
    const [showInfoPopup, toggleInfoPopup] = useState(false);
    const [emailInputState, setEmailInputState] = useState("");
    const [newsletterSuccessModalState, setNewsletterSuccessModalState] = useState(false)
    const [emailInputErrorState, setEmailInputErrorState] = useState("")

    const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const handleInterieurLogoClick = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }

    const sendNewsletter = () => {
        const isValid = emailFieldValidation();
        if(emailInputState && isValid) {
            axios.get(BASE_URL + clientNewsletter + emailInputState, {headers}).then(response => {
                setNewsletterSuccessModalState(true)
            }).catch(error => {
                if(error.response.status === 500) {
                    setEmailInputErrorState("E-mail bestaat al");
                }
            })
        }
    }

    const sendNewsletterKeyevent = (event) => {
        if(emailInputState && event.keyCode === 13) {
            const isValid = emailFieldValidation()
            if(isValid) {
                axios.get(BASE_URL + clientNewsletter + emailInputState, {headers}).then(response => {
                    setNewsletterSuccessModalState(true)
                }).catch(error => {
                    if(error.response.status === 500) {
                        setEmailInputErrorState("E-mail bestaat al");
                    }
                })
            }
        }
    }

    const successModalTimeout = () => {
        setNewsletterSuccessModalState(false);
        setEmailInputState("")
    }

    const emailFieldValidation = () => {
        if(emailInputState === "") {
            setEmailInputErrorState("Veld is leeg")
            return false;
        }
        else if(!REGEX.EMAIL.test(emailInputState)) {
            setEmailInputErrorState("E-mail is niet geldig")
            return false;
        }
        else {
            setEmailInputErrorState("");
            return true;
        }
    }

    return (
        <div className="footer-mobile">
            <div className="section-1">
                <div className="grid grid-row">
                    <div className="p-3 item">
                        <Link to="/">
                            <img src={interierLogo} className="mt-[5px] logo-interier cursor-pointer" alt="Instagram" width={170} onClick = {handleInterieurLogoClick}/>
                        </Link>
                    </div>
                    <div className="p-2 item">
                        <div className="button-wrapper">
                            <Link to='/afspraak-form'>
                                <button className="button-1">
                                    Maak een afspraak
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }} className="mt-[10px]">
                        <div className="p-[20px]">
                            <a href="https://www.instagram.com/topinterieur/" target="_blank" rel="noreferrer">
                                <img src={imageInstagram} alt="Instagram" width={24}/>
                            </a>
                        </div>
                        <div className="p-[20px]">
                            <a href="https://www.pinterest.com/topinterieur/" target="_blank" rel="noreferrer">
                                <img src={imagePintrest} alt="Instagram" width={24}/>
                            </a>
                        </div>
                        <div className="p-[20px]">
                            <a href="https://www.facebook.com/topinterieur/" target="_blank" rel="noreferrer">
                                <img src={imageFacebook} alt="Instagram" width={13}/>
                            </a>
                        </div>
                        <div className="p-[20px]">
                            <a href="https://twitter.com/topinterieur/" target="_blank" rel="noreferrer">
                                <img src={imageTwitter} alt="Instagram" width={30}/>
                            </a>
                        </div>
                    </div>


                    <div className="mb-[30px] mt-[10px] mr-[10px] ml-[10px]" style={{ display: 'grid', justifyContent: 'center' }}>
                        <div style={{paddingBottom: '10px'}}>Schrijf in voor onze nieuwsbrief</div>
                        <div>
                            <TextField
                                variant="outlined"
                                placeholder="Jouw email adres"
                                value = {emailInputState}
                                onChange = {event => setEmailInputState(event.target.value)}
                                onKeyUp = {sendNewsletterKeyevent}
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline
                                    },
                                    className: "search-field-footer-lower",
                                    endAdornment: (
                                        <InputAdornment className = "cursor-pointer" onClick = {sendNewsletter} position="end">
                                            <img src={checkFilled} alt="Pin" width={28} className="text-redOrange-100" />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div>
                            {emailInputErrorState && emailInputErrorState !== "" && (
                                <p className = "font-[Montserrat] font-medium text-[16px] text-[red] mt-[15px]">{emailInputErrorState}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-2">
                <div className="item flex columns-3">
                    <div className="w-2/5 timing"/>
                    <div className="w-1/6">
                        <img src={footerData[0]?.image} alt="Pin" width={45} className="image-mobile-center"/>
                    </div>
                    <div className="w-2/5 ..."/>
                </div>

                <div className="item flex columns-3">
                    <div className="w-2/5 timing">
                        <div style={{
                            height: 35, marginTop: 15
                        }}>
                            {footerData[0]?.show && (
                                <img
                                    src={imageHelp}
                                    alt="Pin"
                                    width={22}
                                    onClick={() => {
                                        if (showInfoPopup === footerData[0]?.id) {
                                            toggleInfoPopup('');
                                        } else {
                                            toggleInfoPopup(footerData[0]?.id);
                                        }
                                    }}
                                    onMouseLeave={() => toggleInfoPopup('')}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                        </div>
                        <span className="heading">{footerData[0]?.title}</span>
                        {showInfoPopup === footerData[0]?.id ? (
                            <div style={{ backgroundColor: '#fd3f315c'}} className="p-[20px]">
                                <span dangerouslySetInnerHTML={{ __html: footerData[0]?.description }} />
                            </div>
                        ) : (
                            footerData[0]?.footerText?.map((text, index) => {
                                return (
                                    text?.show && (
                                        <div className="item-text" key={`mfooter-data-ft-${index}`}>
                                            {<span dangerouslySetInnerHTML={{ __html: text?.text }} />}
                                        </div>
                                    )
                                );
                            })
                        )}
                    </div>
                    <div className="w-1/6">
                        <div className="div-center-border h-[80%]"/>
                    </div>
                    <div className="timing w-2/5 ...">
                        <div style={{
                            height: 35, marginTop: 15
                        }}>
                            {footerData[4]?.show && (
                                <img
                                    src={imageHelp}
                                    alt="Pin"
                                    width={22}
                                    onClick={() => {
                                        if (showInfoPopup === footerData[4]?.id) {
                                            toggleInfoPopup('');
                                        } else {
                                            toggleInfoPopup(footerData[4]?.id);
                                        }
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                        </div>
                        <span className="heading">{footerData[4]?.title}</span>
                        {showInfoPopup === footerData[4]?.id ? (
                            <div style={{ backgroundColor: '#fd3f315c'}} className="p-[20px]">
                                <span dangerouslySetInnerHTML={{ __html: footerData[4]?.description }} />
                            </div>
                        ) : (
                            footerData[4]?.footerText?.map((text, index) => {
                                return (
                                    text?.show && (
                                        <div className="item-text" key={`mfooter4-data-ft-${index}`}>
                                            {<span dangerouslySetInnerHTML={{ __html: text?.text }} />}
                                        </div>
                                    )
                                );
                            })
                        )}
                    </div>
                </div>

                <div className="item flex columns-3">
                    <div className="w-2/5 timing"/>
                    <div className="w-1/6">
                        <img src={footerData[1]?.image} alt="Pin" width={45} className="image-mobile-center"/>
                    </div>
                    <div className="w-2/5 ..."/>
                </div>

                <div className="item flex columns-3">
                    <div className="w-2/5 timing">
                        <div style={{
                            height: 35, marginTop: 15
                        }}>
                            {footerData[1]?.show && (
                                <img
                                    src={imageHelp}
                                    alt="Pin"
                                    width={22}
                                    onClick={() => {
                                        if (showInfoPopup === footerData[1]?.id) {
                                            toggleInfoPopup('');
                                        } else {
                                            toggleInfoPopup(footerData[1]?.id);
                                        }
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                        </div>
                        <span className="heading">{footerData[1]?.title}</span>
                        {showInfoPopup === footerData[1]?.id ? (
                            <div style={{ backgroundColor: '#fd3f315c'}} className="p-[20px]">
                                <span dangerouslySetInnerHTML={{ __html: footerData[1]?.description }} />
                            </div>
                        ) : (
                            footerData[1]?.footerText?.map((text, index) => {
                                return (
                                    text?.show && (
                                        <div className="item-text" key={`mfooter1-data-ft-${index}`}>
                                            {<span dangerouslySetInnerHTML={{ __html: text?.text }} />}
                                        </div>
                                    )
                                );
                            })
                        )}
                    </div>
                    <div className="w-1/6">
                        <div className="div-center-border h-[90%]"/>
                    </div>
                    <div className="timing w-2/5">
                        <div style={{
                            height: 35, marginTop: 15
                        }}>
                            {footerData[5]?.show && (
                                <img
                                    src={imageHelp}
                                    alt="Pin"
                                    width={22}
                                    onClick={() => {
                                        if (showInfoPopup === footerData[5]?.id) {
                                            toggleInfoPopup('');
                                        } else {
                                            toggleInfoPopup(footerData[5]?.id);
                                        }
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                        </div>
                        <span className="heading">{footerData[5]?.title}</span>
                        {showInfoPopup === footerData[5]?.id ? (
                            <div style={{ backgroundColor: '#fd3f315c'}} className="p-[20px]">
                                <span dangerouslySetInnerHTML={{ __html: footerData[5]?.description }} />
                            </div>
                        ) : (
                            footerData[5]?.footerText?.map((text, index) => {
                                return (
                                    text?.show && (
                                        <div className="item-text" key={`mfooter5-data-ft-${index}`}>
                                            {<span dangerouslySetInnerHTML={{ __html: text?.text }} />}
                                        </div>
                                    )
                                );
                            })
                        )}
                    </div>
                </div>

                <div className="item flex columns-3">
                    <div className="w-2/5 timing"/>
                    <div className="w-1/6">
                        <img src={footerData[2]?.image} alt="Pin" width={45} className="image-mobile-center"/>
                    </div>
                    <div className="w-2/5 ..."/>
                </div>

                <div className="item flex columns-3">
                    <div className="w-2/5 timing">
                        <div style={{
                            height: 35, marginTop: 15
                        }}>
                            {footerData[2]?.show && (
                                <img
                                    src={imageHelp}
                                    alt="Pin"
                                    width={22}
                                    onClick={() => {
                                        if (showInfoPopup === footerData[2]?.id) {
                                            toggleInfoPopup('');
                                        } else {
                                            toggleInfoPopup(footerData[2]?.id);
                                        }
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                        </div>
                        <span className="heading">{footerData[2]?.title}</span>
                        {showInfoPopup === footerData[2]?.id ? (
                            <div style={{ backgroundColor: '#fd3f315c'}} className="p-[20px]">
                                <span dangerouslySetInnerHTML={{ __html: footerData[2]?.description }} />
                            </div>
                        ) : (
                            footerData[2]?.footerText?.map((text, index) => {
                                return (
                                    text?.show && (
                                        <div className="item-text" key={`mfooter2-data-ft-${index}`}>
                                            {<span dangerouslySetInnerHTML={{ __html: text?.text }} />}
                                        </div>
                                    )
                                );
                            })
                        )}
                    </div>
                    <div className="w-1/6">
                        <div className="div-center-border h-[80%]"/>
                    </div>
                    <div className="timing w-2/5 ...">
                        <img src={footerData[6]?.image} className="image-rooftop" alt="Pin" width={150}/>
                        <div style={{
                            height: 35, marginTop: 15
                        }}>
                            {footerData[6]?.show && (
                                <img
                                    src={imageHelp}
                                    alt="Pin"
                                    width={22}
                                    onClick={() => {
                                        if (showInfoPopup === footerData[6]?.id) {
                                            toggleInfoPopup('');
                                        } else {
                                            toggleInfoPopup(footerData[6]?.id);
                                        }
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                        </div>
                        <span className="heading mt-[0px]">{footerData[6]?.title}</span>
                        {showInfoPopup === footerData[6]?.id ? (
                            <div style={{ backgroundColor: '#fd3f315c'}} className="p-[20px]">
                                <span dangerouslySetInnerHTML={{ __html: footerData[6]?.description }} />
                            </div>
                        ) : (
                            footerData[6]?.footerText?.map((text, index) => {
                                return (
                                    text?.show && (
                                        <div className="item-text" key={`mfooter6-data-ft-${index}`}>
                                            {<span dangerouslySetInnerHTML={{ __html: text?.text }} />}
                                        </div>
                                    )
                                );
                            })
                        )}
                    </div>
                </div>

                <div className="item flex columns-3">
                    <div className="w-2/5 timing"/>
                    <div className="w-1/6">
                        <img src={footerData[3]?.image} alt="Pin" width={45} className="image-mobile-center"/>
                    </div>
                    <div className="w-2/5 ..."/>
                </div>

                <div className="item flex columns-3">
                    <div className="w-2/5 timing">
                        <div style={{
                            height: 35, marginTop: 15
                        }}>
                            {footerData[3]?.show && (
                                <img
                                    src={imageHelp}
                                    alt="Pin"
                                    width={22}
                                    onClick={() => {
                                        if (showInfoPopup === footerData[3]?.id) {
                                            toggleInfoPopup('');
                                        } else {
                                            toggleInfoPopup(footerData[3]?.id);
                                        }
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                        </div>
                        <span className="heading">{footerData[3]?.title}</span>
                        {showInfoPopup === footerData[3]?.id ? (
                            <div style={{ backgroundColor: '#fd3f315c'}} className="p-[20px]">
                                <span dangerouslySetInnerHTML={{ __html: footerData[3]?.description }} />
                            </div>
                        ) : (
                            footerData[3]?.footerText?.map((text, index) => {
                                return (
                                    text?.show && (
                                        <div className="item-text" key={`mfooter3-data-ft-${index}`}>
                                            {<span dangerouslySetInnerHTML={{ __html: text?.text }} />}
                                        </div>
                                    )
                                );
                            })
                        )}
                    </div>
                    <div className="w-1/6">
                        <div className="div-center-border h-[90%]"/>
                    </div>
                    <div className="timing w-2/5 ...">
                        <div style={{
                            height: 35, marginTop: 15
                        }}>
                            {footerData[7]?.show && (
                                <img
                                    src={imageHelp}
                                    alt="Pin"
                                    width={22}
                                    onClick={() => {
                                        if (showInfoPopup === footerData[7]?.id) {
                                            toggleInfoPopup('');
                                        } else {
                                            toggleInfoPopup(footerData[7]?.id);
                                        }
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                        </div>
                        <span className="heading">{footerData[7]?.title}</span>
                        {showInfoPopup === footerData[7]?.id ? (
                            <div style={{ backgroundColor: '#fd3f315c'}} className="p-[20px]">
                                <span dangerouslySetInnerHTML={{ __html: footerData[7]?.description }} />
                            </div>
                        ) : (
                            footerData[7]?.footerText?.map((text, index) => {
                                return (
                                    text?.show && (
                                        <div className="item-text" key={`mfooter7-data-ft-${index}`}>
                                            {<span dangerouslySetInnerHTML={{ __html: text?.text }} />}
                                        </div>
                                    )
                                );
                            })
                        )}
                    </div>
                </div>

                <div className="text-center">
                    <div className="item-text-footer">
                        <Link to="/terms-conditions">Algemene voorwaarden</Link>
                    </div>
                    <div className="item-text-footer">
                        <Link to="/cookies#privacy">Privacy Policy</Link>
                    </div>
                    <div className="item-text-footer">
                        <Link to="/cookies">Cookiebeleid</Link>
                    </div>

                    <div className="item-text-footer" style={{ display: 'inline-block' }}>
                        <Grid container>
                            <Grid item={6}>Made by </Grid>
                            <Grid item={6}>
                                <a href="https://agifly.be/" target="_blank">
                                    <img src={agiflyLogo} alt="" height={25} width={50} />
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                </div>

            </div>
            <SuccessModal message = "Succesvol ingeschreven voor onze nieuwsbrief!" modalState = {newsletterSuccessModalState} onTimeout = {successModalTimeout} timeoutDuration = {3000} />
        </div>
    )
};

export default FooterLower;
