import React, { useState, useEffect } from "react";
import { IoMdCheckmark } from "react-icons/io"
import PropTypes from "prop-types"

import "./check_box.scss";

export default function CheckBox(props) {
    const [ checkState, setCheckState ] = useState(false);

    const { initialValue, onClick } = props;

    useEffect(() => {
        if(typeof initialValue === "boolean"){
            setCheckState(initialValue)
        }
    }, [initialValue])

    const handleClick = () => {
        onClick();
        setCheckState(!checkState)
    }

    return(
        <button type = "button" className = "h-[18px] w-[18px] cursor-pointer flex justify-center items-center border-[1px] border-[#404040] rounded-[2px]" onClick = {handleClick}>
            <div className = "animated" style = {{opacity: checkState ? 1 : 0}}>
                <IoMdCheckmark size = "0.9rem" color = "#C98468" />
            </div>
        </button>
    )
}

CheckBox.propTypes = {
    initialValue: PropTypes.bool,
    onClick: PropTypes.func
}

CheckBox.defaultProps = {
    initialValue: false,
    onClick: () => {}
}