import { combineReducers } from "redux";
import auth from "./auth";
import product from "./product";
import category from "./category";
import loader from "./loader";
import vacatures from "./vacatures";
import box from "./box";
import collection from "./collection";
import carousel from "./carousel";
import brand from "./brand";
import sale from "./sale";
import projectPage from "./projectPage";
import promotion from "./promotion";
import project from "./project";

const rootReducer = combineReducers({
  auth: auth,
  product,
  sale,
  category,
  vacatures,
  box: box,
  projectPage,
  project,
  collection,
  promotion,
  carousel,
  brand,
  loader,
});

export default rootReducer;
