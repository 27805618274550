import React from "react";
import { Grid, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./filterBrands.scss";

const BrandsList = ({ allBrands }) => {
  const navigate = useNavigate();

  return (
    <Container className="mt-5">
      <Grid container className = "items-center">
        {allBrands?.map((obj, index) => {
          return obj.second_name ||
            (obj.logo &&
              obj.logo !== "[object Object]" &&
              obj.logo !== "null") ? (
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={index}>
              <div className="py-10 cursor-pointer">
                {obj.logo &&
                obj.logo !== "[object Object]" &&
                obj.logo !== "null" ? (
                  <img
                    className="brands-image"
                    src={obj.logo}
                    onClick={() => navigate(`/inventory-page?brand=` + obj?.id + `&brandname=` + obj.name)}
                    alt="brand"
                  />
                ) : (
                  <span className="font-bold text-[18px] font-montserrat" onClick={() => navigate(`/inventory-page?brand=` + obj?.id + `&brandname=` + obj.name)}>
                    {obj.second_name}
                  </span>
                )}
              </div>
            </Grid>
          ) : (
            ""
          );
        })}
      </Grid>
    </Container>
  );
};

export default BrandsList;
