import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { debounce } from "lodash";

import "./price_slider.scss";
import { useCallback } from "react";

export default function PriceSlider(props) {
    const [ isOpenState, setIsOpenState ] = useState(false)
    const [ sliderMaxValue, setSliderMaxValue ] = useState(10000)
    const [ sliderMinValue, setSliderMinValue ] = useState(0)

    const trackRef = useRef(0)
    const minTrackRef = useRef(0)
    const maxTrackRef = useRef(0)
    const minInputRef = useRef(0)
    const maxInputRef = useRef(0)
    const { handleFilterChange } = props;

    useEffect(() => {
        const minPercent = getPercent(sliderMinValue);
        const maxPercent = getPercent(sliderMaxValue);

        trackRef.current.style.left = `${minPercent}%`;
        trackRef.current.style.width = `${maxPercent - minPercent}%`;
    } , [sliderMaxValue, sliderMinValue])

    useEffect(() => {
        handleFilterChange(sliderMinValue, sliderMaxValue)
    }, [sliderMinValue, sliderMaxValue])

    const getPercent = value => {
        return Math.round((value / 10000) * 100);
    }

    const handleTabClick = () => {
        setIsOpenState(!isOpenState)
    }

    const debouncedHandleMinValueChange = debounce(event => {
        handleMinValueChange(event)
    }, 500)

    const handleMinValueChange = event => {
        let value = event.target.value;
        value = Math.min(value, parseInt(sliderMaxValue) - 1);
        setSliderMinValue(value)
        minInputRef.current.value = value;
    }

    const debouncedHandleMaxValue = debounce(event => {
        handleMaxValueChange(event)
    }, 500)

    const handleMaxValueChange = event => {
        let value = event.target.value;
        value = Math.max(value, parseInt(sliderMinValue) + 1);
        setSliderMaxValue(value)
        maxInputRef.current.value = value;
    }

    const debouncedHandleMinInput = debounce(event => {
        handleMinInput(event)
    }, 1000)

    const handleMinInput = event => {
        const value = event.target.value;
        if(value < 0) {
            setSliderMinValue(0)
            minTrackRef.current.value = 0;
        }
        else if(value < sliderMaxValue) {
            setSliderMinValue(value)
            minTrackRef.current.value = value;
        }
        else {
            setSliderMinValue(sliderMaxValue - 1)
            minTrackRef.current.value = sliderMaxValue - 1;
        }
    }

    const debouncedHandleMaxInput = debounce(event => {
        handleMaxInput(event)
    }, 1000)

    const handleMaxInput = event => {
        const value = event.target.value;
        if(value > 10000) {
            setSliderMaxValue(10000)
            maxTrackRef.current.value = 10000;
        }
        else if(value > sliderMinValue) {
            setSliderMaxValue(value)
            maxTrackRef.current.value = value;
        }
        else {
            setSliderMaxValue(sliderMinValue + 1)
            maxTrackRef.current.value = sliderMinValue + 1;
        }
    }

    return(
        <div className = "flex flex-col w-full">
            <div className = {`w-full box-border py-[17px] flex justify-between items-center cursor-pointer`} onClick = {() => handleTabClick()}>
                <p className = "font-montserrat font-semibold text-[16px]">Prijs</p>
                <div className = "animated" style = {{transform: isOpenState ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                    <IoIosArrowDown color = "#C98468" size = "1.25rem" />
                </div>
            </div>
            <div className = "w-full flex flex-col overflow-hidden animated" style = {{opacity: isOpenState ? 1 : 0, maxHeight: isOpenState ? '1000px' : '0px'}}>
                <div className = "w-full h-[35px] mt-[10px] relative">
                    <input style={{zIndex: sliderMinValue > 9500 ? 3: 1}} type = "range" className = "price-slider-range-one" defaultValue = {0} ref = {minTrackRef} min = "1" max = "10000" onChange = {event => debouncedHandleMinValueChange(event)} />
                    <input style={{zIndex: 2}} type = "range" className = "price-slider-range-two" defaultValue = {10000} ref = {maxTrackRef} min = "1" max = "10000" onChange = {event => debouncedHandleMaxValue(event)} />
                    <div className = "h-[5px] w-full rounded-[5px] bg-[#D0CCC6] opacity-[0.34] absolute" />
                    <div className = "h-[5px] w-full rounded-[5px] bg-[#D0CCC6] absolute" ref = {trackRef} />
                </div>
                <div className = "w-full flex flex-row justify-between items-center">
                    <div className = "flex flex-col">
                        <p className = "font-montserrat font-semibold text-[12px] text-[#27292C]">VAN</p>
                        <div className = "box-border h-[35px] w-[90px] flex items-center justify-center border-[1px] border-[#B96A4B] bg-white mt-[4px]">
                            <p className = "font-montserrat font-medium text-[12px] text-[#404040] mr-[5px]">€</p>
                            <input type = "number" className = "font-montserrat font-medium text-[12px] max-w-[35px] text-[#404040] bg-transparent outline-none price-slider-input" defaultValue = {0} ref = {minInputRef} onChange = {debouncedHandleMinInput} />
                        </div>
                    </div>
                    <div className = "flex flex-col">
                        <p className = "font-montserrat font-semibold text-[12px] text-[#27292C]">TOT</p>
                        <div className = "box-border h-[35px] w-[90px] flex items-center justify-center border-[1px] border-[#B96A4B] bg-white mt-[4px]">
                            <p className = "font-montserrat font-medium text-[12px] text-[#404040] mr-[5px]">€</p>
                            <input type = "number" className = "font-montserrat font-medium text-[12px] max-w-[45px] text-[#404040] bg-transparent outline-none price-slider-input" defaultValue = {10000} ref = {maxInputRef} onChange = {debouncedHandleMaxInput} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
