import React, { Component } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import {Container} from "@mui/material";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import StickyButton from "../maakeenafspraak_sticky_button/sticky_button";

class ControlledEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
        };
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    render() {
        const { editorState } = this.state;
        return (
            <>
                <StickyButton />
                <Container maxWidth="lg">
                    <div style={{ border: '1px solid #e1e1e1', padding: '0 10px', marginTop: 50 }}>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={{
                                options: ['inline', 'link'],
                                inline: {
                                    inDropdown: false,
                                    options: ['bold', 'italic', 'underline'],
                                },
                                link: {
                                    options: ['link'],
                                },
                            }}
                        />
                    </div>
                </Container>
            </>
        )
    }
}

export default ControlledEditor;
