import React, { useState, useEffect } from "react";
import { Container } from '@mui/material';

import FooterLowerDesktop from "./footer_lower_desktop";
import FooterLowerMobile from "./footer_lower_mobile";
import { getAllFooterData}  from "../footer.api";

import "../footer.scss";

const FooterLower = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [isFetching, setIsFetching] = useState(false);
    const [footerData, setFooterData] = useState([]);

    const breakpoint = 1024;

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        getAllFooterData(setIsFetching, setFooterData);

        return () => window.removeEventListener("resize", resizeHandler);
    }, []);

    const resizeHandler = event => {
        setWidth(window.innerWidth)
    }

    return(
        <div id="footer-lower">
            <Container maxWidth = "lg">
                {(width < breakpoint)
                    ? <FooterLowerMobile footerData={footerData} />
                    : <FooterLowerDesktop width={width} footerData={footerData} />
                }
            </Container>
        </div>
    )
};

export default FooterLower;
