import React from 'react';
import PropTypes from "prop-types";

import LoginPage from './login';

const Login = (props) => {
    const { navigationPath } = props;

    return (
        <LoginPage navigationPath = {navigationPath} />
    )
}

export default Login;

Login.defaultProps = {
    navigationPath: null
}

Login.propTypes = {
    navigationPath: PropTypes.string
}