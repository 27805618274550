import React, { useRef } from "react";

import "./rounded_button.scss";

export default function RoundedButton({
  id,
  title,
  btnClass,
  handler,
  fileHandler,
  disabled,
  type,
}) {
  const hiddenFileInput = useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };
  return (
    <div className="button-wrapper">
      <button
        disabled={disabled}
        className={`button-1 ${btnClass}`}
        onClick={type === "file" ? handleClick : handler}
      >
        {title}
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={fileHandler}
        style={{ display: "none" }}
        id={id}
      />
    </div>
  );
}
