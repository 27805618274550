import { ALL_PRODUCTS } from "../constants/constants";
const INIT_STATE = {
	all_products: [],
};
const product = (state = INIT_STATE, action) => {
	switch (action.type) {
		case ALL_PRODUCTS:
			return {
				...state,
				all_products: action.payload,
			};

		default:
			return state;
	}
};

export default product;
