import React, { createRef, useRef, useState} from 'react';
import { Grid, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import SubmitButton from '../../../components/submit_section';
import RadioGroup from '../../../components/inputs/radio_group';
import Delivery from './delivery';
import Pickup from './pickup';
import StickyButton from "../../../components/maakeenafspraak_sticky_button/sticky_button";

const DeliveryPickup = (props) => {
    const [rbState, setRBState] = useState('LEVERING');
    const [ suggestionState, setSuggestionState ] = useState("");

    const { setDeliveryData } = props;
    const deliveryRef = useRef(null);
    const pickupRef = useRef(0);
    const navigate = useNavigate();

    // const Suggestions = () => {
    //     return (
    //         <div className='font-montserrat font-semibold'>
    //             <div className='text-[#27292C] text-[14px] mb-[8px]'>
    //                 OPMERKINGEN, SUGGESTIES?
    //             </div>
    //             <textarea rows="5"  className='resize-none border-[#676666] border-[2px] w-full py-[14px] px-[18px] font-mont' type='text' value = {suggestionState} onChange = {event => setSuggestionState(event.target.value)} placeholder='Schrijf uw bericht hier'/>
    //         </div>
    //     )
    // }

    const handleSubmit = () => {
        let success = true;
        if(rbState === "LEVERING") {
            let deliveryData = deliveryRef.current.getDeliveryData();
            if(deliveryData) {
                deliveryData = {...deliveryData, suggestions: suggestionState, delivery: true };
                setDeliveryData(deliveryData)
            }
            else {
                success = false;
            }
        }
        else {
            let pickupData = pickupRef.current.getLocation();
            if(pickupData) {
                pickupData = { outlet_id: pickupData, suggestions: suggestionState, delivery: false };
                setDeliveryData(pickupData)
            }
            else {
                success = false;
            }
        }
        if(success) {
            navigate("/checkout/confirmation")
        }
    }

    return (
        <div>
            <StickyButton />
            <Grid container justifyContent= "center" alignItems = "center">
                <Container maxWidth = "lg">
                    <div className='w-full text-center'>
                        <div className='text-[35px] text-[#404040] font-dmserif my-[40px]'>
                            Afhaling/Levering
                        </div>
                    </div>

                        <RadioGroup
                            rbMobileProp={true}
                            rbState={rbState}
                            setRBState={setRBState}
                            labels={['AFHALING', 'LEVERING']}
                            labelProps='font-semibold ml-[2px]'
                            styleProps='mr-[40px] text-[14px] text-[#27292C] text-center'
                            spaceBetween='||||||'
                            spaceAfter='||||||||||||||||'
                            spaceBefore='||||||'
                        />
                        <div className='mt-[70px]'>
                            {rbState === 'LEVERING' ? <Delivery ref = {deliveryRef} /> : <Pickup ref = {pickupRef} />}
                        </div>
                        <div className='font-montserrat font-semibold'>
                            <div className='text-[#27292C] text-[14px] mb-[8px]'>
                                OPMERKINGEN, SUGGESTIES?
                            </div>
                            <textarea rows="5"  className='resize-none border-[#676666] border-[2px] w-full py-[14px] px-[18px] font-mont' type='text' value = {suggestionState} onChange = {event => setSuggestionState(event.target.value)} placeholder='Schrijf uw bericht hier'/>
                        </div>
                        <SubmitButton mtSubmitSection='60px' link='/checkout/confirmation' onClick = {handleSubmit} alternative />
                </Container>
            </Grid>
        </div>
    )
}

export default DeliveryPickup;

DeliveryPickup.defaultProps = {
    setDeliveryData: () => {}
}

DeliveryPickup.propTypes = {
    setDeliveryData: PropTypes.func
}
