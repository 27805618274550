import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import LockModal from '../../../components/lock_modal/lock_modal';

import './login.scss';
import axios from "axios";
import {BASE_URL, forgot_password} from "../../../redux/constants/ApiEndPoints";
import { trim } from 'lodash';
import { REGEX } from '../../../constants/constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ForgetPassword = (props) => {
    const {forgotPasswordModalState, setForgotPasswordModalState} = props;
    const navigate = useNavigate();

    const handleForgotPasswordModalClose = () => {
        forgotForm.resetForm();
        setForgotPasswordModalState(false)
    }

    const forgotForm = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().matches(REGEX.EMAIL, { message: "Ongeldig e-mailadres" }).required("Verplicht"),
        }),
        onSubmit: (values) => {
            axios.post(BASE_URL + forgot_password, {
                ...values,
                type: 1
            }).then(response => {
                navigate(0)
            }).catch(error => console.log(error))
        },
    });

    return (
        <form onSubmit={forgotForm.handleSubmit}>
            <LockModal heading="Wachtwoord vergeten" subheader="Geef hier het e-mailadres in dat is gekoppeld aan uw top interieur-account." buttonText="Email versturen" openState={forgotPasswordModalState} onExit={handleForgotPasswordModalClose} onClick={forgotForm.handleSubmit} subTextClass="text-center text-[14px] mb-[15px]">
                <div className="flex flex-col w-full my-[17px]">
                    <p className="font-[Montserrat] text-[14px] text-black font-semibold">EMAIL</p>
                    <input className="h-[41px] w-full border-[1px] border-[black] box-border rounded-[3px] indent-[15px] font-[Montserrat] text-[14px] text-black font-medium" name="email" value={forgotForm.values.email} onChange={forgotForm.handleChange}
                        style={{ borderColor: (forgotForm.errors.email && forgotForm.errors.email) ? 'red' : 'black' }} />
                </div>
            </LockModal>
        </form>
    )
}
export default ForgetPassword;
