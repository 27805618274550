import { useState } from "react";

const useBreadCrumb = () => {
    const [expanded, setExpanded] = useState(true);

    const open = () => setExpanded(true);

    return {
        expanded,
        open
    };
};

export default useBreadCrumb;
