import React, { useState, useEffect, useCallback } from "react";
import { Grid, Container } from "@mui/material";
import HTMLFlipBook from "react-pageflip";
import PropTypes from "prop-types"
import axios from "axios";
import { BASE_URL, getPdfImages } from "../../redux/constants/ApiEndPoints";
import { IoMdClose } from "react-icons/io";

import "./brochures.scss";


export default function Brochures(props) {
    // size represents each page size, so 500x500 would be 1000x500 since two pages get rendered side by side
    // size should always be less than: size * 2 < screen width
    const [ flipbookSizeState, setFlipbookSizeState ] = useState({width: 550, height: 700})
    const [ pdfImages, setPdfImages ] = useState([])
    const [ renderLimit, setRenderLimit ] = useState(4);
    const { brochureId, onClose } = props;

    useEffect(() => {
        window.addEventListener("resize", flipbookSize);

        return () => {
            window.removeEventListener("resize", flipbookSize)
        }
    }, [])

    useEffect(() => {
        flipbookSize();
    }, [])

    useEffect(() => {
        if(brochureId?.id) {
            axios.get(BASE_URL + getPdfImages + brochureId?.id).then(response => {
                const imageData = response?.data?.data;
                setPdfImages(imageData)
                if(response?.data?.data?.length > 4) {
                    // first render only renders image up to 4th index
                    setRenderLimit(4)
                }
                else {
                    setRenderLimit(500)
                }
            }).catch(error => console.log(error))
        }
        
    }, [brochureId])

    const flipbookSize = () => {
        const windowWidth = window.screen.width;
        if(windowWidth >= 1280) {
            setFlipbookSizeState({width: 496, height: 702});
        }
        else if(windowWidth >= 1024) {
            setFlipbookSizeState({width: 447, height: 632});
        }
        else if(windowWidth >= 768) {
            setFlipbookSizeState({width: 306, height: 443});
        }
        else if(windowWidth >= 640){
            // for mobile devices below, set it based on their screen width so they get one page view
            setFlipbookSizeState({width: 447, height: 632});
        }
        else {
            setFlipbookSizeState({width: 331, height: 468});
        }
    }

    const onPageFlip = useCallback(event => {
        if(event.data + 2 < (pdfImages?.length - 1)) {
            // increase render limit by two on page flip
            setRenderLimit(renderLimit + 2);
            if(event.data + 2 === renderLimit) {
                // validation to only increase the render limit when we are right before the pages that need to be rendered 
                setRenderLimit(renderLimit + 2);
            }
        }
        else {
            // assuming there are less than 4 pages or we're nearing the end of the brochure, there is no need to limit rendering since all pages
            // should have rendered by now
            setRenderLimit(500)
        }
    })

    return(
        <div className = "w-screen h-screen fixed top-0 left-0 flex justify-center items-center z-[1000]" style = {{display: brochureId ? "flex" : "none"}}>
            <div className = "h-full w-full absolute top-0 left-0 bg-[#000000]/[0.4]" onClick = {onClose} />
            <Container maxWidth = "lg" className="relative">
                <div className = "box-border p-[25px] pt-10 bg-white flex justify-center items-center relative">
                    <div className = "absolute right-[10px] top-[10px] cursor-pointer flex justify-center items-center cursor-pointer" onClick = {onClose}>
                        <IoMdClose size = "1.5em" />
                    </div>
                    {pdfImages && pdfImages?.length > 1 ? (
                        <HTMLFlipBook height = {flipbookSizeState.height} width = {flipbookSizeState.width} onFlip={onPageFlip}>
                            {pdfImages?.map((pdfImage, imageIndex) => {
                                return imageIndex < renderLimit ? <img key={`b-img-${imageIndex}`} className = "demo-page-1 object-contain" src = {pdfImage?.url} alt = "furniture" /> : <img key={`b-img-${imageIndex}`}/>
                            })}
                        </HTMLFlipBook>
                    ) : (
                        <img className = "object-contain h-full" src = {pdfImages[0]?.url} alt = "grondplan" />
                    )}
                </div>
            </Container>
        </div>
    )
}

Brochures.propTypes = {
    brochureId: PropTypes.object,
    onClose: PropTypes.func
}

Brochures.defaultProps = {
    brochureId: null,
    onClose: () => {}
}
