import React, { useEffect, useState } from 'react';
import { Grid, Container } from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import axios from "axios";
import { BASE_URL, getUserOrder } from '../../../redux/constants/ApiEndPoints';
import TPLogo from "../../../images/brand_logos/top_interieur.svg"

import './orders.scss';

const Orders = ({
    items = [{
        name:"name Of The Product",
        description: "Short description of 2 lines maximum lore Epsom dolor",
        code: "10A-08500",
        price: "2455,50",
        quantity:"1",
        img:"https://grandinteriors.com.pk/wp-content/uploads/2021/02/f1cd1b9a-964f-46ad-a18f-8fecb029daa9.jpg",
        text:"Commande en cours de traitement, un de nos vendeurs vous contactera très bientôt"
    },
    {
        name:"name Of The Product",
        description: "Short description of 2 lines maximum lore Epsom dolor",
        code: "10A-08500",
        price: "2455,50",
        quantity:"1",
        img:"https://grandinteriors.com.pk/wp-content/uploads/2021/02/f1cd1b9a-964f-46ad-a18f-8fecb029daa9.jpg",
        text:"Commande validée, en attente de livraison. Livraison prévue le XX/XX/XXXX"
    }],history=[{    
        name:"name Of The Product",
        description: "Short description of 2 lines maximum lore Epsom dolor",
        code: "10A-08500",
        price: "2455,50",
        quantity:"1",
        img:"https://grandinteriors.com.pk/wp-content/uploads/2021/02/f1cd1b9a-964f-46ad-a18f-8fecb029daa9.jpg",
        text:"Commande livrée le XX/XX/XXXX"
    }]
}) => {
    const [ orderData, setOrderData ] = useState([])

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const userData = JSON.parse(localStorage.getItem('user-data'))

    useEffect(() => {
        if(userData?.id) {
            axios.get(BASE_URL + getUserOrder + userData?.id, {headers}).then(response => {
                setOrderData(response?.data?.data)
            }).catch(error => console.log(error))
        }
    }, [])

    const getItemPrice = (cartItem) => {
        if(cartItem?.product_id) {
            return cartItem?.product?.promotion_price || cartItem?.product?.price || 0;
        }
        else {
            return cartItem?.sale_item?.new_sales_price || cartItem?.sale_item?.original_sales_price || 0;
        }
    }

    const OrderDetail = ({content}) => {
        const [openDetailState, setOpenDetailState] = useState(false)

        const getTotalProducts = () => {
            let count = 0;
            if(content?.order_details?.length > 0) {
                content?.order_details?.forEach(product => {
                    count += product?.quantity;
                })
            }
            return count;
        }

        const getTotal = () => {
            let totalPrice = 0;
            if(content?.order_details?.length > 0) {
                content?.order_details?.forEach(orderItem => {
                    totalPrice += (getItemPrice(orderItem) * orderItem?.quantity || 1);
                })
                return totalPrice.toFixed(2);
            }
            else {
                return 0;
            }
        }

        const handleOrderClick = () => {
            setOpenDetailState(!openDetailState)
        }

        return(
            <div className = "relative md:bg-transparent bg-[#F7F6F5] mb-[15px]">
                <div className='grid_8_1 mb-[10px] h-auto md:h-[150px] cursor-pointer' onClick = {handleOrderClick}>
                    <div className = "bg-[#F7F6F5] flex-col box-border py-[15px] flex md:hidden">
                        <div className = "flex w-full justify-between box-border px-[15px] my-[15px]">
                            <div className='flex flex-1'>
                                <p className = "font-[Montserrat] font-bold text-[16px] text-[#404040]">{content?.id}</p>
                                <div className='md:hidden ml-[15px]'>
                                    {/* <InformationIcon/> */}
                                </div>
                            </div>
                            <div className = "flex flex-1 justify-end">
                                <div className = "flex flex-col">
                                    <div className='font-[Montserrat] font-bold text-[#404040] text-[15px]'>{!content?.delivery ? 'Afhaling' : 'Levering'}</div>
                                    {content?.delivery ? (
                                        <>
                                            <p className = "font-[Montserrat] font-regular text-[13px] text-[#404040]">{content?.address && content?.address?.length > 0 ? `${content?.address[0]?.street_1} ${content?.address[0]?.number_1}, ${content?.address[0]?.number_2}` : ""}</p>
                                            <p className = "font-[Montserrat] font-regular text-[13px] text-[#404040]">{content?.address && content?.address?.length > 0 ? `${content?.address[0]?.zip_code} ${content?.address[0]?.city}` : ""}</p>
                                        </>
                                    ) : (
                                        <>
                                            <p className = "font-[Montserrat] font-regular text-[13px] text-[#404040]">{content?.outlet?.street} {content?.outlet?.number}<br/> {content?.outlet?.post_code} {content?.outlet?.city}<br/> {content?.outlet?.province}</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className = "flex w-full justify-between items-center box-border px-[15px] mt-[10px]">
                            <div className = "flex flex-col">
                                <p className = "font-[Montserrat] font-bold text-[13px] text-[#27292C]">Aantal</p>
                                <p className = "font-[Montserrat] font-bold text-[13px] text-[#27292C]">{getTotalProducts()}</p>
                            </div>
                            {/* <div className = "h-[22px] w-[1px] bg-[#D0CCC6]" />
                            <div className = "flex flex-col">
                                <p className = "font-[Montserrat] font-bold text-[13px] text-[#27292C]">Totaal excl. btw</p>
                                <p className = "font-[Montserrat] font-semibold text-[15px] text-[#C98468]">€ {getTotalWithoutTax()}</p>
                            </div>
                            <div className = "h-[22px] w-[1px] bg-[#D0CCC6]" /> */}
                            <div className = "flex flex-col">
                                <p className = "font-[Montserrat] font-bold text-[13px] text-[#27292C]">Totaal incl. btw</p>
                                <p className = "font-[Montserrat] font-semibold text-[15px] text-[#C98468]">€ {getTotal()}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex py-[20px] bg-[#F7F6F5] border-b-[1px] border-b-[#ececec] hidden md:flex'>
                        <div className='flex flex-1 items-center justify-center'>
                            <div className='img_acc_page_mb flex justify-center items-center'>
                                <p className = "font-[Montserrat] font-bold text-[16px] text-[#404040]">{content?.id}</p>
                            </div>
                            <div className='info_icon_parent_acc_page_mb hidden md:block'>
                                {/* <InformationIcon/> */}
                            </div>
                        </div>
                        <div className='flex-1 flex flex-col mt-[35px]'>
                            <p className = "font-[Montserrat] font-bold text-[14px] text-[#404040]">Aantal producten</p>
                            <p className = "font-[Montserrat] font-bold text-[14px] text-[#404040]">{getTotalProducts()}</p>
                        </div>
                        <div className='flex flex-1 flex-col mt-[35px]'>
                            {/* <p className = "font-[Montserrat] font-bold text-[14px] text-[#404040]">Totaal excl. btw</p>
                            <p className = "font-[Montserrat] font-bold text-[14px] text-[#C98468]">€ {getTotalWithoutTax()}</p> */}
                        </div>
                        <div className='flex flex-col flex-1 mt-[35px]'>
                            <p className = "font-[Montserrat] font-bold text-[14px] text-[#404040]">Totaal incl. btw</p>
                            <p className = "font-[Montserrat] font-bold text-[14px] text-[#C98468]">€ {getTotal()}</p>
                        </div>
                        <div className='flex flex-col flex-1 mt-[35px]'>
                            <p className = "font-[Montserrat] font-bold text-[14px] text-[#404040]">{!content?.delivery ? 'Afhaling' : 'Levering'}</p>
                            {content?.delivery ? (
                                <>
                                    <p className = "font-[Montserrat] font-regular text-[13px] text-[#404040]">{content?.address && content?.address?.length > 0 ? `${content?.address[0]?.street_1} ${content?.address[0]?.number_1}, ${content?.address[0]?.number_2}` : ""}</p>
                                    <p className = "font-[Montserrat] font-regular text-[13px] text-[#404040]">{content?.address && content?.address?.length > 0 ? `${content?.address[0]?.zip_code} ${content?.address[0]?.city}` : ""}</p>
                                </>
                            ) : (
                                <>
                                    <p className = "font-[Montserrat] font-regular text-[13px] text-[#404040]">{content?.outlet?.street} {content?.outlet?.number}<br/> {content?.outlet?.post_code} {content?.outlet?.city}<br/> {content?.outlet?.province}</p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='text_acc_page_mb bg-[#C98468] font-semibold rounded-r-[10px] text-[13px] text-[white] vertical_center_acc_page pr-[48px] pl-[48px] md:flex flex-1 hidden'>
                        {content?.status?.description || "Commande en cours de traitement, un de nos vendeurs vous contactera très bientôt"}
                    </div>
                    <div id="popup1" className="overlay z-[1012]">
                        <div className="popup_account_page_mb">
                            <a className="close" href="#"><CancelOutlinedIcon fontSize="large" className='h-[100px] w-auto' /></a>
                            <div className="content"> 
                                {content?.status?.description || "Commande en cours de traitement, un de nos vendeurs vous contactera très bientôt"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className = "w-full relative animated max-h-[1500px]" style = {{opacity: openDetailState ? 1 : 0, maxHeight: openDetailState ? '1500px' : '0px'}} >
                    {content && content?.order_details?.map((orderItem, index) =><TableRow key={`detail-item-${index}`} content={orderItem}/>)}
                </div>
                <div className = "w-full rounded-b-[10px] bg-[#C98468] h-[75px] flex justify-center items-center md:hidden">
                    <p className = "font-[Montserrat] font-semibold text-[13px] text-white">{content?.status?.description || "Commande en cours de traitement, un de nos vendeurs vous contactera très bientôt"}</p>
                </div>
            </div>
        )
    }

    const TableRow = ({content, show}) => {
        const getTotal = () => {
            return getItemPrice(content) * content?.quantity || 1
        }

        return (
            <div className='grid_8_1 mb-[10px] animated'>
                <div className='flex py-[10px] bg-[#F7F6F5] border-t-[1px] border-t-[#ececec] md:border-t-[0px] md:border-b-[1px] md:border-b-[#ececec]'>
                    {/* image section, desktop version */}
                    <div className='flex flex-1 h-full box-border hidden md:flex'>
                        <div className = "w-full flex justify-center items-center">
                            <img alt="" className = {`w-[80%] h-full ${content?.product_id ? "object-cover" : "object-contain"} object-center`} src={content?.product?.main_image ? "https://tp-storage.s3.fr-par.scw.cloud/import" + content?.product?.main_image : TPLogo}/>
                        </div>
                        <div className='info_icon_parent_acc_page_mb'>
                            {/* <InformationIcon/> */}
                        </div>
                    </div>
                    {/* description part, desktop version */}
                    <div className='flex justify-between flex-1 md:mt-[15px] hidden md:flex'>
                        <div className = "flex flex-col w-full max-w-[90%]">
                            <div className='font-[Montserrat] text-[14px] text-[#404040] font-bold hidden md:block'>Beschrijving</div>
                            <div className='font-[Montserrat] text-[14px] text-[#404040] hidden md:block'>{content?.product?.item_description || content?.sale_item?.card_id || ""}</div>
                        </div>
                    </div>
                    {/* quantity, desktop version */}
                    <div className = "flex-col flex-1 md:mt-[15px] hidden md:flex">
                        <div className='font-[Montserrat] text-[14px] text-[#404040] font-bold hidden md:block'>Aantal</div>
                        <div className='font-[Montserrat] text-[14px] text-[#404040] font-bold hidden md:block'>{content?.quantity}</div>
                    </div>
                    {/* price, desktop version */}
                    <div className = "flex-col flex-1 md:mt-[15px] hidden md:flex">
                        <div className='font-[Montserrat] text-[14px] text-[#404040] font-bold hidden md:block'>Eenheids Prijs</div>
                        <div className='font-[Montserrat] text-[14px] text-[#C98468] font-bold hidden md:block'>€ {getItemPrice(content)}</div>
                        <div className='font-[Montserrat] text-[14px] text-[#404040] font-bold hidden md:block'>Totaal</div>
                        <div className='font-[Montserrat] text-[14px] text-[#C98468] font-bold hidden md:block'>€ {getTotal()}</div>
                    </div>
                    {/* empty space, desktop version */}
                    <div className = "flex flex-1 shrink-0 hidden md:flex" />
                    <div className = "flex w-full h-full box-border px-[10px] md:hidden">
                        {/* image and price column, mobile version */}
                        <div className = "flex flex-col flex-1">
                            <img alt= "" className = {`w-[90%] h-[100px] ${content?.product_id ? "object-cover" : "object-contain"} object-center`} src={content?.product?.main_image ? "https://tp-storage.s3.fr-par.scw.cloud/import" + content?.product?.main_image : TPLogo}/>
                            <div className = "flex flex-col mt-[10px]">
                                <p className = "font-[Montserrat] font-bold text-[12px] text-[#404040]">Eenheids prijs</p>
                                <p className = "font-[Montserrat] font-bold text-[12px] text-[#C98468]">€ {getItemPrice(content)}</p>
                            </div>
                        </div>
                        <div className = "flex flex-col flex-1 justify-between h-[146px]">
                            <div className = "flex flex-col">
                                <p className = "font-[Montserrat] font-bold text-[12px] text-[#404040]">Beschrijving</p>
                                <p className = "font-[Montserrat] font-regular text-[12px] text-[#404040]">{content?.product?.item_description}</p>
                            </div>
                            <div className = "flex flex-col">
                                <div className = "flex flex-col mt-[10px]">
                                    <p className = "font-[Montserrat] font-bold text-[12px] text-[#404040]">Aantal</p>
                                    <p className = "font-[Montserrat] font-bold text-[12px] text-[#404040]">{content?.quantity}</p>
                                </div>
                                <div className = "flex flex-col mt-[10px]">
                                    <p className = "font-[Montserrat] font-bold text-[12px] text-[#404040]">Totaal</p>
                                    <p className = "font-[Montserrat] font-bold text-[12px] text-[#C98468]">€ {getTotal()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text_acc_page_mb bg-[#C98468] font-semibold rounded-[5px] text-[13px] text-[white] vertical_center_acc_page pr-[48px] pl-[48px] opacity-0 pointer-events-none'>
                    {content?.text || "Commande en cours de traitement, un de nos vendeurs vous contactera très bientôt"}
                </div>
                <div id="popup1" className="overlay">
                    <div className="popup_account_page_mb">
                        <a className="close" href="#"><CancelOutlinedIcon fontSize="large" className='h-[100px] w-auto' /></a>
                        <div className="content"> 
                            {content?.text || "Commande en cours de traitement, un de nos vendeurs vous contactera très bientôt"}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    const TabContent = () => {
        const showOrderHistory = () => {
            if(orderData && orderData?.length > 0) {
                let count = 0;
                orderData?.forEach(orderItem => {
                    if(orderItem?.order_status === 3) {
                        count++;
                    }
                })
                return count > 0;
            }
            else {
                return false;
            }
        }
        
        return (
            <div className='font-montserrat'>
                <div>
                    <div className='text-[22px] mb-[24px]'>
                        Orders in behandeling
                    </div>
                    <div>
                        {orderData && orderData?.map((orderItem, index) => {
                                return orderItem?.order_status !== 3 && (<OrderDetail key={`order-detail-${index}`} content={orderItem}/>)
                            })
                        }
                    </div>          
                </div>
                <div>
                    {showOrderHistory() && (
                        <div className='opacity-50 mt-[40px] mb-[35px]'>
                            <div className='text-[22px] mb-[24px]'>
                                Geschiedenis
                            </div>
                            <div>
                                {orderData && orderData?.map((orderItem, index) => {
                                        return orderItem?.order_status === 3 && (<OrderDetail key={`order-detail-h-${index}`} content={orderItem}/>)
                                    })
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const InformationIcon = () => {
        return (
            <div className ="box">
                <a className ="button" href="#popup1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        fill="#C98468"
                        width="14px" height="14px"
                        className='info_icon_acc_page_mb'
                        viewBox="0 0 416.979 416.979"
                        xmlSpace="preserve"
                    >
                        <g>
                            <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85
                                c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786
                                c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576
                                c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765
                                c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
                            />
                        </g>
                    </svg>
                </a>
            </div>
        )
    }

    return (
        <div>
            <Grid container justifyContent= "center" alignItems = "center">
                <TabContent/>
            </Grid>
        </div>
    )
}

export default Orders;