import React from "react";
import ProductDetails from "../../components/product_details/index";
import StickyButton from "../../components/maakeenafspraak_sticky_button/sticky_button";

const Details = () => {
  return (
    <div>
        <StickyButton />
        <ProductDetails />
    </div>
  );
};

export default Details;
