import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dummy from "../../assets/images/rooms/dummy.jpg";
import TPLogo from "../../images/brand_logos/top_interieur.svg";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import axios from "axios";
import { BASE_URL, updateWishlist, deleteWishList, createWishlist } from "../../redux/constants/ApiEndPoints";
import "./sales.scss";

const SalesCard = ({ product, currentPage, onDelete, wishlist, isFavourite }) => {
  const [urlImage, setUrlImage] = useState("");
  const [wishlistObject, setWishlistObject] = useState(null);

  const imageBaseUrl = "https://tp-storage.s3.fr-par.scw.cloud/import";
  const userData = JSON.parse(localStorage.getItem("user-data"))
  const navigate = useNavigate();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  useEffect(() => {
    if (product?.picture) {
      let fullUrl =
        imageBaseUrl +
        product.picture.replace(/\\/g, "/").replace(/\s/g, "%20");
      setUrlImage(fullUrl);
    } else setUrlImage(TPLogo);
  }, [product]);

  const handleFavoriteClick = () => {
    if (product && userData?.id) {
      if (wishlistObject) {
        axios
          .put(BASE_URL + updateWishlist + wishlistObject?.id, {
            is_favourite: !wishlistObject?.is_favourite,
          }, {headers})
          .then((response) => {
            setWishlistObject(response.data.data);
          })
          .catch((error) => console.log(error));
      } else {
        let doesWishlistExist = false;
        let wishlistItem = {};
        if(product?.wishlist) {
          // if the product contains the wishlist data, then go through this condition
          doesWishlistExist =
          product?.wishlist?.length > 0 &&
          product?.wishlist[0]?.user_id === userData?.id
            ? true
            : false;
            wishlistItem = product?.wishlist[0]
        }
        else {
          // if product does not contain wishlist data then manually search through wishlist object for the product
          wishlist.forEach(item => {
            if(item?.sale_item_id === product?.id && item?.is_favourite) {
              doesWishlistExist = true;
              wishlistItem = {...item};
            }
          })
        }
        if (doesWishlistExist) {
          // if product exist using either of the two methods above then simply then remove it from the data
          axios
            .delete(BASE_URL + deleteWishList + wishlistItem?.id, {headers}).then((response) => {
              setWishlistObject([]);
              onDelete();
            }).catch((error) => console.log(error));
        } else {
          // if product does not exist using either of the two moethods above then add it to the list
          axios
            .post(BASE_URL + createWishlist, {
              user_id: userData?.id,
              sale_item_id: product?.id,
              type: 2
            }, {headers})
            .then((response) => {
              setWishlistObject(response.data.data);
            })
            .catch((error) => console.log(error));
        }
      }
    }
    else {
      navigate("/info")
    }
  };

  const displayFavoriteIcon = () => {
    if (wishlistObject) {
      return wishlistObject?.is_favourite;
    }
    else if(isFavourite){
      return isFavourite;
    }
    else {
      if (product?.wishlist?.length > 0 && product?.wishlist[0]?.user_id === userData?.id) {
        return product?.wishlist[0]?.is_favourite;
      } else {
        return false;
      }
    }
  };

  const onCardClick = () => {
    navigate(`/product-details/sale/${product.id}`)
  }

  if (product)
    return (
      <div id="inventory-card" className = "w-[100%]">
        <div className="inventory-item pl-[15px] pr-[15px] cursor-pointer" onClick = {onCardClick}>
          <div className="item-hover">
            <div className="image-section">
              <div className="flex">
                <div className="w-4/5 pt-[5px] pb-[5px] pl-[15px] pr-[5px]">
                  <img className = "h-[185px] object-contain object-center" alt = "main image" src = {urlImage} />
                </div>
              </div>
            </div>
          </div>
          <div className="item" style={{ backgroundColor: 'rgba(208, 204, 198, 0.6)' }}>
            <img className = "h-[140px] object-contain object-center" alt = "main image" src = {urlImage} />
          </div>
        </div>
        <div className="pl-[15px] pr-[15px]">
          <div className="description-section mt-[30px]" >
            <div className = "w-full cursor-pointer" onClick = {onCardClick}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
              {product?.brand && (product?.brand?.logo || product?.brand?.second_name) ? (
                <>
                  {product?.brand?.logo ? (
                    <img src = {product?.brand?.logo} alt = "default logo" className = "w-[60%] object-contain" />
                  ) : (
                    <p className = "w-[60%] font-[Montserrat] font-bold text-[#404040] text-[16px]">{product?.brand?.second_name || ""}</p>
                  )}
                </>
              ) : (
                  <img src={TPLogo} alt="default logo" style={{ width: '80%', objectFit: 'contain' }} />
              )}
                <span
                  onClick={() => handleFavoriteClick()}
                  style={{ cursor: 'pointer', float: 'right', marginLeft: 30 }}
                >
                  {displayFavoriteIcon() ? (
                    <IoMdHeart size="1.5em" color="#C98468" />
                  ) : (
                    <IoMdHeartEmpty size="1.5em" color="#C98468" />
                  )}
                </span>
              </div>
              {product?.card_id ? (
                <p className = "w-[60%] font-[Montserrat] font-bold text-[#404040] text-[16px]">{product?.card_id || ""}</p>
              ) : (
                <img src = {TPLogo} alt = "default logo" className = "w-[60%] object-contain" />
              )}
              <p className = "font-[Montserrat] font-regular text-[#404040] text-[12px]">{product?.description || ""}</p>
            </div>
            <div className="font-normal text-base mt-[10px] flex w-full justify-between">
              <div className = "flex flex-col items-center">
                <p className = "font-[Montserrat] font-bold text-[16px] text-[#c98468]">Incl. BTW</p>
                <div className = "flex">
                {(product?.new_sales_price && product?.original_sales_price && product?.new_sales_price !== product?.original_sales_price) ? (
                  <div>
                    <span className="cut-price mr-[10px]">
                      {"€ " + product.original_sales_price}
                    </span>
                    <span className="price">€ {product.new_sales_price}</span>
                  </div>
                ) : (
                  <span className="price">€ {product.original_sales_price}</span>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return null;
};

export default SalesCard;
