import { ALL_PROJECTS } from "../constants/constants";
const INIT_STATE = {
  all_categories: [],
};
const project = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_PROJECTS:
      return {
        ...state,
        all_categories: action.payload,
      };

    default:
      return state;
  }
};

export default project;
