import React, { useEffect, useState } from 'react';
import { Container } from "@mui/material";
import {getAllAlerts} from "./alerts.api";
import CookieDesktop from "./cookie_alert_desktop";
import CookieMobile from "./cookie_alert_mobile";
import { cookieConfig, cookieExist } from './cookie_configuration';
import PropTypes from 'prop-types';

import './cookie_alert.scss';

const CookieAlert = (props) => {
    const [isFetching, setIsFetching] = useState(false);
    const [alert, setAlerts] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [ cancelCookieState, setCancelCookieState ] = useState(false)
    const [ cookieState, setCookieState ] = useState(cookieExist())

    const breakpoint = 640;
    const { path } = props;

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        getAllAlerts(setIsFetching, setAlerts);

        return () => window.removeEventListener("resize", resizeHandler)
    }, []);

    useEffect(() => {
        // listener for whenever the url path changes, we then see if cookie exists and if not we show cookie popup
        const doesCookieExist = cookieExist();
        if(!doesCookieExist) {
            setCancelCookieState(false)
        }
    }, [path])

    const resizeHandler = event => {
        setWidth(window.innerWidth);
    }

    const setCookie = () => {
        cookieConfig();
        setCookieState(true);
    };

    return (
        <Container>
            {(width < breakpoint)
                ? (
                    <CookieMobile
                        cancelCookie={cancelCookieState}
                        alert={alert}
                        setCancelCookie={setCancelCookieState}
                        cookie={cookieState}
                        setCookie={setCookie}
                    />
                ) : (
                    <CookieDesktop
                        cancelCookie={cancelCookieState}
                        alert={alert}
                        setCancelCookie={setCancelCookieState}
                        cookie={cookieState}
                        setCookie={setCookie}
                    />
                )
            }
        </Container>
    )
}

export default CookieAlert;

CookieAlert.propTypes = {
    path: PropTypes.string
}

CookieAlert.defaultProps = {
    path: ""
}