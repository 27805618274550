import React from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import './submit_section.scss';

const SubmitButton = ({
    submitText='Volgende stap', 
    customBackBtnCss,
    mtSubmitSection,
    link='/',
    onClick,
    alternative
}) => {

    let navigate = useNavigate();

    return (
        <div className={`font-montserrat grid_3 mt-[${mtSubmitSection}]`}>
            <div className={`back_button_submit ${customBackBtnCss} mt-[10px] text-[16px] text-[#8D908B]`}>
                <span>&#60; </span>
                <span className='underline cursor-pointer' onClick={() => navigate(-1)}>Terug</span>
            </div>
            <div className='text-center'>
                {alternative ? (
                    <button className='w-[254px] h-[45px] text-white bg-[#c98468] rounded-full text-[18px] font-semibold' onClick={onClick}>
                        {submitText}
                    </button>
                ) : (
                    <Link to={link}>
                        <button className='w-[254px] h-[45px]  text-white bg-[#c98468] rounded-full text-[18px] font-semibold'>
                            {submitText}
                        </button>
                    </Link>
                )}
            </div>
            <div/>
        </div>
    )
}


export default SubmitButton;