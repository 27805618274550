import axios from "../axios/axios";
import { all_projects } from "../constants/ApiEndPoints";
import { ALL_PROJECTS } from "../constants/constants";

export const getAllProjects = () => {
  return async (dispatch) => {
    const { data } = await axios.get(all_projects);
    dispatch({ type: ALL_PROJECTS, payload: data.data });
    return data;
  };
};
