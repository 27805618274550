import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RoundedButton from "../../../components/rounded_button/rounded_button";
import { IoLocationOutline } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import BreakpointController from "../../../components/breakpoint_controller/breakpoint_controller";

import "./jobs.scss";

const JobsTable = ({ allJobs }) => {
  const navigate = useNavigate();

  function useWindowSize() {
    const [size, setSize] = React.useState([0]);
    React.useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const isMobile = useWindowSize() < 1025;
  return (
    <Grid container>
      <BreakpointController direction="down" breakpoint="lg">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div>
            <table className="jobs-table">
              <thead>
                <tr>
                  <th>Functie</th>
                  <th>Locatie</th>
                  <th>Regime</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allJobs?.map((job) => {
                  return (
                    job.show && (
                      <tr key={job.id}>
                        <td>
                          <div>
                            <span className="font-[SerifDisplay] fs-30 text-[#404040]">
                              {job.name}
                            </span>
                            <br />
                            <span className="fs-16 text-[#27292C]">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: job.description,
                                }}
                              />
                            </span>
                          </div>
                        </td>
                        <td className = "capitalize">{job.location || 'izegem'}</td>
                        <td>{job.regime}</td>
                        <td>
                          <div className="text-center p-4">
                            <RoundedButton
                              title={"Solliciteren"}
                              handler={() =>
                                navigate(`/job-application/${job.id}`)
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>
        </Grid>
      </BreakpointController>
      <BreakpointController direction="up" breakpoint="lg">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {allJobs?.map((job) => {
            return (
                job.show && (
                    <Grid container key={`jobs-${job.id}`} className="mb-[70px]" direction="column">
                  <div className="application-container  p-[10px]">
                    <p className="text-[30px] font-[SerifDisplay] text-[#404040]">
                      {job.name}
                    </p>
                    <p className="text-[16px] font-[Montserrat] text-[#27292C] font-light">
                      <div dangerouslySetInnerHTML={{ __html: job.description }} />
                    </p>
                  </div>
                  <div className={`w-full flex justify-between items-center ${!isMobile && 'mt-[35px]'}`}>
                    <div className="bg-[#F7F6F5] border-[3px] border-[#fff] h-[80px] flex flex-1 items-center p-[10px]">
                      {isMobile ? (
                          <Grid container>
                            <Grid item={4}>
                              <IoLocationOutline size="1.75em" color="#C98468" />
                            </Grid>
                            <Grid item={20} style={{ marginTop: 3 }}>
                              <p className="text-[14px] font-[Montserrat] text-[#27292C] font-semibold ml-[4px] capitalize">
                                {job.location || 'izegem'}
                              </p>
                            </Grid>
                          </Grid>
                      ) : (
                          <>
                            <IoLocationOutline size="1.75em" color="#C98468" />
                            <p className="text-[14px] font-[Montserrat] text-[#27292C] font-semibold ml-[14px] capitalize">
                              {job.location || 'izegem'}
                            </p>
                          </>
                      )}
                    </div>
                    <div className="bg-[#F7F6F5] border-[3px] border-[#fff] h-[80px] flex flex-1 items-center p-[10px]">
                      {isMobile ? (
                          <Grid container>
                            <Grid item xs={2}>
                              <IoMdTime size="1.75em" color="#C98468" />
                            </Grid>
                            <Grid item xs={10} style={{ marginTop: 3, paddingLeft: 5 }}>
                              <p className="text-[14px] font-[Montserrat] text-[#27292C] font-light ml-[4px]">
                                {job.regime}
                              </p>
                            </Grid>
                          </Grid>
                      ) : (
                          <>
                            <IoMdTime size="1.75em" color="#C98468" />
                            <p className="text-[14px] font-[Montserrat] text-[#27292C] font-light ml-[14px]">
                              {job.regime}
                            </p>
                          </>
                      )}
                    </div>
                  </div>
                  <div style={{ display: 'flex', paddingBottom: 20 }} className={`application-container pt-[20px] w-full flex justify-center ite]ms-center ${!isMobile && 'mt-[40px]'}`}>
                    <button
                        type="button"
                        className="h-[35px] w-[169px] rounded-[30px] cursor-pointer bg-[#C98468] flex justify-center items-center"
                        onClick={() => navigate(`/job-application/${job.id}`)}
                    >
                      <p className="font-[Montserrat] font-semibold text-white text-[14px]">
                        Solliciteer
                      </p>
                    </button>
                  </div>
                </Grid>
                )
            );
          })}
        </Grid>
      </BreakpointController>
    </Grid>
  );
};

export default JobsTable;
