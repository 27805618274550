import React from 'react';
import DepositPage from './deposit';
import PropTypes from "prop-types";
import StickyButton from "../../../components/maakeenafspraak_sticky_button/sticky_button";

const Deposit = (props) => {
    const { onClick, advanceamount } = props;

    return (
        <>
            <StickyButton />
            <DepositPage onClick = {onClick} advanceamount = {advanceamount} />
        </>
    )
}

export default Deposit;

Deposit.defaultProps = {
    onClick: () => {},
    advanceamount: 0
}

Deposit.propTypes = {
    onClick: PropTypes.func,
    advanceamount: PropTypes.number
}
