import { GET_ALL_BRANDS } from "../constants/constants";
const INIT_STATE = {
  all_brands: [],
};
const brand = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_BRANDS:
      return {
        ...state,
        all_brands: action.payload,
      };

    default:
      return state;
  }
};

export default brand;
