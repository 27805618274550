import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Tabs from "../../tabs/tabs";
import InventoryCard from "../../inventory_card/inventory_card";
import axios from "axios";
import { BASE_URL, getMostViewedProducts } from "../../../redux/constants/ApiEndPoints";

const TabsProduct = ({ products, currentPage, pageId }) => {
  const [mostViewedList, setMostViewedList ] = useState([]);
  const userData = JSON.parse(localStorage.getItem("user-data"));
  useEffect(() => {
    axios.get(BASE_URL + getMostViewedProducts, {params:{user_id: userData?.id}}).then(response => setMostViewedList(response.data.data)).catch(error => console.log(error))
  }, [])

  return (
    <div className="mt-20">
      <Tabs>
        Klanten bekeken ook
        <div>
          <Grid container className="mt-5">
            {mostViewedList?.length ? (
              <>
                {mostViewedList?.map((obj) => (
                  <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="mt-20">
                    <InventoryCard product={obj} currentPage={currentPage} productBrandName={''} />
                  </Grid>
                ))}
              </>
              ) : (
                  <div style={{
                    width: '100%',
                    textAlign: 'center',
                    color: '#8d908b',
                  }}>Geen gerelateerde producten gevonden.</div>
            )}
          </Grid>
        </div>
        Meer van deze designer
        <div>
          <Grid container className="mt-5">
            {products?.linked_products?.length ? (
              <>
                {products?.linked_products?.map((obj) => (
                  <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="mt-20">
                    <InventoryCard product={obj.linked} currentPage={currentPage} productBrandName={''} />
                  </Grid>
                ))}
              </>
              ) : (
                <div style={{
                  width: '100%',
                  textAlign: 'center',
                  color: '#8d908b',
                }}>Geen andere producten met deze designer gevonden.</div>
            )}
          </Grid>
        </div>
        Gerelateerde producten
        <div>
          <Grid container className="mt-5">
            {products?.linked_products?.length ? (
              <>
              {products?.linked_products?.map((obj) => (
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="mt-20">
                  <InventoryCard product={obj.linked} currentPage={currentPage} productBrandName={''} />
                </Grid>
              ))}
              </>
            ) : (
                <div style={{
                  width: '100%',
                  textAlign: 'center',
                  color: '#8d908b',
                }}>Geen gerelateerde producten gevonden.</div>
            )}
          </Grid>
        </div>
      </Tabs>
    </div>
  );
};

export default TabsProduct;
