import React from "react";
import { Routes, Route } from "react-router-dom";

import Homepage from "../pages/homepage/homepage";
import AfspraakForm from "../pages/afspraak_form";
import AfspraakFormProduct from "../pages/afspraak_form_product";
import InventoryCard from "../components/inventory_card/inventory_card";

import Login from "../pages/authentication/login";
import Register from "../pages/authentication/register";
import RegistrationConfirm from "../pages/authentication/registration_confirm/registration_confirm";

import Checkout from "../pages/checkout/checkout";
import DeliveryPickup from "../pages/checkout/delivery_pickup/delivery";
import Deposit from "../pages/checkout/deposit/deposit";

import Brands from "../pages/brands/brands";
import ProductDetails from "../pages/product_details/details";
import InventoryPage from "../pages/inventory/inventory";
import Categories from "../pages/project_categories";
import SelectedCategory from "../pages/project_categories/selected_category";
import ProjectDetails from "../pages/project_categories/project_details";
import PromotionsPage from "../pages/promotions/promotions";
import CadeauPage from "../pages/cadeau/cadeau";
import ScrollToTopWrapper from "../config/scrollToTop";
import CategoryPage from "../pages/categories/categories";
import JobOpenings from "../pages/careers/job_openings";
import JobApplication from "../pages/careers/job_application/application";
import Editor from "../components/editor/editor";
import UserAccount from "../pages/user_account/user_account";
import SalesPage from "../pages/sales";
import ResetPassword from "../pages/reset_password/reset_password";

import "./routes.scss";
import TermsConditions from "../pages/terms/terms";
import CookiesPage from "../pages/cookies/cookies";

export default function RoutesWithHeader() {
  return (
    <ScrollToTopWrapper>
      <Routes>
        <Route path="/afspraak-form/" element={<AfspraakForm />} />
        <Route path="/afspraak-form/:id" element={<AfspraakFormProduct />} />
        <Route path="/inventory-card" element={<InventoryCard />} />
        <Route path="/promotions" element={<PromotionsPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="registrationConfirmed" element={<RegistrationConfirm/>} />
        <Route path="/checkout/login" element={<Checkout type = "login" />} />
        <Route path="/checkout/billing" element={<Checkout type = "billing" />} />
        <Route path="/checkout/delivery" element={<Checkout type = "delivery" />} />
        <Route path="/checkout/confirmation" element={<Checkout type = "confirmation" />} />
        <Route path="/checkout/order/:id" element={<Checkout type = "order" />} />
        <Route path="/orders" element={<UserAccount type="orders" />} />
        <Route path="/cart" element={<UserAccount type="cart" />} />
        <Route path="/favorites" element={<UserAccount type="favorites" />} />
        <Route path="/info" element={<UserAccount type="info" />} />
        <Route path="/info/edit" element={<UserAccount type="info/edit" />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="/register" element={<Register />} />
        <Route path="/brands-page" element={<Brands />} />
        <Route path="/reset_password" element = {<ResetPassword />} />
        <Route
          path="/product-details/:pageId/:productId"
          element={<ProductDetails />}
        />
        <Route path="/inventory-page" element={<InventoryPage />} />
        <Route path="/sales-page" element={<SalesPage />} />
        <Route path="/category-page/:categoryId" element={<CategoryPage />} />
        <Route path="/project-categories" element={<Categories />} />
        <Route path="/delivery-pickup" element={<DeliveryPickup />} />
        <Route path="/cadeau" element={<CadeauPage />} />
        <Route path="/job-openings" element={<JobOpenings />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/cookies" element={<CookiesPage />} />
        <Route
          path="/project-categories/:categoryId"
          element={<SelectedCategory />}
        />
        <Route
          path="/project-categories/:categoryId/:projectId"
          element={<ProjectDetails />}
        />

        <Route
          path="/job-application/:applicationId"
          element={<JobApplication />}
        />
        <Route path="/" element={<Homepage />} />
      </Routes>
    </ScrollToTopWrapper>
  );
}
