import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import axios from "axios";
import { BASE_URL, getOrderLocations } from '../../../redux/constants/ApiEndPoints';

import './pickup.scss';

const Pickup = forwardRef((props, ref) => {
    const [location, setLocation] = useState(null);
    const [outletList, setOutletList] = useState([]);
    const [ errorState, setErrorState ] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    useEffect(() => {
        axios.get(BASE_URL + getOrderLocations, {headers}).then(response => {
            setOutletList(response.data.data)
        }).catch(error => console.log(error))
    }, [])

    useImperativeHandle(ref, () => ({
        getLocation: () => {
            if(location) {
                setErrorState(false);
                return location;
            }
            else {
                setErrorState(true);
                return false;
            }
        }
    }))

    const PickupCard = ({heading, text, imgURL, value}) => {

        const onClickCard = (value) => {
            setLocation(value);
        } 

        return (
                <div className={ `pickup_card_parent bg-[${location === value ? '#C98468' :'#FAFAFA'}] font-montserrat text-[#27292C] p-[25px]`} onClick={() => onClickCard(value)}>
                    <img alt="" className = "h-[200px] object-cover" src={imgURL} />
                    <div>
                        <div className='mt-[10px] font-semibold'>{heading}</div>
                        {text}
                    </div>
                </div>
        )
    }
    
    return (
        <div className='mb-[46px]'>
            <div className = {`text-montserrat font-semibold text-[14px] mb-[26px] ${errorState ? "text-[red]" : "text-black"}`}>
                SELECTEER WINKEL VOOR AFHALING:
            </div>
            
            
            <div className='grid_1_1_1'>
                {outletList && outletList?.length > 0 && outletList.map((outlet, index) => (
                    <PickupCard
                        imgURL={outlet?.image}
                        heading={outlet?.title}
                        text={<div>{outlet?.street} {outlet?.number}<br/> {outlet?.post_code} {outlet?.city}<br/> {outlet?.province}<br/><br/> {outlet?.contact}<br/> {outlet?.email}</div>}
                        value={outlet?.id}
                        key={`pimage-${index}`}
                    />
                ))}
            </div>
        </div>
    )
})

export default Pickup;