import Styled from 'styled-components';

const SuccessMessageStyle = Styled.div`
.main-success-message{
        h3{
            margin-bottom:0px!important;
        }
        p{
            margin-bottom:0px!important;
        }
}
.inner-column{
    padding:30px 20px;
    background: white !important;
    border-radius: 10px;
    box-shadow: 0 1px 6px 0px #E4E4E4;
}

  
`;

export { SuccessMessageStyle };
