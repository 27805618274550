import { useLocation, useNavigate } from "react-router-dom";

import "./card.scss";

const ButtonCard = ({ obj, isDetail }) => {
  const location = useLocation();
  const pathname = isDetail
      ? '/project-categories'
      : location.pathname;
  const navigate = useNavigate();

  return (
    <div className="left_action relative font-[dmserif]">
      <div className="absolute w-full h-full text-center text-white left_action">
        <div className=" relative text-center top-[50%] left_action_padding_div">
          <div className="text-[25px] font-extrabold left_action_heading">
            {obj.name}
          </div>
          <div className="left_action_button_div mt-4">
            {obj.button_text && (
              <button
                className="text-[16px] w-[270px] left_action_button font-[Montserrat] bg-transparent hover:bg-[#C98468] text-white-100  py-2 border border-white hover:border-transparent rounded-full"
                onClick={() => navigate(`${pathname}/${obj.id}`)}
              >
                {obj.button_text}
              </button>
            )}
          </div>
        </div>
      </div>
      <img
        className="h-full w-full"
        alt="right"
        src={
          (obj.image !== "null" &&
            obj.image !== "[object Object]" &&
            obj.image) ||
          "https://tp-storage.s3.fr-par.scw.cloud/project/category/Kasten.jpeg"
        }
      />
    </div>
  );
};

export default ButtonCard;
