import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import './radio_button.scss';

const RadioButton = ({activeProps, enabled = true}) => {
    const [active, setActive] = useState(activeProps);
    const labels = ['Winkelwagen', 'Uw gegevens', 'Afhaling/Levering', 'Voorschot & voorwarden']
    const labelLinks = ['/checkout/login', '/checkout/billing', '/checkout/delivery', '/checkout/confirmation']
    const navigate = useNavigate();

    const handleClick = index => {
        if(activeProps.includes(index)) {
            navigate(labelLinks[index])
        }
    }

    return (
            <div className='row bs-wizard mt-[50px] mx-[10%]' style = {{display: enabled ? "grid" : "none"}}>
                {labels.map((label, index) => {
                    return (
                        <div key={label} className={`bs-wizard-step ${activeProps.includes(index) ? 'active cursor-pointer' : 'disabled'}`} onClick = {() => handleClick(index)}>
                            <div className="text-center bs-wizard-stepnum">?</div>
                            <div className="progress"><div className="progress-bar" /></div>
                            <span href="#"
                                onClick={e => {setActive(label)}}
                                className="bs-wizard-dot"
                            />
                            <div className={`bs-wizard-info text-center text-[12px] ${active === label ? 'font-semibold' : ''}`}>{label}</div>
                        </div>
                    )
                })}
                
	    </div>
    )}


export default RadioButton;