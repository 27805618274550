export const cookieConfig = () => {
    let d = new Date();
    d.setTime(d.getTime() + 2*24*60*60*1000 ); 
    let expires = 'expires=' + d.toUTCString();
    document.cookie = `top-interior=Exist;${expires};path=/`;
}

export const cookieExist = () => {
    let cookieExist = document.cookie.indexOf('top-interior') === -1 ? false : true;
    return cookieExist
}