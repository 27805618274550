import React, { useEffect, useState } from "react";
import LoginPage from "../../authentication/login/login";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import LockModal from "../../../components/lock_modal/lock_modal";
import { BASE_URL, change_password, getPdfImages } from "../../../redux/constants/ApiEndPoints";
import axios from "axios";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import "./information.scss";

const Information = (props) => {
  const [ formSubmitted, setFormSubmitted ] = useState(false)
  const [ passwordModalState, setPasswordModalState ] = useState(false)
  const [ errorOldPass, setErrorOldPass ] = useState('')

  const passwordChangeForm = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmedNewPassword: ""
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required("Verplicht"),
      newPassword: Yup.string().min(8, 'wachtwoord moet 8 tekens lang zijn.').required(" "),
      confirmedNewPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Wachtwoorden komen niet overeen').required(" "),
    }),
    onSubmit: (values, { resetForm }) => {
      axios.post(BASE_URL + change_password, {
        user_id: userData?.id,
        password: values.oldPassword,
        new_password: values.newPassword
      }, {headers}).then(response => {
        setFormSubmitted(false);
        navigate(0);
      }).catch(error => {
        console.log(error)
        if(error?.response?.data){
          if(error?.response?.data?.data?.message.includes("Ongeldig oud wachtwoord")){
            setErrorOldPass("Ongeldig oud wachtwoord.");
          }
        }
      })
    },
});

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  const { clientData, isLoggedIn } = props;
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('user-data'));

  useEffect(() => {
    axios.get(BASE_URL + getPdfImages + "24", {headers}).then(response => {
    }).catch(error => console.log(error))
  }, [])

  const InformationRow = ({ className = "", text }) => {
    return <div className={className}>{text}</div>;
  };


  const handlePasswordModalClose = () => {
    passwordChangeForm.resetForm();console.log('hi', passwordChangeForm)
    setPasswordModalState(false)
  }

  const InformationCard = ({
    heading,
    name,
    subHeading,
    address,
    city,
    email,
    date,
    phone,
    phone2,
    isLineVisible,
    mainClass,
    section
  }) => {
    return (
      <div className={"information_card_parent h-full text-[#27292C] leading-8 bg-[#F7F6F5] mb-[15px] py-[29px] pl-[48px] pr-[24px] " + mainClass}>
        <div className="relative flex justify-between items-center">
          <InformationRow
            className="information_heading text-[35px] text-[#404040]"
            text={heading}
          />
          <div
            onClick={() => navigate("/info/edit?section=" + section)}
            className="cursor-pointer"
          >
            <Icon />
          </div>
        </div>
        <InformationRow className="mt-[23px] font-bold" text={subHeading} />
        <InformationRow text={name} />
        <InformationRow text={address} />
        <InformationRow text={city} />
        {isLineVisible && (
          <div className="bg-[#D3D3D3] h-[2px] w-full mt-[15px] mb-[15px]" />
        )}
        <InformationRow text={email} />
        <InformationRow text={date} />
        <InformationRow text={phone} />
        <InformationRow text={phone2} />
      </div>
    );
  };

  const Icon = () => {
    return (
      <div className="h-[21px] w-[21px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style={{ enableBackground: "new 0 0 512 512" }}
          xmlSpace="preserve"
          fill="#C98468"
        >
          <g>
            <path d="M231.5,49.1h-162C31.1,49.1,0,80.2,0,118.5v324C0,480.9,31.1,512,69.4,512h324c38.4,0,69.4-31.1,69.4-69.4v-162   c0-12.8-10.4-23.1-23.1-23.1c-12.8,0-23.1,10.4-23.1,23.1l0,162c0,12.8-10.4,23.1-23.1,23.1h-324c-12.8,0-23.1-10.4-23.1-23.1v-324   c0-12.8,10.4-23.1,23.1-23.1h162c12.8,0,23.1-10.4,23.1-23.1C254.6,59.5,244.2,49.1,231.5,49.1L231.5,49.1z" />
            <path d="M405,37.5l16.4,16.4c5.1-5.1,11.6-7.6,18.3-7.6c6.7,0,13.2,2.5,18.4,7.6l0,0c5.1,5.1,7.6,11.6,7.6,18.4   c0,6.7-2.5,13.2-7.6,18.3L242.8,305.9l-48.9,12.2l12.2-48.9L421.4,53.9L405,37.5l-16.4-16.4L168.8,241c-2.9,2.9-5.1,6.7-6.1,10.8   l-23.1,92.6c-2,7.9,0.3,16.2,6.1,22c5.7,5.7,14.1,8.1,22,6.1l92.6-23.1c4-1,7.8-3.1,10.8-6.1l219.9-219.9   c14.1-14,21.2-32.7,21.2-51.1c0-18.4-7.1-37-21.1-51.1l0,0C476.8,7.1,458.2,0,439.8,0c-18.4,0-37,7.1-51.1,21.2l0,0L405,37.5z" />
          </g>
        </svg>
      </div>
    );
  };

  const parseSQLDateTime = (value) => {
    if (value) {
      const datetime = value.split("T");
      const dateString = datetime[0];
      const timeString = datetime[1];
      const dateArray = dateString.split("-");
      const timeArray = timeString.split(":");
      const date = new Date(
        dateArray[0],
        parseInt(dateArray[1]) - 1,
        dateArray[2],
        timeArray[0]
      );
      return {
        date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
        time: `${date.getHours()}:${date?.getMinutes()}`,
      };
    } else {
      return null;
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/")
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      {isLoggedIn ? (
        <>
          <div className="w-full flex flex-col mb-[60px] lg:flex-nowrap flex-wrap">
            <div className = "flex w-full flex-col lg:flex-row">
              <div className = "flex flex-col w-full flex-1 lg:mr-[15px]">
                {clientData &&
                  clientData?.addresses &&
                  clientData?.addresses[0] && (
                    <InformationCard
                      heading="Huidig adres"
                      subHeading={
                        clientData?.business
                          ? `${clientData?.company || ""} | ${
                              clientData?.company_number || ""
                            }`
                          : ""
                      }
                      name={`${clientData?.firstName} ${clientData?.lastName}`}
                      address={`${clientData?.addresses[0]?.street_1} ${clientData?.addresses[0]?.number_1}${clientData?.addresses[0]?.number_2 ? ", " + clientData?.addresses[0]?.number_2 : ""}`}
                      city={`${clientData?.addresses[0]?.zip_code} ${clientData?.addresses[0]?.city}`}
                      email={`${clientData?.email}`}
                      date={clientData?.dob ? clientData?.dob : ""}
                      phone={`${clientData?.phone}`}
                      phone2={`${clientData?.mobile}`}
                      isLineVisible
                      section = "email"
                    />
                )}
              </div>
              <div className = "flex flex-col w-full justify-between flex-1">
                {clientData &&
                  clientData?.addresses &&
                  clientData?.addresses[1] && (
                    <InformationCard
                      heading="Facturatie adres"
                      subHeading={
                        clientData?.addresses[0]?.billing
                          ? "Identiek aan het huidig adres"
                          : ""
                      }
                      section = "billing"
                      mainClass = "h-full"
                      address={`${clientData?.addresses[1]?.street_1} ${clientData?.addresses[1]?.number_1}${clientData?.addresses[1]?.number_2 ? ", " + clientData?.addresses[1]?.number_2 : ""}`}
                      city={`${clientData?.addresses[1]?.zip_code} ${clientData?.addresses[1]?.city}`}
                    />
                )}
                {clientData &&
                clientData?.addresses &&
                clientData?.addresses[2] && (
                  <InformationCard
                    heading="Levering adres"
                    subHeading={
                      clientData?.addresses[0]?.delivery
                        ? "Identiek aan het huidig adres"
                        : ""
                    }
                    mainClass = "h-full"
                    section = "living"
                    address={`${clientData?.addresses[2]?.street_1} ${clientData?.addresses[2]?.number_1}${clientData?.addresses[2]?.number_2 ? ", " + clientData?.addresses[2]?.number_2 : ""}`}
                    city={`${clientData?.addresses[2]?.zip_code} ${clientData?.addresses[2]?.city}`}
                  />
                )}
              </div>
            </div>
            <div className = "flex w-full flex-col lg:flex-row">
              <div className = "flex flex-col flex-1 lg:mr-[15px]">
                {clientData && clientData?.second_contact && (
                  <InformationCard
                    heading="2de contact persoon"
                    mainClass = "h-fit"
                    section = "second"
                    name={`${clientData?.second_contact?.firstName} ${clientData?.second_contact?.lastName}`}
                    email={`${clientData?.second_contact?.email}`}
                    phone={`${clientData?.second_contact?.phone}`}
                  />
                )}
              </div>
              <div className = "flex flex-1 flex-col lg:flex-row">
                <div className="information_card_parent flex-[6] text-[#27292C] leading-8 h-[209px] bg-[#F7F6F5] mb-[15px] py-[29px] pl-[48px] pr-[48px] lg:mr-[15px]">
                  <div className="relative flex flex-col h-full justify-between items-center">
                    <InformationRow
                      className="information_heading text-[35px] text-[#404040]"
                      text = "Wachtwoord"
                    />
                    <InformationRow
                      className="information_heading text-[35px] text-[#404040]"
                      text = "wijzigen"
                    />
                    <button type = "button" className = "h-[41px] w-[100%] bg-[#C98468] cursor-pointer flex justify-center items-center rounded-[25px] md:mt-[0px] mt-[25px]" onClick = {() => setPasswordModalState(true)}>
                      <p className = "font-[Montserrat] text-[px] text-white font-medium">Wachtwoord wijzigen</p>
                    </button>
                  </div>
                </div>
                <div className="information_card_parent flex-[4] text-[#27292C] leading-8 h-[209px] bg-[#F7F6F5] mb-[15px] py-[29px] pl-[48px] pr-[48px]">
                  <div className="relative flex flex-col h-full justify-between items-center">
                    <InformationRow
                      className="information_heading text-[35px] text-[#404040]"
                      text = "Uitloggen"
                    />
                    <p className = "font-[Montserrat] text-[16px] text-black font-medium">Tot ziens!</p>
                    <button type = "button" className = "h-[41px] w-[90%] bg-[#C98468] cursor-pointer flex justify-center items-center rounded-[25px]" onClick = {handleLogout}>
                      <p className = "font-[Montserrat] text-[14px] text-white font-medium">Uitloggen</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoginPage />
      )}
      <form onSubmit={passwordChangeForm.handleSubmit}>
        <LockModal heading="Wachtwoord wijzigen" subheader="Kies een nieuw wachtwoord" buttonText="Wijziging opslaan" openState={passwordModalState} onExit={handlePasswordModalClose} onClick={passwordChangeForm.handleSubmit}>
          <div className="flex flex-col w-full">
            <p className="font-[Montserrat] text-[14px] text-black font-semibold">OUD WACHTWOORD</p>
            <input
              type="password"
              name="oldPassword"
              onChange={passwordChangeForm.handleChange}
              value={passwordChangeForm.values.oldPassword}
              style={(passwordChangeForm.errors.oldPassword && passwordChangeForm.touched.oldPassword) ? { borderColor: 'red' } : {}}
              className="h-[41px] w-full border-[1px] border-[black] box-border rounded-[3px] indent-[15px] font-[Montserrat] text-[14px] text-black font-medium"
            />
            <div className="w-full h-[1px] bg-[#C98468] my-[30px]" />
            <p className="font-[Montserrat] text-[14px] text-black font-semibold">NIEUW WACHTWOORD</p>
            <input
              type="password"
              name="newPassword"
              onChange={passwordChangeForm.handleChange}
              value={passwordChangeForm.values.newPassword}
              style={(passwordChangeForm.errors.newPassword && passwordChangeForm.touched.newPassword) ? { borderColor: 'red' } : {}}
              className="h-[41px] w-full border-[1px] border-[black] box-border rounded-[3px] indent-[15px] font-[Montserrat] text-[14px] text-black font-medium"
            />
            {passwordChangeForm.errors.newPassword && passwordChangeForm.touched.newPassword && (
              <p style={{ color: 'red', fontSize: 'small' }}>{passwordChangeForm.errors.newPassword}</p>
            )}
            <p className="font-[Montserrat] text-[14px] text-black font-semibold mt-[15px]">BEVESTIGING NIEUW WACHTWOORD</p>
            <input
              type="password"
              name="confirmedNewPassword"
              onChange={passwordChangeForm.handleChange}
              value={passwordChangeForm.values.confirmedNewPassword}
              style={(passwordChangeForm.errors.confirmedNewPassword && passwordChangeForm.touched.confirmedNewPassword) ? { borderColor: 'red' } : {}}
              className="h-[41px] w-full border-[1px] border-[black] box-border rounded-[3px] indent-[15px] font-[Montserrat] text-[14px] text-black font-medium mb-[10px]"
            />
            {passwordChangeForm.errors.confirmedNewPassword && passwordChangeForm.touched.confirmedNewPassword && (
              <p style={{ color: 'red', fontSize: 'small' }}>De wachtwoorden die u hebt ingevoerd komen niet overeen!</p>
            )}
            {errorOldPass && (
              <p style={{ color: 'red', fontSize: 'small' }}>{errorOldPass}</p>
            )}
          </div>
        </LockModal>
      </form>
    </Grid>
  );
};

export default Information;

Information.propTypes = {
  clientData: PropTypes.object,
  isLoggedIn: PropTypes.bool,
};

Information.defaultProps = {
  clientData: {},
  isLoggedIn: false,
};
