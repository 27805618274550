/* eslint-disable no-unused-expressions */
import React from 'react';
import { Row, Col, Typography } from 'antd';
import { SuccessMessageStyle } from './style';

// image imports here
import Check from '../../images/brand_logos/top_interieur.svg';

const { Text, Title } = Typography;

const SuccesMessage = () => {
  return (
    <SuccessMessageStyle>
      <div className="main-success-message">
        <Row className=" pt-30" justify="center">
          <Col span={14} className="inner-column">
            <div className="align-center-v">
              <img src={Check} alt="" />
              <Title level={3} className="ml-10">
                Successful!
              </Title>
            </div>
            <Text>Email send successfully.</Text>
          </Col>
        </Row>
      </div>
    </SuccessMessageStyle>
  );
};
export default SuccesMessage;
