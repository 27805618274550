import React from 'react';
import TopBar from '../top_bars/login_top_bar';
import Content from './content';
import PropTypes from "prop-types";

const Form = (props) => {const active = <span>Afspraak</span>
    const { productId, userData } = props;

    return (
        <div>
            <Content productId = {productId} userData = {userData} />            
        </div>
    );
}

export default Form;

Form.defaultProps = {
    productId: null,
    userData: null
}

Form.propTypes = {
    productId: PropTypes.number,
    userData: PropTypes.object
}