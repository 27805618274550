import React from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import { Link } from 'react-router-dom';

import './sticky_button.scss';

const StickyButton = () => {
    return (
        <div className="maakeenafspraak_sticky_button text-center z-20 bg-[#27292c]">
            <Link to='/afspraak-form'>
                <ChatIcon className='chat_icon text-redOrange-100 pr-[5px] ms:h-[40px] ms:w-[40px]' />
                <p className='text-white inline leading-[40px]'>Maak een afspraak</p>
            </Link>
        </div>
    )
}

export default StickyButton;
