/* Products */
export const ALL_PRODUCTS = "ALL_PRODUCTS";

/* Sales */
export const ALL_SALES = "ALL_SALES";

/* Categories */
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";

/* Vacatures (Jobs) */
export const GET_VACATURES_DETAIL = "GET_VACATURES_DETAIL";
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_JOB = "GET_JOB";

/* Loader */
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const HIDE_MESSAGE = "HIDE_MESSAGE";

/* Carousel */
export const GET_ALL_CAROUSEL = "ALL_CAROUSEL";

/* Box */
export const GET_ALL_BOX = "ALL_BOX";

/* Collection */
export const GET_ALL_COLLECTION = "GET_ALL_COLLECTION";

/* Brand */
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";

/* Page */
export const GET_PROJECT_PAGE_DETAILS = "GET_PROJECT_PAGE_DETAILS";

/* Project Category */
export const ALL_PROJECTS_CATEGORIES = "ALL_PROJECTS_CATEGORIES";

/* Project */
export const ALL_PROJECTS = "ALL_PROJECTS";

/* Promotion */
export const GET_ALL_PROMOIONS = "GET_ALL_PROMOIONS";
