import React from 'react'
import { IoMdCheckmark } from "react-icons/io";

export default function CustomCheckbox({ obj, handleCheckbox }) {
  return (
    <div className="flex px-7 font-[Montserrat]">
      <button id={obj.id} type = "button" className = "h-[18px] w-[18px] cursor-pointer flex justify-center items-center border-[1px] border-[#404040] rounded-[2px]" onClick = {handleCheckbox}>
          <div className = "animated" style = {{opacity: obj.selected ? 1 : 0}}>
            <IoMdCheckmark size = "0.9rem" color = "#C98468" />
          </div>
      </button>
      <span className="brands-checkbox-label ">{obj.name}</span>
    </div>
  )
}
