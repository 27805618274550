import React from "react";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";

import "./terms.scss";

export default function Terms(props) {
    const { modalState, onClose } = props;

    return(
        <div className = "fixed w-screen h-screen left-0 top-0 flex justify-center items-center animated z-[1111]" style = {{opacity: modalState ? 1 : 0, transform: modalState ? "scale(1)" : "scale(0)"}}>
            <div className = "absolute w-full h-full left-0 top-0 bg-[#27292C]/[0.31]" />
            <div className = "h-[635px] w-[912px] bg-white rounded-[5px] relative box-border py-[32px] flex flex-col items-center overflow-y-auto">
                <button type = "button" className = "absolute right-[16px] top-[16px] cursor-pointer" onClick = {onClose}>
                    <IoMdClose size = "1.5em" color = "black" />
                </button>
                <p className = "font-[SerifDisplay] font-regular text-[#27292C] text-[25px]">Voorwaarden</p>
                <div className = "w-full box-border px-[32px] mt-[44px]">
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] capitalize">Het begrip « meubelen »</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.1 Onder meubelen verstaat men alle producten die tot het normaal assortiment van de meubelhandelaar behoren.</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">OMVANG VAN DE VERPLICHTINGEN</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.2 De verkoper is gebonden door garantiebewijzen, prospectussen en publicitaire mededelingen en andere documenten die door hem worden uitgedeeld, overgenomen of waarnaar hij verwijst, en dit voor zover zij de rechten van de koper, die uit de wet en uit deze overeenkomst voortvloeien, niet beperken. De verkoper zal echter niet aansprakelijk kunnen gesteld worden voor kleine wijzigingen inzake constructie, maten, kleur door de fabrikant aangebracht, tenzij uit de rubriek “opmerkingen” op de voorkant van de bestelbon zou blijken dat de constructie, de maten en/of de kleur voor de koper een wezenlijk onderdeel uitmaken van de overeenkomst.</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">VERKOOP BUITEN DE ONDERNEMING : BEDENKTIJD</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.3 Met verkopen buiten de onderneming (zoals vermeld op de voorkant van dit contract) wordt o.a. bedoeld : verkoop tijdens beurzen of exposities, tijdens een door de verkoper georganiseerde uitstap, alsook via de post, per telefoon of andere vormen van verkoop op afstand, alsook ten huize van de koper. Deze bedenktijd geldt niet wanneer de koper het bezoek van de verkoper voorafgaandelijk en uitdrukkelijk gevraagd heeft, met de bedoeling te onderhandelen over de aankoop. Er is geen bedenktijd indien de verkoop plaats heeft in de winkel van de verkoper.</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">PRIJS</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.4 De hoger vermelde prijs is vast en alles inbegrepen, behalve indien deze overeenkomst uitdrukkelijk bijkomende kosten vermeldt. Indien op verzoek van de koper wijzigingen worden aangebracht aan de leveringstermijn en/of –plaats en/of –omstandigheden, of indien de koper verkeerde inlichtingen heeft verstrekt (zie: “Inlichtingen ten behoeve van de levering” alsook “Opmerkingen”) krijgt de verkoper het recht extra kosten aan te rekenen. Indien vóór de leveringsdatum de BTW wijzigingen ondergaat, wordt de totale prijs in dezelfde mate aangepast.</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">LEVERINGSDATUM OF -TERMIJN</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.5 De leveringsdatum of –termijn moet aangegeven zijn en is van strikte toepassing. Indien geen precieze leveringsdatum of –termijn werd overeengekomen, dan bedraagt de uiterste leveringstermijn drie maanden vanaf de ondertekening van deze overeenkomst. Levert de verkoper de meubelen niet ten laatste op de leveringsdatum, of weigert de koper de meubelen ten laatste op deze datum in ontvangst te nemen, dan kan de benadeelde partij per aangetekend schrijven de uitvoering van de overeenkomst eisen binnen een termijn van 3 weken. Indien de tegenpartij in gebreke blijft dan heeft de benadeelde partij het recht om de afgesloten overeenkomst onmiddellijk te verbreken zonder voorafgaande ingebrekestelling. In dit geval heeft ze het recht op de teruggave van alle reeds betaalde bedragen of reeds geleverde meubelen; daarenboven heeft ze het recht op een vergoeding voor de geleden schade. Wordt de overeenkomst ontbonden door de verkoper, dan heeft deze vanaf dit ogenblik het recht om de meubelen aan derden te verkopen. Indien de uiterste leveringsdatum op éénzijdig verzoek van de koper wordt verdaagd of uitgesteld voor langer dan één maand, dan kan de verkoper een bijkomend voorschot eisen om zijn financiële verplichtingen te kunnen nakomen. Eventuele bewaringskosten zullen worden doorgerekend bij de definitieve afrekening naar rato van 0,5% per maand.</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px]">Art.6 Artikel 5 is evenwel niet van toepassing ingeval de leveringsdatum niet kan worden nageleefd wegens normaal niet voorzienbare omstandigheden onafhankelijk van de wil van de verkoper, en hij, binnen de 14 dagen nadat hij kennis had van het bestaan van deze omstandigheden, en ten laatste op de voorziene leveringsdatum, de koper hiervan verwittigt. In dit geval heeft de koper de keuze tussen de tijdelijke levering van (een) evenwaardig(e) vervangingsmeubel(en) of een evenwaardige Vervangingsaankoop.</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px]">Art.7 Indien de tijdelijke terbeschikkingstelling van het (de) vervangingsmeubel(en) de 3 maanden overschrijdt, of ingeval van definitieve onmogelijkheid tot levering ten gevolge van overmacht, is de koop van rechtswege en zonder ingebrekestelling ontbonden, behalve indien schriftelijk anders wordt overeengekomen.</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px]">Art.8 De koper kan zich echter verzetten tegen de toepassing van art. 5,6 en 7 in het bijzonder geval dat hij onder de rubriek”Opmerkingen” (zie voorzijde), erop gewezen heeft dat een laattijdige levering geenszins meer zal passen in het bijzonder kader waarvoor de aankoop gepland werd of een ernstige financiële of morele schade zal meebrengen.</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px]">Art.9 De koper kan niet worden verplicht een gedeeltelijke levering te aanvaarden van meubelen die één geheel vormen, behalve als hij zelf een gedeeltelijke levering heeft gevraagd.</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">PLAATS VAN LEVERING</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.10 De levering geschiedt op de afgesproken plaats. Werd geen plaats van levering overeengekomen, dan geschiedt zij op het adres van de koper.</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">KWALITEIT VAN GEBREKEN</p>
                    <p className = "font-[Montserrat] font-medium text-[#27292C] text-[14px] mt-[15px]">A. Definitie</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px]">Art.11 De verkoper waarborgt dat de meubelen in overeenstemming zijn met het contract. Hij verklaart dat de meubelen stroken met hetgeen in de overeenkomst bepaald is, met de normale eisen van bruikbaarheid, betrouwbaarheid en levensduur, met de bestaande veiligheidsnormen en met de publicitaire mededelingen en andere documenten die door de verkoper worden uitgedeeld, overgenomen of waarnaar hij verwijst. De verkoper verklaart dat de meubelen geschikt zijn voor elk bijzonder door de koper gewenst gebruik dat deze aan de verkoper bij het sluiten van de overeenkomst heeft meegedeeld en dat de verkoper schriftelijk heeft aanvaard. Elke niet-overeenstemming met deze gegevens wordt hierna “gebrek” genoemd. Worden niet als gebrek beschouwd, verkleuren van hout, textiel, vezelstoffen en leder, alsook het ”werken” van hout, voorzover deze verkleuring of dit werken vanuit technisch oogpunt niet te voorkomen zijn, of volgens de gebruiken in de meubelsector algemeen aanvaard worden of eigen zijn aan de gebruikte materialen.</p>
                    <p className = "font-[Montserrat] font-medium text-[#27292C] text-[14px] mt-[15px]">B. Verhaalmiddelen</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px]">Art.12 Als er een gebrek is, heeft de koper de keuze tussen kosteloze herstelling of vervanging. De verkoper kan die herstelling of vervanging weigeren wanneer die buiten verhouding is, gelet op : - de waarde van de meubelen; - de ernst van het gebrek; - de vraag of de alternatieve vorm van genoegdoening mogelijk is zonder ernstige overlast voor de Koper. De koper heeft recht op prijsvermindering of ontbinding als herstelling of vervanging niet mogelijk is of als de verkoper ze niet uitvoert binnen een redelijke termijn. In geval van ontbinding moet de koper de verkoper op een billijke manier vergoeden voor het reeds verkregen genot. Betreft het een relatief klein en weinig belangrijk gebrek, dan kan de koper de ontbinding niet vragen. De koper heeft bovendien, overeenkomstig de wet, recht op een vergoeding van de door het gebrek veroorzaakte schade. Deze verhaalmiddelen vervallen indien het ingeroepen gebrek veroorzaakt is door een onoordeelkundige behandeling van/of onvoldoende zorg voor de geleverde meubelen, alsmede wanneer de meubelen niet gebruikt worden overeenkomstig hun bestemming. De koper heeft geen verhaal bij gebreken die hij kende of redelijkerwijze had moeten kennen bij het sluiten van de overeenkomst of als het gebrek voortvloeit uit materiaal door de koper geleverd.</p>
                    <p className = "font-[Montserrat] font-medium text-[#27292C] text-[14px] mt-[15px]">C. Termijnen</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px]">Art.13 De verkoper is aansprakelijk voor elk gebrek dat bestaat bij de aflevering van de meubelen. Als het gebrek zich manifesteert binnen de 6 maanden na aflevering, wordt vermoed dat het gebrek bestond bij de aflevering, tenzij dit vermoeden onverenigbaar is met de aard van de meubelen of met de aard van het gebrek. Als het gebrek zich manifesteert vanaf de 7de maand tot en met het 2de jaar na aflevering, dient de koper te bewijzen dat het gebrek bestond bij de aflevering. De koper moet de verkoper schriftelijk op de hoogte brengen van het bestaan van het gebrek binnen de 2 maanden vanaf de dag waarop de koper het gebrek heeft vastgesteld. Gebreken welke bij het sluiten van de overeenkomst bestonden worden geacht niet te bestaan als de koper het gebrek kende of redelijkerwijs behoorde te kennen of als het gebrek voortvloeit uit materiaal geleverd door de koper aan de verkoper. Na de termijn van 2 jaar zijn de artikelen 1641 tot en met 1649 van het Burgerlijk Wetboek van toepassing. Indien de verkoper geen gevolg geeft aan de klacht van de koper, is artikel 19 van Toepassing.</p>
                    <p className = "font-[Montserrat] font-medium text-[#27292C] text-[14px] mt-[15px]">D. Meubelwaarborg</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px]">Art.14 In geval van gebrek wordt volgende garantie gegeven : 1. Gedurende de eerste 2 jaar na de levering heeft de koper het recht het meubel door de verkoper te laten herstellen of te laten vervangen door een identiek meubel. Het herstellen, afhalen en terugbrengen gebeurt op kosten van de verkoper. 2. Gedurende het derde jaar na de levering geldt dezelfde waarborgregeling maar betaalt de koper 50% van de kosten. Deze bijzondere garantiebepalingen doen geen afbreuk aan de artikelen 11 en 12 en aan de wettelijke bepalingen terzake. In geval van betwisting is de Geschillencommissie bevoegd (cfr. Art.19).</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">EIGENDOMSOVERDRACHT</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.15 De eigendomsoverdracht heeft plaats op het ogenblik van de levering. Zolang de meubelen niet geleverd zijn, valt elk risico wegens verlies of beschadiging ten laste van de verkoper. De verkoper kan echter onder de rubriek “Opmerkingen” (zie voorzijde) bepalen dat de eigendomsoverdracht slechts zal geschieden bij de volledige betaling.</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">BETALING</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.16 De betaling van de meubelen geschiedt contant bij de levering, behoudens een uitdrukkelijk afwijkende vermelding op de bestelbon. Bij niet-betaling van de gehele of gedeeltelijke prijs op de vervaldag zal van rechtswege en zonder enige ingebrekestelling een intrest verschuldigd zijn op de openstaande schuld ten bedrage van 1% per maand met als minimum de wettelijke intresten. Bovendien zal, indien de betaling niet gebeurd is binnen de 14 dagen na een aangetekende ingebrekestelling, van rechtswege een forfaitaire schadevergoeding verschuldigd zijn van 10% op de aankoopprijs, met een minimum van 25 euro, dit alles onverminderd de bovenvermelde intresten.</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">FINANCIERING</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.17 Ingeval de koper op een krediet dient beroep te doen, zal hiervan melding gemaakt worden op de bestelbon. Indien het krediet niet wordt verkregen, wordt de koop geacht niet te hebben bestaan en heeft de koper recht op de terugbetaling van het gebeurlijk betaalde voorschot onder de volgende voorwaarden: hij zal de verkoper per aangetekende brief binnen de zeven dagen na de ondertekening van de bestelbon in kennis stellen van de weigering van het krediet. Het geschreven bewijs van deze weigering zal binnen de maand vanaf de ondertekening van de bestelbon aan de verkoper overgemaakt worden. Het voorschot zal dan onmiddellijk terugbetaald worden aan de koper. Bij gebrek aan bewijs binnen de hierboven vermelde termijn, zal de verkoper recht hebben op een schadevergoeding.</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">SCHADEVERGOEDINGEN</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.18 Indien de koper deze overeenkomst verbreekt of de goederen niet in ontvangst neemt, is hij verplicht de geleden en bewezen schade te vergoeden.</p>
                    <p className = "font-[Montserrat] font-bold text-[#27292C] text-[16px] mt-[15px]">GESCHILLENCOMMISIE (*)</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Art.19 Elk geschil gerezen na het sluiten van de onderhavige overeenkomst, over de geldigheid, de uitvoering of de interpretatie van dit contract, kan op schriftelijk verzoek van de eiser worden behandeld door de Geschillencommissie Meubelen. Is de gedaagde partij echter de koper, dan heeft deze het recht om zich tegen de behandeling door de Geschillencommissie te verzetten. Hiertoe dient hij binnen een termijn van 15 kalenderdagen vanaf de kennisgeving van de klacht door het secretariaat van de Geschillencommissie Meubelen aan de gedaagde, het secretariaat per aangetekend schrijven ervan op de hoogte te brengen dat hij niet wenst dat de zaak door de Geschillencommissie wordt behandeld. Ingeval de Geschillencommissie de zaak behandelt, zijn haar beslissingen definitief en binden ze de partijen. De procedure en de beslissingen zijn conform aan het geschillenreglement en aan de bepalingen van het gerechtelijk wetboek inzake arbitrage (art.1676 tot 1723).</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">(*) Geschillencommissie Meubelen : tot 30 maart 2005: BI Trade Mart, Atomiumsquare PB 613, 1020 Brussel – vanaf 1 april 2005: Ternat Busines Center, Assesteenweg 117/9 D, 1740 Ternat.</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Tel : 02/478.47.58 – fax : 02/478.37.66.</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Dit contract werd opgesteld door :</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">- de Belgische Verbruikersunie Test-Aankoop vzw, Hollandstraat 13, 1060 Brussel- tel:02/542.32.32</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">- Nationale Beroepsvereniging van Meubelhandelaars Navem vzw, Atomiumsquare PB 613, 1020 Brussel- tel : 02/478.47.16</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">- Arcopar cvba, Livingstonelaan 6, 1000 Brussel- tel : 02/285.41.34</p>
                    <p className = "font-[Montserrat] font-regular text-[#27292C] text-[14px] mt-[15px]">Overeenkomstig de Belgische wet van 30 juni 1994 op het auteursrecht, heeft alleen de auteur het recht om dit contract te vermenigvuldigen of om de gehele of gedeeltelijke reproductie ervan, in welke vorm dan ook, toe te staan.</p>
                </div>
            </div>
        </div>
    )
}

Terms.propTypes = {
    modalState: PropTypes.bool,
    onClose: PropTypes.func
}

Terms.defaultProps = {
    modalState: false,
    onClose: () => {}
}